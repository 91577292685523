import { Colors } from '../../../../constants/colors.constants';

/**
 * This function set the color by the sub selected for a task
 * @param {*} task Task object to be checked sub color
 * @param {*} render Boolean that defines if gantt must render
 * @returns Null if task object is milestone
 */
function defineColorBySubcontract(task, render = false, gantt = false) {
  if (!gantt) {
    gantt = window.to_use_react_gantt;
  }

  gantt.config.highlight_critical_path = false;
  if (task.type == 'milestone') return;
  const COLOR_DEFAULT = Colors.GRAY_1;

  let color;
  const subId = task.subcontractId;

  if (subId) {
    const subcontractObject = gantt.subContracts.find((el) => el.id == subId);
    if (subcontractObject) {
      color = subcontractObject.color;
    }
  }

  if (!color) {
    color = COLOR_DEFAULT;
  }
  task.progressSolidColor = color;
  task.color = color + '4d';
  if (render) gantt.optimizedRender();
}

export { defineColorBySubcontract };
