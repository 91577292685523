import { Colors } from '../../../../constants/colors.constants';

function defineColorByTags(task, render = false, gantt = false) {
  if (!gantt) {
    gantt = window.to_use_react_gantt;
  }

  gantt.config.highlight_critical_path = false;
  if (task.type == 'milestone') return;

  const COLOR_DEFAULT = Colors.GRAY_1;
  let color;

  const tags = task.tags;
  if (tags && tags.length) {
    const firstTag = tags[0];
    if (firstTag) {
      color = firstTag.description;
    }
  }

  if (!color) {
    color = COLOR_DEFAULT;
  }

  task.progressSolidColor = color;
  task.color = color + '4d';
  if (render) gantt.optimizedRender();
}
export { defineColorByTags };
