/** React components  */
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** import base contant (setting urls)  */
import { base } from '../../services/base';

/** Services */
import { authService } from '../../services';
import { procoreService } from '../../services/procore.service';

/** import elements from library Antd Framework */
import { Input } from 'antd';

/** import common functions from utils */
import { withTranslation } from 'react-i18next';

import { LoadingOutlined } from '@ant-design/icons';
import ProPlanner from '../../assets/img/proplanner.svg';
import NotificationSystem from '../../components/DesignSystem/NotificationSystem';
import SSOProcoreButton from './SSOProcoreButton';
import { trackingEvent } from '../../analytics';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import { useProperties } from '../../hooks/useProperties';
import './index.scss';
import { httpCodes } from '../../constants/httpCodes';
import LayoutLoginOutbuild from '../../components/LayoutLoginOutbuild';
import { redirectSelectCompany } from './login.helpers';

const DEFAULT_DATA_FORM = { email: '', password: '' };
const DEFAULT_CONFIG_NOTIFICATION = { show: false, type: '', label: '' };
const EMAIL_TYPE = 'email';
const PASSWORD_TYPE = 'password';
const MIN_LENGHT_PASSWORD = 6;
const ERROR_TYPE = 'error';
const WARNING_TYPE = 'warning';
const USER_NOT_CONFIRM_TYPE = 'user_not_confirm';
const SOURCE_SYSTEM_SSO = 'procore';
const SOURCE_ENVIRONMENT_SANDBOX = 'sandbox';
const SOURCE_ENVIRONMENT_PROD = 'prod';
const REGEX_EMAIL =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const FLOW_FREE_TRIAL = 'FLOW_FREE_TRIAL';
const FLOW_FREE_TRIAL_ONLY_US = 'FLOW_FREE_TRIAL_ONLY_US';

const EXCLUDED_SUBDOMAINS_FOR_REDIRECT = [
  'qa.proplanner.app',
  'dev.proplanner.app',
  'mobile-testing.proplanner.app',
  'preprod.proplanner.app'
];
const MAIN_DOMAINS = ['proplanner.app'];
const DEFAULT_URL = 'https://app.outbuild.com/';
export function redirectLogic(currentUrl) {
  let url = currentUrl;

  const urlObj = new URL(currentUrl);
  const { hostname } = urlObj;

  const isInMainDomain = MAIN_DOMAINS.some((domain) =>
    hostname.endsWith(domain)
  );
  const isInExcludedSubdomain = EXCLUDED_SUBDOMAINS_FOR_REDIRECT.some(
    (domain) => hostname.endsWith(domain)
  );

  if (isInMainDomain && !isInExcludedSubdomain) {
    url = DEFAULT_URL;
  }

  return url;
}

function LoginView(props) {
  const { t } = props;

  const propertiesHook = useProperties();

  /** hooks */
  const [dataForm, setDataForm] = useState(DEFAULT_DATA_FORM);
  const [emailIncorrectFormat, setEmailIncorrectFormat] = useState(false);
  const [passwordIncorrectFormat, setPasswordIncorrectFormat] = useState(false);
  const [configNotification, setConfigNotification] = useState(
    DEFAULT_CONFIG_NOTIFICATION
  );
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingProcoreButton, setIsLoadingProcoreButton] = useState(false);
  const [showFlowFreeTrial, setShowFlowFreeTrial] = useState(false);
  const [beta, setBeta] = useState(false);
  const [env, setEnv] = useState('Local');

  useEffect(() => {
    window.Appcues.page();
  });

  /** Similar to did mount */

  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    const currentUrl = window.location.href;

    const redirectUrl = redirectLogic(currentUrl);

    if (redirectUrl !== currentUrl) {
      window.location.replace(redirectUrl);
    }

    if (
      window.location.href.includes('localhost') ||
      window.location.href.includes('dev.proplanner.app') ||
      window.location.href.includes('qa.proplanner.app') ||
      window.location.href.includes('preprod.proplanner.app')
    ) {
      setBeta(true);
      window.location.href.includes('qa.proplanner.app') && setEnv('QA');
      window.location.href.includes('dev.proplanner.app') && setEnv('Dev');
    }

    EventEmitter.on('changeMainRoute', callback);

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);

  /** redirects */
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.agaveapi.com/init.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const flagFlowFreeTrial = propertiesHook?.properties?.find(
      (property) => property.name === FLOW_FREE_TRIAL
    );
    const flagFlowFreeTrialOnlyUS = propertiesHook?.properties?.find(
      (property) => property.name === FLOW_FREE_TRIAL_ONLY_US
    );

    if (!flagFlowFreeTrial || !flagFlowFreeTrialOnlyUS) return;
    if (!flagFlowFreeTrial.active) return;

    if (flagFlowFreeTrialOnlyUS.active) {
      setShowFlowFreeTrial(t('lang') === 'en');
      return;
    }

    setShowFlowFreeTrial(true);
  }, [propertiesHook]);

  const signed = localStorage.getItem('signed');
  if (signed) {
    return <Redirect to="/selectCompany" />;
  }

  /** navigation history */
  const goToForgot = (e) => {
    e.preventDefault();
    trackingEvent('forgot_password_option_selection', null, AMPLITUDE_SERVICE);
    props.history.push('/forgot');
  };

  const goToSignUp = (e) => {
    e.preventDefault();
    props.history.push('/signup');
  };

  /** services */
  async function getData(data) {
    const resp = await authService.index(data);
    return resp;
  }

  async function getDataSSO(data) {
    const resp = await authService.indexSSO(data);
    return resp;
  }

  const handleInputChange = (event) => {
    setDataForm({
      ...dataForm,
      [event.target.name]: event.target.value
    });
  };

  const handleOnBlur = (type) => {
    if (type === EMAIL_TYPE)
      setEmailIncorrectFormat(!REGEX_EMAIL.test(dataForm.email));
    if (type === PASSWORD_TYPE)
      setPasswordIncorrectFormat(
        dataForm.password.length < MIN_LENGHT_PASSWORD
      );
  };

  const isValidForm = () =>
    REGEX_EMAIL.test(dataForm.email) &&
    dataForm.password !== '' &&
    dataForm.password !== null &&
    dataForm.password.length >= MIN_LENGHT_PASSWORD;

  const getErrorMessageProcore = () => {
    setConfigNotification({
      ...configNotification,
      show: true,
      type: ERROR_TYPE,
      label: t('login.notification_error_unable_profile_procore')
    });
  };

  const sendTrackErrorProcore = () => {
    trackingEvent(
      'sign_in',
      {
        email_given: dataForm.email,
        event_source: 'With PROCORE',
        event_result: 'Failed',
        error_source: 'Unable to get profile information from Procore'
      },
      AMPLITUDE_SERVICE
    );
  };

  const getTrackingSignIn = (
    email_given,
    event_source,
    event_result,
    error_source
  ) => {
    trackingEvent(
      'sign_in',
      {
        email_given,
        event_source,
        event_result,
        error_source
      },
      AMPLITUDE_SERVICE
    );
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingButton(true);

    if (isValidForm() && !isLoadingButton) {
      try {
        const data = {
          user: dataForm.email.toLowerCase(),
          password: dataForm.password,
          link: base.front + 'confirmation/',
          lang: t('lang')
        };

        const response = await getData(data);

        if (response.status !== httpCodes.OK) {
          setConfigNotification({
            ...configNotification,
            show: true,
            type: response.status === 401 ? WARNING_TYPE : ERROR_TYPE,
            label:
              response.status === 401
                ? response.data.type === USER_NOT_CONFIRM_TYPE
                  ? t('login.notification_user_not_confirm')
                  : t('login.notification_warning_text')
                : t('login.notification_error_text')
          });

          getTrackingSignIn(
            dataForm?.email,
            'With Credentials',
            'Failed',
            response.status === 401
              ? response.data.type === USER_NOT_CONFIRM_TYPE
                ? 'Email not confirmed'
                : 'Incorrect user mail or password'
              : 'Login issues'
          );
          setIsLoadingButton(false);
          return;
        }

        getTrackingSignIn(
          dataForm?.email,
          'With Credentials',
          'Successfull',
          null
        );

        redirectSelectCompany(response);
      } catch (err) {
        setConfigNotification({
          ...configNotification,
          show: true,
          type: ERROR_TYPE,
          label: t('login.notification_error_text')
        });

        getTrackingSignIn(
          dataForm?.email,
          'With Credentials',
          'Failed',
          'Login issues'
        );
        setIsLoadingButton(false);
      }
    }
  };

  const handleSSOProcore = async () => {
    try {
      setIsLoadingProcoreButton(true);

      const linkToken = await getLinkTokenOrThrow();
      const userResponse = await authenticateUserWithToken(linkToken);
      await handleUserAuthenticationSuccess(userResponse);
    } catch (err) {
      console.log(`Error: ${err}`);
      handleAuthenticationFailure();
    }
  };

  async function getLinkTokenOrThrow() {
    const { link_token: linkToken } =
      await procoreService.getAgaveLinkTokenSSO();
    if (!linkToken) {
      throw new Error('linkToken error');
    }
    return linkToken;
  }

  async function authenticateUserWithToken(linkToken) {
    if (window && window.AgaveLink) {
      return new Promise((resolve, reject) => {
        window.AgaveLink.openLink({
          linkToken,
          sourceSystem: SOURCE_SYSTEM_SSO,
          showSandboxSourceSystems: beta,
          showProductionSourceSystems: !beta,
          sourceSystemEnvironment: beta
            ? SOURCE_ENVIRONMENT_SANDBOX
            : SOURCE_ENVIRONMENT_PROD,
          onSuccess: resolve,
          onExit: reject
        });
      });
    }
    throw new Error('AgaveLink is not available');
  }

  async function handleUserAuthenticationSuccess(publicToken) {
    const reponse = await getDataSSO({
      publicToken
    });

    if (!reponse.success) {
      throw new Error(reponse.message);
    }

    getTrackingSignIn(
      reponse?.data?.user?.email,
      'With PROCORE',
      'Successful',
      null
    );
    redirectSelectCompany(reponse);
  }

  function handleAuthenticationFailure() {
    setIsLoadingProcoreButton(false);
    getErrorMessageProcore();
    sendTrackErrorProcore();
  }

  // const layout = LayoutLoginOutbuild()

  const renderNotification = configNotification.show && (
    <div className="login__notification">
      <NotificationSystem
        type={configNotification.type}
        label={configNotification.label}
      />
    </div>
  );
  const renderForm = (
    <div className="login__form">
      <form onSubmit={onSubmit}>
        <div className="form__input">
          <label>{t('login.label_email')}</label>
          <Input
            value={dataForm.email}
            onChange={handleInputChange}
            onBlur={() => handleOnBlur(EMAIL_TYPE)}
            className={emailIncorrectFormat ? ERROR_TYPE : ''}
            type={EMAIL_TYPE}
            name={EMAIL_TYPE}
            autoComplete="off"
          />
          {emailIncorrectFormat && <small>{t('login.hint_email')}</small>}
        </div>
        <div className="form__input">
          <label>{t('login.label_password')}</label>
          <Input
            value={dataForm.password}
            onChange={handleInputChange}
            onBlur={() => handleOnBlur(PASSWORD_TYPE)}
            className={passwordIncorrectFormat ? ERROR_TYPE : ''}
            type={PASSWORD_TYPE}
            name={PASSWORD_TYPE}
          />
          {passwordIncorrectFormat && <small>{t('login.hint_password')}</small>}
        </div>
        <div className="form__input">
          <button type="submit" disabled={!isValidForm()}>
            {isLoadingButton ? (
              <LoadingOutlined />
            ) : (
              t('login.button_sign_in_text')
            )}
          </button>
        </div>
        <div className="form__forgot">
          <a href="javascript:void(0);" onClick={goToForgot}>
            {t('login.button_forgot_password_text')}
          </a>
        </div>
        {showFlowFreeTrial && (
          <div className="form__signup">
            <span>
              {t('login.button_sign_up_label')}{' '}
              <a href="javascript:void(0);" onClick={goToSignUp}>
                {t('login.button_sign_up_link')}
              </a>
            </span>
          </div>
        )}
        <div className="form__line">
          <div className="line__separation" />
          <span>{t('login.other_login_text')}</span>
        </div>
        <div className="form__procore">
          <SSOProcoreButton
            t={t}
            isLoading={isLoadingProcoreButton}
            onClick={() => handleSSOProcore()}
          />
        </div>
        {beta && (
          <div className="form__beta">
            <NotificationSystem
              type={WARNING_TYPE}
              label={`Warning: ${env} version, please go to: app.outbuid.com`}
            />
          </div>
        )}
      </form>
    </div>
  );
  return (
    <div>
      <LayoutLoginOutbuild>
        <>
          {renderNotification}
          {renderForm}
        </>
      </LayoutLoginOutbuild>

      <div className="loggin-project-version">
        {base.version ? `v ${base.version}` : ''}
      </div>
    </div>
  );
}

export default withTranslation()(LoginView);
