export const PROD = 'prod';
export const PROD2 = 'prod2';
export const LOCAL = 'local';
export const QA = 'qa';
export const DEV = 'dev';

export const ALL_SERVICES = 'all';

export const AMPLITUDE_SERVICE = 'amplitude';
export const AMPLITUDE_TOKEN_LOCAL = '14548985b269511e2146d079158fa616';
export const AMPLITUDE_TOKEN_PROD = 'a3e6fb0214c43dfa2062caf5b46736f0';
export const AMPLITUDE_MIN_LENGHT_USER_ID = 6;

export const TOTANGO_SERVICE = 'totango';
