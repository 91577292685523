import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RightCircleOutlined } from '@ant-design/icons';
import * as ganttActions from '../../../../../../../redux/slices/ganttSlice';
import CleanFiltersModal from './CleanFiltersModal';
import { updateDrawer } from '../../../../../../../redux/slices/hoveringStackSlice';
import {
  activityCardFetchRequested,
  activityLogFetchRequested
} from '../../../../../../../redux/slices/activitySlice';

import './styles.scss';

const DependenciesGoToTask = ({ activity, t }) => {
  const [showModal, setShowModal] = useState(false);
  const ganttState = useSelector((state) => state.ganttState);
  const dispatch = useDispatch();
  const gantt = window.to_use_react_gantt;
  const task = gantt.getTask(activity.gantt_id);

  const goToTask = (retry = 0) => {
    if (isActivityHidden()) {
      retryGoToTask(retry);
      return;
    }
    updateActivityCard();
    gantt.unselectTask();
    window.to_use_react_gantt.avoidCloseDrawer = true;
    const sizes = gantt.getTaskPosition(task);
    gantt.scrollTo(sizes.left, sizes.top);
    gantt.selectTask(activity.gantt_id);
  };

  const retryGoToTask = (retry) => {
    const MAX_RETRIES = 10;
    if (retry < MAX_RETRIES) {
      setTimeout(() => {
        goToTask(retry + 1);
      }, 100);
    }
  };

  const isDependenciesFilterActived = () => {
    const areActiveFilters = ganttState.filteredByLinkedActivities.length;
    return Boolean(areActiveFilters);
  };

  const cleanDependenciesFilter = () => {
    dispatch(ganttActions.setFilteredByLinkedActivities({ filtered: [] }));
    const tasks = gantt.getTaskByTime();
    tasks.forEach((task) => {
      task.should_be_showed = true;
    });

    gantt.refreshData();
    gantt.render();
    gantt.scrollTo(null, 0);
  };

  const clearFilters = () => {
    dispatch(ganttActions.setShowedFilters([]));
    if (isDependenciesFilterActived()) {
      cleanDependenciesFilter();
    }
    setShowModal(false);
    goToTask();
  };

  const updateActivityCard = () => {
    const globalActivityId = gantt.getTask(activity.gantt_id)?.proplannerId;
    const globalDhtmlxId = activity.gantt_id;

    dispatch(updateDrawer({ params: { globalActivityId, globalDhtmlxId } }));
    dispatch(activityCardFetchRequested({ globalActivityId, globalDhtmlxId }));
    dispatch(activityLogFetchRequested({ globalActivityId, globalDhtmlxId }));
  };

  const isActivityHidden = () => {
    return !gantt.getTask(activity.gantt_id).should_be_showed;
  };

  const handleGoToTask = () => {
    if (isActivityHidden()) {
      setShowModal(true);
      return;
    }

    goToTask();
  };

  const handleClose = (state) => {
    setShowModal(state);
  };

  const render = () => {
    if (task.type === 'project') return null;

    return (
      <>
        {isActivityHidden() && (
          <CleanFiltersModal
            isVisible={showModal}
            setShowModal={handleClose}
            goToTask={goToTask}
            clearFilters={clearFilters}
            t={t}
          />
        )}

        <div>
          <span onClick={handleGoToTask} className="goToTaskButton">
            <RightCircleOutlined className="goToTaskIcon" />
          </span>
        </div>
      </>
    );
  };

  return render();
};

export default React.memo(DependenciesGoToTask);
