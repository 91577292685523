/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class CncService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(cncId) {
    const res = await this.http.get('cncs/' + cncId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async destroy(cncId) {
    const res = await this.http.delete('cncs/' + cncId).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('cncs', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async createWithTasks(data) {
    const res = await this.http.post('cncs/withtasks', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http.put('cncs/' + data.id, data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async showBySector(sectorId) {
    const res = await this.http
      .get('cncs/sector/' + sectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySectorTask(data) {
    const res = await this.http
      .post('cncs/getbysectortask', data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const cncService = new CncService();
