import React from 'react';
import ProgressCell from '../../../components/ProgressCell';

export default (gantt, { t }) => ({
  name: 'progress',
  label: t('scheduleofschedule.columns.progress'),
  align: 'center',
  width: 70,
  onrender: (item) => (
    <ProgressCell progress={item.progress} parent={item.parent} />
  )
});
