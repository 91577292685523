/* eslint-disable no-eval */

/** React components  */
import React, { useState, useEffect, useRef } from 'react';

import SelectSearch from 'react-select-search';

/** To custom event handling */
import EventEmitter from 'react-native-eventemitter';

/** To animating views easely */
import { Animated } from 'react-animated-css';

import { filter, openNotification } from '../../../utils';

import moment from 'moment';

import quitDismissIcon from '../../../assets/img/gantt/quit-header-config.png';
/** import helpers */
import { getConstraintList } from './constraints.helper';

import CustomPaginator from '../../../components/CustomPaginator';

import AnimatedSortable from '../../../components/AnimatedSortable';
/** Build components from antd css framework */
import { Row, Col, Tooltip, Popconfirm } from 'antd';
import { Spin, Icon, Empty, Popover, DatePicker, Modal } from 'antd';
import { CalendarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import ConstraintForm from '../../../components/LookAhead/Constraints/ConstraintForm';

import useWindowDimensions from '../../../hooks/useWindowDimensions';
/** Services */
import {
  userService,
  activityService,
  constraintTypeService,
  constraintService,
  constraintScheduleService,
  notificationService
} from '../../../services';

/** Plain text CSS file (react pretty features) */
import './index.css';

/** Redux */
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { constraintActions } from '../../../redux/actions/constraintActions';
import { userActions } from '../../../redux/actions/userActions';

/** JSON with table distribution */
import { tableMetadata } from './table.layout';

/** Single table with activity as header and tasks as childs */
import ConstraintActivity from '../../../components/LookAhead/Constraints/ConstraintActivity';
import ConstraintOtherGroup from '../../../components/LookAhead/Constraints/ConstraintOtherGroup';
import LookaheadFilterHeader from '../../../components/LookaheadFilterHeader';

/** Function to clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';
import differenceBy from 'lodash/differenceBy';
import { capitalize, isEqual } from 'lodash';

/** Function to keep states replacing specific elements */
import updateInmmutability from 'immutability-helper';
import { firstBy } from 'thenby';

/** Massive actions icons */
import deleteMassive from '../../../assets/img/massive/delete.png';
import priorityMassive from '../../../assets/img/massive/cnc/priority.svg';
import responsableMassive from '../../../assets/img/massive/responsable.png';
import statusMassive from '../../../assets/img/massive/cnc/status.svg';
import typeMassive from '../../../assets/img/massive/cnc/type.svg';
import datesMassive from '../../../assets/img/massive/dates.png';
import constraintIcon from '../../../assets/img/iconoConstraint.png';

import {
  adjustColumnsSize,
  resizeNameTaskColumn,
  handleLeanStatusColPosition
} from '../../../utils/lookahead-common';

/** PNG to put on users without image */
import fakeAvatar from '../../../assets/img/fake_user.png';
import { socket } from '../../../services/socket.service';
import { withTranslation } from 'react-i18next';
import { attachmentsService } from '../../../services/attachment.service';
import useSubtradeUser from '../../../hooks/useSubtradeUser';
import { setTrending } from './functions/setTrending';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../../analytics/implements/totango';
import { getSignedUser } from '../../../utils/userUtils';
const { confirm } = Modal;

/** RangePicker builded components to use datepicker from start to end in just one input :) */
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

/**
 * This view shows activities from last level.
 * Then user can through a filter change range date showed.
 * Then, user can add on eagger data model tasks, associating them with the activity ID as a super parent.
 * @param {*} props
 */
function ConstraintView(props) {
  const userLang = navigator.language || navigator.userLanguage;
  const leanStatusColVisibility = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [popsVisibility, setPopsVisibility] = useState({});

  /** handle Form Add Constraint */
  const [visibleForm, setVisibleForm] = useState({ value: false, tasks: [] });

  const { permission, t } = props;
  /** Project state from redux */ /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  const constraintState = useSelector((state) => state.constraintState);
  const dispatch = useDispatch();
  const [isTree, setIsTree] = useState({ value: false });
  const update = useSelector((state) => state.userState);
  /** Activities to handle table loading */
  const [activities, setActivities] = useState([]);
  const [activitiesReport, setActivitiesReport] = useState([]);

  /** Array with users that belongs to the same sector that this master plan */
  const [toSelectResponsables, setToSelectResponsables] = useState([]);

  /** Flag to know when component is on loading step */
  const [isLoading, setLoading] = useState(true);

  /** Object to handle start and end dates to show last level activities from master plan  */
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  /** Variable to view and his components to handle table manipulation by user */
  const [tableConfig, setTableConfig] = useState([]);

  const groupByDefault = { criteria: 'status', sort: 'asc' };
  const [massiveUserPop, setMassiveUserPop] = useState({ visible: false });
  const [massivePriorityPop, setMassivePriorityPop] = useState({
    visible: false
  });
  const [massiveStatusPop, setMassiveStatusPop] = useState({ visible: false });

  /** Handle Constraint Types */
  const [constraintTypes, setConstraintTypes] = useState([]);

  /** Object to handle start and end dates to show last level activities from master plan  */
  const [groupBy, setGroupBy] = useState(groupByDefault);
  const [orderBy, setOrderBy] = useState({
    value: [{ name: 'id', order_by: 'asc' }]
  });
  const [lastLevelActivities, setLastLevelActivities] = useState({});

  const [resizing, setResizing] = useState(false);

  const [massiveSelection, setMassiveSelection] = useState([]);
  const [selectParams, setSelectParams] = useState(false);
  const [existFilter, setExistFilter] = useState(false);

  const { height, width } = useWindowDimensions();

  const [scrollStates, setScrollStates] = useState({});

  const [massiveResponsableState, setMassiveResponsableState] = useState({
    values: []
  });
  const [massiveOnProcess, setMassiveOnProcess] = useState(false);
  const [attachments, setAttachments] = useState([]);

  /** Full width feature */
  const [totalTablesWidth, setTotalTablesWidth] = useState(0);
  let paginatorRef = useRef();

  const subtradeRole = useSubtradeUser();

  /** Effect to load translation to table declaration file */
  useEffect(() => {
    const loggedUser = getSignedUser();
    const currentCompany = stateCompany.currentCompany;
    const project = projectState.allProjects.find(
      (p) => p.id == projectState.projectSelected
    );

    totangoSetAccountAttributes(
      loggedUser,
      projectState.projectSelected,
      currentCompany?.name,
      currentCompany?.id,
      project?.name,
      project?.stage,
      project?.country
    );

    totangoEventTracking(
      `p_${projectState.projectSelected}`,
      loggedUser,
      'See Roadblocks',
      'Lookahead'
    );
  }, []);

  useEffect(() => {
    window.Appcues.page();
  });

  useEffect(() => {
    dispatch(constraintActions.notifyLookaheadUpdateGroup());

    setTimeout(() => {
      dispatch(constraintActions.notifyLookaheadUpdateOrder());
    }, 50);

    setTimeout(() => {
      dispatch(constraintActions.notifyLookaheadUpdateFilter());
    }, 100);
  }, [constraintState.notifyChange]);

  useEffect(() => {
    modifyGroupBy();
  }, [constraintState.notifyChangeGroup]);

  /** Effect to load translation to table declaration file  */
  useEffect(() => {
    setTimeout(() => {
      const copyOfTableData = [...tableMetadata];
      copyOfTableData.map((column) => {
        if (
          column.data_type.includes('/icon') ||
          column.data_type.includes('/string')
        ) {
          column.label = t(
            'tables.lookahead.constraints.' + column.name + '.label'
          );
          column.from_values.map((option) => {
            option.label = t(
              'tables.lookahead.constraints.' +
                column.name +
                '.options.' +
                option.value
            );
          });
        } else {
          column.label = t('tables.lookahead.constraints.' + column.name);
        }
      });
      setTableConfig(copyOfTableData);
      setTableUpdated(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (!resizing) {
      let tablesWidth = 0;
      tableConfig.map((el) => {
        if (el.visible) {
          tablesWidth += el.width;
        }
      });
      setTotalTablesWidth(tablesWidth);
    }
  }, [tableConfig]);

  useEffect(() => {
    if (paginatorRef.current) {
      paginatorRef.current.resetAfterColumnIndex(0);
    }
  }, [totalTablesWidth]);

  const [countSchedule, setCountSchedule] = useState(0);

  /** calculate rescheduling count */
  useEffect(() => {
    let cont = 0;
    if (activities) {
      cont = activities.reduce((a, c) => {
        const count = c.tasks.reduce((ac, cu) => {
          let schCh = cu.schedules.length
            ? parseInt(cu.schedules.length) - 1
            : 0;
          if (cu.hide) schCh = 0;
          ac += parseInt(schCh);
          return ac;
        }, 0);
        a += count;
        return a;
      }, 0);
    }
    setCountSchedule(cont);
  }, [activities]);

  /**
   * This function handles with resize of cols inside the table from dragging resize  from header titles
   * @param {*} width Updated width catched from the event
   * @param {*} colMetadata Single column metadata that were resized
   */
  const onDivResizeHandler = (width, colMetadata) => {
    if (colMetadata.doubleClicked) {
      colMetadata.width = width;
      colMetadata.doubleClicked = false;
      updateTableMetadata(null, colMetadata);
    }
  };

  /**
   * This function handles the click, and the unclick from user when the resize is catched
   * @param {*} col Single column metadata that is going to be resized
   * @param {*} val Boolean, true for start resizing, false for end resizing
   */
  const onDivResizeDoubleClicked = (col, val) => {
    col.doubleClicked = val;
    // setResizing(val)
    // updateTableMetadata()
  };

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);
    props.setContainerHeader('');

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, []);
  const [tableUpdated, setTableUpdated] = useState(false);
  /** This effect allows to load initial lookahead range, and reacts to change on sectorSelected from redux */
  useEffect(() => {
    getTaskConstraints();
  }, [
    projectState.sectorSelected,
    tableUpdated,
    projectState.props.loadSectors,
    update?.update2
  ]);

  /** Loads activities with their tasks */
  const getTaskConstraints = async (_) => {
    if (tableConfig.length) {
      const data = await getConstraints();
      modifyGroupBy(groupByDefault, data);
    }
  };

  const lastSelectedGroupByRef = useRef(groupByDefault);

  function setLastSelectedGroupByRef(newGroupBy) {
    if (isEqual(newGroupBy, groupByDefault)) return;
    if (isEqual(newGroupBy, lastSelectedGroupByRef.current)) return;
    lastSelectedGroupByRef.current = newGroupBy;
  }

  const modifyGroupBy = async (
    newGroupBy = groupByDefault,
    data = activities
  ) => {
    setLastSelectedGroupByRef(newGroupBy);
    newGroupBy = lastSelectedGroupByRef.current;

    if (newGroupBy.criteria == 'no-group') {
      data = await getConstraints(false);
      data.sort(
        firstBy('id', { direction: newGroupBy.sort, ignoreCase: true })
      );

      setGroupBy(newGroupBy);
      updateRender(data);
      setIsTree({ ...isTree });

      return;
    }

    let col = tableConfig.filter((el) => el.name == newGroupBy.criteria);

    if (!col.length) return;

    col = col[0];

    if (
      newGroupBy.criteria == groupBy.criteria &&
      newGroupBy.sort != groupBy.sort
    ) {
      data.sort(
        firstBy('value', { direction: newGroupBy.sort, ignoreCase: true })
      );

      setGroupBy(newGroupBy);
      updateRender(data);
      setIsTree({ ...isTree });

      return;
    }

    if (
      newGroupBy.criteria === 'responsables' ||
      isTree.value ||
      newGroupBy.criteria != groupBy.criteria
    ) {
      data = await getConstraints(false);
      isTree.value = false;
    }

    if (groupBy.criteria !== newGroupBy.criteria) {
      quitDismissHandler();
    }

    let newData = [];

    if (col.from_values == 'toSelectResponsables') {
      newData = toSelectResponsables
        .map((el) =>
          el.is_active
            ? {
                value: el[col.el_to_extract_from],
                name: `${el.name} ${el.lastname}`,
                img: el.image,
                tasks: []
              }
            : null
        )
        .filter((el) => el);
    } else if (col.from_values.length) {
      newData = col.from_values.map((el) => ({
        value: el.value,
        tasks: [],
        label: el.label,
        order: el.order
      }));
    }

    data.map((constraints) => {
      constraints.tasks.map((constraint) => {
        newData.map((singleParent) => {
          /** Save object reference */
          if (col.name == 'responsables') {
            constraint[col.name].map((responsable) => {
              if (responsable[col.el_to_extract_from] == singleParent.value) {
                singleParent.tasks.push(constraint);
              }
            });
          } else if (col.name == 'reportusers') {
            constraint[col.name].map((responsable) => {
              if (responsable[col.el_to_extract_from] == singleParent.value) {
                singleParent.tasks.push(constraint);
              }
            });
          } else {
            if (constraint[col.name] == singleParent.value) {
              singleParent.tasks.push(constraint);
            }
          }
        });
      });
    });

    const orderByProperty = newGroupBy.criteria == 'status' ? 'order' : 'value';
    data = newData;
    data.sort(
      firstBy(orderByProperty, { direction: newGroupBy.sort, ignoreCase: true })
    );

    newGroupBy.criteria == 'status' &&
      (data = data.filter((dataGroup) => dataGroup.tasks.length));

    setGroupBy(newGroupBy);
    updateRender(data);
    setIsTree({ ...isTree });
  };

  const getConstraintsFetch = async (_) => {
    /** get constraints */
    const responsables = await userService.getBySector(
      projectState.sectorSelected
    );
    const list = await getConstraintList(projectState.sectorSelected);
    if (!list) return [];
    const constraints = list.map((el) => {
      const findUser = responsables.users.find((user) => user.id === el.userId);
      const findUserReport = responsables.users.find(
        (user) => user.id === el.report_user
      );
      return {
        ...el,
        reportusers: findUserReport ? [findUserReport] : [],
        responsables: findUser ? [findUser] : []
      };
    });
    const constraintsOrder = constraints.sort((a, b) => {
      return new Date(a.commitmentDate) < new Date(b.commitmentDate) ? 1 : -1;
    });
    return constraintsOrder;
  };

  /**
   * This function get activities and responsables to use them at DOM
   */
  async function getConstraints(reLoad = true) {
    if (!projectState.sectorSelected) return null;
    /** set load to true */
    if (reLoad) {
      setLoading(true);
    }
    setMassiveSelection([]);
    setCurrentPage(0);

    /** get users by sector */
    const responsables = await userService.getBySector(
      projectState.sectorSelected
    );
    const attachments = await attachmentsService.showBySector(
      projectState.sectorSelected
    );
    if (attachments) {
      setAttachments(attachments.attachments);
    }

    const lastLevelActivities_get = await constraintService.getLookahead({
      sector_id: projectState.sectorSelected,
      start: moment(),
      end: moment(),
      ignore_dates: true
    });

    setLastLevelActivities(lastLevelActivities_get);

    /** add user to constraints */

    const constraints = await getConstraintsFetch();

    setTrending(constraints);
    if (reLoad) {
      setActivities([
        {
          name: t('constraints_tooltip'),
          tasks: constraints
        }
      ]);
    }
    setToSelectResponsables(responsables.users);

    /** get Constraint Types */
    const constraintTypesGet = await constraintTypeService.showByProject(
      projectState.projectSelected
    );
    if (constraintTypesGet) {
      const typeFrom = tableConfig.find(
        (col) => col.name == 'constraintTypeId'
      );
      typeFrom.from_values.splice(0, typeFrom.from_values.length);
      constraintTypesGet.constrainttypes.map((type, index) => {
        typeFrom.from_values.push({
          value: type.id,
          label: type.name,
          weigth: index + 1
        });
      });

      const tableStoreConfig = constraintState.columnsSizes?.length
        ? constraintState.columnsSizes
        : tableConfig;
      const table = adjustColumnsSize({
        activatedColumns: constraintState.activatedColumns,
        configTable: tableStoreConfig,
        widthScreen: width,
        columnResized: false
      });

      setTableConfig(table);
      setConstraintTypes(constraintTypesGet.constrainttypes);
    }

    if (reLoad) {
      setLoading(false);
    }
    return [
      {
        name: t('constraints_tooltip'),
        tasks: constraints
      }
    ];
  }
  /**
   * This function updates the state of table metadata
   * @param {*} tMetadata Object with same attr than tableConfig to replace at view state
   */
  const updateTableMetadata = (
    tMetadata = tableConfig,
    columnResized = false
  ) => {
    let table = cloneDeep(tMetadata || tableConfig);
    table = adjustColumnsSize({
      activatedColumns: table
        .filter((column) => column.visible)
        .map((column) => column.name),
      configTable: table,
      widthScreen: width,
      columnResized
    });

    table.forEach((column) => {
      if (
        column.data_type.includes('/icon') ||
        column.data_type.includes('/string')
      ) {
        column.label = t(
          'tables.lookahead.constraints.' + column.name + '.label'
        );
        column.from_values.map((option) => {
          option.label = t(
            'tables.lookahead.constraints.' +
              column.name +
              '.options.' +
              option.value
          );
        });
      } else {
        column.label = t('tables.lookahead.constraints.' + column.name);
      }
    });

    setTableConfig(table);
    dispatch(constraintActions.setColumnsSizes(table));
  };

  /**
   * This functions update the state of activities at view
   */
  const updateRender = async (ac = activities) => {
    setActivities([...ac]);
    // eslint-disable-next-line prefer-const
    let data = await getConstraints(false);
    data.sort(firstBy('id', { direction: 'asc', ignoreCase: true }));
    data[0].childRended = data[0].tasks.length;
    setActivitiesReport([...data]);
  };

  /**
   * This function is used by datepickers to save new valeus to component state
   * @param {*} value Moment date object
   * @param {*} dateString String date
   */
  const changeDateState = (value, dateString) => {
    if (dateString[0] != '' && dateString != '') {
      setDateRange((prev) => {
        prev.start = dateString[0];
        prev.end = dateString[1];
        return prev;
      });
      setGroupBy((prev) => {
        prev = { criteria: 'status', sort: 'asc' };
        return prev;
      });
      getConstraints();
    } else {
      setActivities([]);
    }
  };

  /**
   * This function executes massiveSelectionHandler then sets the selection state to
   * handle massive actions like deleting, changing responsable, etc.
   * @param {*} selection Initial selection
   */
  const recursivelySelection = (selection) => {
    /** Temporal array to save added task */
    const toAdd = [];

    /** Temporal array to save deleted task */
    const toDelete = [];

    /** Execution of recursively selection */
    massiveSelectionHandler(selection, null, toAdd, toDelete);

    /** Setting state of massive actions */
    setMassiveSelection((prev) => {
      /* Take off elements of massive actions state */
      const newState = differenceBy(prev, toDelete, 'id');

      /** Then we check those elements that arent at the state and add them */
      toAdd.map((el) => {
        const doesExist = newState.find((single) => el.id == single.id);
        if (!doesExist) {
          if (el.tasks && !el.hide) {
            newState.push(el);
          }
          // newState.push(el)
        }
      });

      prev = cloneDeep(newState);
      return prev;
    });
  };

  const updateAsyncConstraint = (constraint) => {
    constraintService.update(constraint);
    socket.emit('constraint_update', {
      sector: projectState.sectorSelected,
      constraint: constraint
    });
  };

  const notification = async (constraint, prev = false, report = false) => {
    const user = getSignedUser();
    if (report) {
      constraint.report_user &&
        (await notificationService.createConstraint({
          user,
          constraint,
          module: 'constraints',
          type_notification: 'assign_report'
        }));
    } else {
      prev !== constraint.userId &&
        (await notificationService.createConstraint({
          user,
          constraint,
          module: 'constraints',
          type_notification: 'assign_responsible'
        }));
    }
    if (prev && prev !== constraint.userId) {
      const copy = { ...constraint, userId: prev };
      await notificationService.createConstraint({
        user,
        constraint: copy,
        module: 'constraints',
        type_notification: 'unassign_responsible'
      });
    }
    dispatch(userActions.setUserUpdate());
  };

  /**
   * This function handles with massive selection tasks
   * @param {*} selection Element to change selecton status by it active attribute
   * @param {*} flag This saves the parent selected status to match with their children
   * @param {*} toAdd Array with tasks to add to state array with massive selection
   * @param {*} toDelete Array with tasks to delete from state array with massive selection
   */
  const massiveSelectionHandler = (
    selection,
    flag = null,
    toAdd = [],
    toDelete = []
  ) => {
    /** Define array of children (activity or task) */
    const childs = selection.id ? null : selection.tasks;

    /** Defines first flag value from calling this function without flag */
    if (flag == null) {
      flag = !selection.active;
      selection.active = !selection.active;
    }

    /** Matchs the values to dont change already setted correct value */
    if (flag != selection.active) {
      selection.active = !selection.active;
    }

    /** Defines if the current selection must be deleted or added to state array of massive selection */
    if (selection.id) {
      if (selection.active) {
        toAdd.push(selection);
      } else if (!selection.active) {
        toDelete.push(selection);
      }
    }

    /** Recursively repetition for children */
    if (childs && childs.length) {
      childs.map((el) => {
        massiveSelectionHandler(el, flag, toAdd, toDelete);
      });
    }
  };

  /** this function shown modal dialog to confirm action (rescheduling) */
  const showConfirm = (dateString) => {
    confirm({
      title: t('lookahead_constraints.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('lookahead_constraints.warning_schedule'),
      onOk() {
        massiveCommitmentHandle(dateString);
      },
      onCancel() {
        updateRender();
      }
    });
  };

  /**
   * This function handles the drag n drop movements for change cols disposition
   * @param {*} oldIndex Index from the array that is dragged
   * @param {*} newIndex Index from the element that the dragged el is going to be moved
   */
  const moveCard = async ({ oldIndex, newIndex }) => {
    const dragCard = tableConfig[oldIndex];

    const a = updateInmmutability(tableConfig, {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, dragCard]
      ]
    });
    updateTableMetadata(a);
  };

  const [array, setArray] = useState([]);
  useEffect(() => {
    setArray([renderGeneralHeader(), ...activities]);
  }, [activities, isLoading, tableConfig]);

  useEffect(() => {
    if (!isLoading) {
      if (activities.length) {
        /** select constraints if ids in params */
        if (props.idsParam) {
          const massiveArray = [];
          const arrIds = props.idsParam.split('-');
          const arrIdsNumber = arrIds.map((el) => parseInt(el));
          activities[0].tasks.map((el) => {
            if (arrIdsNumber.includes(el.id)) {
              massiveArray.push(el);
            }
            return el;
          });
          setMassiveSelection(massiveArray);
          setSelectParams(true);
        }
      }
    }
  }, [isLoading]);

  /** effect for select constraints id on params */
  useEffect(() => {
    if (selectParams) {
      if (
        props.idsParam &&
        activities &&
        activities[0] &&
        activities[0].tasks
      ) {
        setTimeout(
          () => {
            const arrIds = props.idsParam.split('-');
            const arrIdsNumber = arrIds.map((el) => parseInt(el));
            const newtasks = activities[0].tasks.map((el) => {
              if (arrIdsNumber.includes(el.id)) el.active = true;
              return el;
            });
            setActivities([
              {
                name: t('constraints_tooltip'),
                tasks: newtasks
              }
            ]);
          },
          500 + activities[0].tasks.length * 30
        );
      }
    }
  }, [selectParams]);

  /**
   * This function render the table with activities and their tasks
   */
  const renderTable = () => {
    if (isLoading) {
      return (
        <Spin className="loader-spinner-lookahead-table" indicator={antIcon} />
      );
    }
    /* const array = [
                renderGeneralHeader(),
                ...activities
            ] */
    if (activities.length) {
      if (groupBy.criteria == 'no-group') {
        return (
          <CustomPaginator
            totalTablesWidth={totalTablesWidth}
            massiveSelection={massiveSelection}
            resizing={resizing}
            current={currentPage}
            setCurrentPage={setCurrentPage}
            data={array}
            itemSize={height < 700 ? height * 0.55 : height * 0.68}
            renderItem={(item, key, virtualizeRef) => {
              if (key == 0) {
                paginatorRef = virtualizeRef;
                return item;
              } else if (!resizing) {
                return (
                  <ConstraintActivity
                    t={t}
                    permission={permission}
                    scrollStates={scrollStates}
                    setScrollStates={setScrollStates}
                    virtualizeRef={virtualizeRef}
                    updateAsyncConstraint={updateAsyncConstraint}
                    massiveSelectionHandler={recursivelySelection}
                    setMassiveSelection={setMassiveSelection}
                    resizing={resizing}
                    tableMetadata={tableConfig}
                    key={key}
                    lastLvlActivity={item}
                    index={key}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    updateRender={updateRender}
                    data={activities}
                    constraintTypes={constraintTypes}
                    toSelectResponsables={toSelectResponsables}
                    handleAddConstraint={handleAddConstraint}
                    lastLevelActivities={lastLevelActivities}
                    setCountSchedule={setCountSchedule}
                    countSchedule={countSchedule}
                  />
                );
              }
            }}
            perPage={1}
          />
        );
      }
      return (
        <CustomPaginator
          totalTablesWidth={totalTablesWidth}
          massiveSelection={massiveSelection}
          itemSize={height < 700 ? height * 0.55 : height * 0.68}
          current={currentPage}
          setCurrentPage={setCurrentPage}
          data={array}
          renderItem={(item, key, virtualizeRef) => {
            if (key == 0) {
              paginatorRef = virtualizeRef;
              return item;
            } else if (!resizing) {
              return (
                <ConstraintOtherGroup
                  attachments={attachments}
                  setAttachments={setAttachments}
                  t={t}
                  permission={permission}
                  scrollStates={scrollStates}
                  setScrollStates={setScrollStates}
                  virtualizeRef={virtualizeRef}
                  updateAsyncConstraint={updateAsyncConstraint}
                  massiveSelectionHandler={recursivelySelection}
                  setMassiveSelection={setMassiveSelection}
                  resizing={resizing}
                  tableMetadata={tableConfig}
                  modifyGroupBy={modifyGroupBy}
                  key={key}
                  constraintActions={constraintActions}
                  groupParent={item}
                  index={key}
                  updateRender={updateRender}
                  constraintTypes={constraintTypes}
                  toSelectResponsables={toSelectResponsables}
                  handleAddConstraint={handleAddConstraint}
                  lastLevelActivities={lastLevelActivities}
                  data={activities}
                  setCountSchedule={setCountSchedule}
                  countSchedule={countSchedule}
                />
              );
            }
          }}
          perPage={1}
        />
      );
    }
    return (
      <Empty
        style={{ marginTop: 200 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <span>
            {t('lang') === 'es'
              ? 'No se han encontrado restricciones'
              : 'You haven’t created any Roadblocks'}
          </span>
        }
      />
    );
  };

  /**
   * This function render the header with titles for the printed tables, it allows to drag n drop the position
   * as also allow to resize the width.
   */
  const renderGeneralHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row className="fixed-tree-table-header">
        <AnimatedSortable
          useDragHandle
          items={tableConfig}
          onSortEnd={moveCard}
          axis="x"
          onDivResizeHandler={onDivResizeHandler}
          onDivResizeDoubleClicked={onDivResizeDoubleClicked}
        />
      </Row>
    );
  };

  /**
   * This function render modal Add Constraint
   */
  const handleAddConstraint = (task) => {
    setVisibleForm({ value: true, tasks: [task] });
  };

  const assignTasksToCorrespondingGroup = (activities = [], newConstraint) => {
    if (groupBy.criteria == 'no-group') {
      activities[0].tasks = cloneDeep(activities[0].tasks).push(newConstraint);
      return activities;
    }

    const existGroup = activities.find(
      (activity) => activity.value == newConstraint[groupBy.criteria]
    );
    for (const activityIdx in activities) {
      if (!existGroup) {
        let label = '';
        groupBy.criteria == 'responsables' &&
          (label = newConstraint[groupBy.criteria][0].email);

        groupBy.criteria == 'status' &&
          (label = t(
            `tables.lookahead.constraints.status.options.${newConstraint[groupBy.criteria]}`
          ));

        groupBy.criteria == 'priority' &&
          (label = t(
            `tables.lookahead.constraints.priority.options.${newConstraint[groupBy.criteria]}`
          ));

        const constraint = {
          value: newConstraint[groupBy.criteria],
          tasks: [newConstraint],
          label
        };

        if (groupBy.criteria == 'status') {
          activities.splice(2, 0, { ...constraint, order: 3 });
          break;
        }

        activities.push({
          ...constraint,
          order: activities[activities.length - 1].order + 1
        });
        break;
      }

      if (activities[activityIdx].value == newConstraint[groupBy.criteria]) {
        activities[activityIdx].tasks.push(newConstraint);
        break;
      }

      if (
        groupBy.criteria == 'responsables' &&
        activities[activityIdx].value ==
          newConstraint[groupBy.criteria][0].email
      ) {
        activities[activityIdx].tasks.push(newConstraint);
        break;
      }
    }

    return activities;
  };

  const [reApplyFilters, setReApplyFilters] = useState(false);

  /**
   * This function clone current activities state, and updates with new data to keep it fresh
   * @param {*} constraint Constraint added
   */
  const flushStatesToUpdateWithNewData = (constraint, ac = activities) => {
    const newActivities = assignTasksToCorrespondingGroup(
      cloneDeep(ac),
      constraint
    );
    updateRender(newActivities);
    setReApplyFilters(true);
    setTimeout(() => {
      setReApplyFilters(false);
    }, 200);
  };

  /**
   * This function update states to be sync with new data
   * @param {*} constraint New constraint to be added to tables
   */
  const updateViewWhenNoConstraintDoesExist = async (constraint) => {
    const constraints = await getConstraintsFetch();
    if (constraints) {
      if (groupBy.criteria === 'no-group') {
        setActivities([
          {
            name: t('constraints_tooltip'),
            tasks: constraints
          }
        ]);
        return;
      }

      let label;
      groupBy.criteria == 'responsables' &&
        (label = constraint[groupBy.criteria][0].email);

      groupBy.criteria == 'status' &&
        (label = t(
          `tables.lookahead.constraints.status.options.${constraint[groupBy.criteria]}`
        ));

      groupBy.criteria == 'priority' &&
        (label = t(
          `tables.lookahead.constraints.priority.options.${constraint[groupBy.criteria]}`
        ));
      flushStatesToUpdateWithNewData(constraint, [
        {
          name: label,
          value: constraint[groupBy.criteria],
          tasks: constraints.filter((c) => c.id !== constraint.id)
        }
      ]);
    }
  };

  /**
   * This function render Form Add Constraint
   */
  const renderFormConstraints = () => (
    <ConstraintForm
      t={t}
      initialTasks={visibleForm.tasks}
      visibleForm={visibleForm.value}
      setVisibleForm={(val) => setVisibleForm({ value: val, tasks: [] })}
      toSelectResponsables={toSelectResponsables}
      setConstraintAdded={() => {
        getConstraints(false);
      }}
      updateConstraints={(constraint) => {
        constraint.schedules = [];
        constraint.reportusers = [];
        constraint.responsables = [];
        constraint.responsables[0] = toSelectResponsables.find(
          (el) => el.id === constraint.userId
        );
        if (
          permission === 'ACP' &&
          constraint &&
          subtradeRole.subtrade &&
          constraint.responsables &&
          constraint.responsables[0] &&
          constraint.responsables[0].subcontractId !== subtradeRole.subtrade.id
        ) {
          return;
        }

        if (!activities.length) {
          updateViewWhenNoConstraintDoesExist(constraint);
          return;
        }

        flushStatesToUpdateWithNewData(constraint);
      }}
    />
  );

  const renderExtraItemsFilter = () => {
    /** calculate number of constraints */
    const countConstraints = activities.reduce((a, c) => {
      const count = c.tasks.reduce((ac, cu) => {
        if (!cu.hide) {
          ac += 1;
        }
        return ac;
      }, 0);
      a += count;
      return a;
    }, 0);
    return (
      <div className="counts-constraints">
        <div>
          <img width={12} className="img-constraint" src={constraintIcon} />{' '}
          {countConstraints}{' '}
          {activities[0]?.tasks?.length === 1
            ? t('constraint_label_only')
            : t('constraints_label_only')}
        </div>
        <div>
          <CalendarOutlined className="calendar-constraint" /> {countSchedule}{' '}
          {countSchedule === 1
            ? t('reschedule_only_label')
            : t('reschedules_only_label')}
        </div>
      </div>
    );
  };

  /**
   * This function render the header with filtering options for printed tables
   */
  const renderFilterHeader = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row>
        <Col>
          <LookaheadFilterHeader
            renderExtraFilterRigth={renderExtraItemsFilter}
            defaultOrderOptions={[
              {
                name: 'no-group',
                label: t('no_group'),
                switch: false
              }
            ]}
            reApplyFilters={reApplyFilters}
            disableLookahead
            isPlanification={false}
            lookaheadActions={constraintActions}
            lookaheadState={'constraintState'}
            groupBy={groupBy}
            setIsFilter={setExistFilter}
            modifyGroupBy={modifyGroupBy}
            data={
              groupBy.criteria == 'no-group' ? activities : activitiesReport
            }
            changeDateState={changeDateState}
            dateRange={dateRange}
            updateTableMetadata={updateTableMetadata}
            tableMetadata={tableConfig}
            toSelectResponsables={toSelectResponsables}
            constraintTypes={constraintTypes}
            projectSelected={projectState.projectSelected}
            updateRender={updateRender}
            lastLevelActivities={lastLevelActivities}
            handleAddConstraint={handleAddConstraint}
            isRoadblock
          />
        </Col>
      </Row>
    );
  };

  /**
   * On click function when pressing delete
   * @param {*} res resource to delete
   */
  const handleDelete = async (res) => {
    await constraintService.destroy(res.id);
  };

  /**
   * This functions shows a pretty alert to user
   * @param {*} data Object { title, message, type }
   */
  const notifyMessage = (data) => {
    const alertErrorMailExists = {
      title: data.title,
      description: data.message,
      type: data.type
    };
    openNotification(alertErrorMailExists);
  };

  const quitDismissHandler = () => {
    setMassiveResponsableState({ values: [] });
    massiveSelection.map(async (selection) => {
      let reference;
      if (groupBy.criteria == 'no-group') {
        reference = activities[0].tasks.find((el) => selection.id == el.id);
        activities[0].active = false;
      } else {
        const referenceActivity = activities.find((el) => {
          const refFindInTasks = el.tasks.find(
            (elem) => elem.id === selection.id
          );
          return Boolean(refFindInTasks); // like refFindInTasks ? true : false
        });
        reference = referenceActivity.tasks.find((el) => selection.id == el.id);
        referenceActivity.active = false;
      }
      if (reference !== undefined) {
        reference.active = false;
      }
    });
    updateRender(activities);
    setMassiveSelection([]);
  };

  /**
   * This function deletes tasks massively
   */
  const massiveDeleteHandler = async () => {
    /** Declare an async iteration */
    const asyncMap = massiveSelection.map(async (selection) => {
      const newChildrens = activities[0].tasks.filter(
        (res) => res.id !== selection.id
      );
      // activities[0].tasks = newChildrens
      activities.map((el, i) => {
        const childs = el.tasks.filter((res) => res.id !== selection.id);
        activities[i].tasks = childs;
      });
      await handleDelete(selection);
    });

    /** We await of async iteration */
    await Promise.all(asyncMap);

    /** Then update the render, notify of process, and clean the massive selection state */
    // updateRender()
    updateRender(activities);
    notifyMessage({
      title: t('lookahead_constraints.title'),
      message: t('deleted_constraint_message_label'),
      type: 'success'
    });
    setMassiveSelection([]);
  };

  const massiveStatusHandle = async (option) => {
    // if (groupBy.criteria == 'no-group') {
    let flag = false;
    const asyncMap = massiveSelection.map(async (selection) => {
      let reference;
      if (groupBy.criteria == 'no-group') {
        reference = activities[0].tasks.find((el) => selection.id == el.id);
      } else {
        const referenceActivity = activities.find((el) => {
          const refFindInTasks = el.tasks.find(
            (elem) => elem.id === selection.id
          );
          return Boolean(refFindInTasks); // like refFindInTasks ? true : false
        });
        reference = referenceActivity.tasks.find((el) => selection.id == el.id);
      }

      if (
        reference.status === 'notcompromises' &&
        option.value === 'notreleased'
      ) {
        flag = true;
        return false;
      }
      if (
        reference.status === 'notcompromises' &&
        option.value === 'released'
      ) {
        flag = true;
        return false;
      }
      reference.status = option.value;
      const user = getSignedUser();
      if (option.value === 'released') {
        reference.release_date = moment();
        reference.release_user = user.id;
      }
      if (option.value === 'notreleased') {
        reference.release_date = null;
        reference.release_user = null;
      }
      updateAsyncConstraint(reference);
    });
    if (flag) {
      notifyMessage({
        title: t('lookahead_constraints.title'),
        message: t('lookahead_constraints.change_notcommitment'),
        type: 'warning'
      });
    }
    /** We await of async iteration */
    await Promise.all(asyncMap);
    setMassiveStatusPop({ visible: false });
    updateRender(activities);
    dispatch(constraintActions.notifyLookaheadUpdateGroup());
  };

  const createSchedule = async (dateString, constraintId) => {
    const data = {
      deadline: dateString,
      fail_comment: '',
      constraintId
    };
    return constraintScheduleService.create(data);
  };

  /**
   * This function handles the massive commitment date change
   * @param {*} date date from datepicker at dom
   */
  const massiveCommitmentHandle = async (date) => {
    const asyncMap = massiveSelection.map(async (selection) => {
      const dateComm = cloneDeep(moment(date).format('YYYY-MM-DD'));
      // const reference = activities[0].tasks.find(el => selection.id == el.id)
      let reference;
      if (groupBy.criteria == 'no-group') {
        reference = activities[0].tasks.find((el) => selection.id == el.id);
      } else {
        const referenceActivity = activities.find((el) => {
          const refFindInTasks = el.tasks.find(
            (elem) => elem.id === selection.id
          );
          return Boolean(refFindInTasks); // like refFindInTasks ? true : false
        });
        reference = referenceActivity.tasks.find((el) => selection.id == el.id);
      }
      let status = reference.status;
      if (dateComm < moment().format('YYYY-MM-DD')) {
        status = 'expired';
      } else {
        status = 'notreleased';
      }
      reference.status = status;
      reference.commitmentDate = dateComm;
      /** chek if is first schedule */
      if (!reference.schedules.length) {
        const datStr = moment.utc(dateComm).startOf('day').add(12, 'hours');
        const addSch = await createSchedule(datStr, reference.id);
        reference.schedules.push(addSch);
      }
      const addSch = await createSchedule(date, reference.id);
      reference.schedules.push(addSch);
      updateAsyncConstraint(reference);
    });
    await Promise.all(asyncMap);
    setMassiveStatusPop({ visible: false });
    // updateRender()
    updateRender(activities);

    handlePopVisibility(false, 'datesMassive');
  };

  /**
   * This function handle massive change of priority
   * @param {*} option Single object with selected priority
   */
  const massivePriorityHandle = async (option) => {
    const asyncMap = massiveSelection.map(async (selection) => {
      let reference;
      if (groupBy.criteria == 'no-group') {
        reference = activities[0].tasks.find((el) => selection.id == el.id);
      } else {
        const referenceActivity = activities.find((el) => {
          const refFindInTasks = el.tasks.find(
            (elem) => elem.id === selection.id
          );
          return Boolean(refFindInTasks); // like refFindInTasks ? true : false
        });
        reference = referenceActivity.tasks.find((el) => selection.id == el.id);
      }
      reference.priority = option.value;
      updateAsyncConstraint(reference);
    });
    /** We await of async iteration */
    await Promise.all(asyncMap);
    setMassivePriorityPop({ visible: false });
    // updateRender()
    updateRender(activities);
    dispatch(constraintActions.notifyLookaheadUpdateGroup());
  };

  /**
   * This function handle massive change of priority
   * @param {*} option Single object with selected priority
   */
  const massiveTypeHandle = async (option) => {
    const asyncMap = massiveSelection.map(async (selection) => {
      // const reference = activities[0].tasks.find(el => selection.id == el.id)
      let reference;
      if (groupBy.criteria == 'no-group') {
        reference = activities[0].tasks.find((el) => selection.id == el.id);
      } else {
        const referenceActivity = activities.find((el) => {
          const refFindInTasks = el.tasks.find(
            (elem) => elem.id === selection.id
          );
          return Boolean(refFindInTasks); // like refFindInTasks ? true : false
        });
        reference = referenceActivity.tasks.find((el) => selection.id == el.id);
      }
      reference.constraintTypeId = option.id;
      updateAsyncConstraint(reference);
    });
    /** We await of async iteration */
    await Promise.all(asyncMap);
    updateRender(activities);
    dispatch(constraintActions.notifyLookaheadUpdateGroup());
  };

  /**
   * This function handles change massive responsable change
   */
  const massiveResponsableHandler = async () => {
    const findUser = toSelectResponsables.find(
      (us) => us.email === massiveResponsableState.values
    );
    if (!findUser) return;
    const asyncMap = massiveSelection.map(async (selection) => {
      let reference;
      if (groupBy.criteria == 'no-group') {
        reference = activities[0].tasks.find((el) => selection.id == el.id);
      } else {
        const referenceActivity = activities.find((el) => {
          const refFindInTasks = el.tasks.find(
            (elem) => elem.id === selection.id
          );
          return Boolean(refFindInTasks); // like refFindInTasks ? true : false
        });
        reference = referenceActivity.tasks.find((el) => selection.id == el.id);
      }
      const prevRes = reference.responsables[0]?.id || false;
      // const newConstraint = reference
      reference.users = [findUser];
      reference.responsables = [findUser];
      reference.userId = findUser.id;
      reference.active = true;
      updateAsyncConstraint(reference);
      notification(reference, prevRes);
      notification(reference, false, true);
    });
    await Promise.all(asyncMap);
    setMassiveResponsableState({ values: [] });
    updateRender(activities);
    dispatch(constraintActions.notifyLookaheadUpdateGroup());
  };

  const handlePopVisibility = async (visible, id) => {
    setPopsVisibility({
      ...popsVisibility,
      [id]: visible
    });
  };

  /**
   * This function builds a JSX to select responsables at inline edition
   */
  const renderFriend = (props, option, snapshot, className) => {
    const imgStyle = {
      borderRadius: '50%',
      verticalAlign: 'middle',
      marginRight: 10
    };

    let toShowName = `${option.object.name} ${option.object.lastname}`;

    if (toShowName) {
      if (toShowName.length > 17) {
        toShowName = toShowName.slice(0, 17) + '...';
      }
    }

    return (
      <button
        {...props}
        className={className}
        type="button"
        title={option.object.email}>
        <span>
          <img alt="" style={imgStyle} width={20} src={option.photo} />
          <span style={{ fontSize: 15 }}>{toShowName}</span>
        </span>
      </button>
    );
  };

  /** this function render list of responsables in massive selection */
  const renderMassiveResponsable = () => {
    const friends = [];
    const selected = [];
    const dictionary = {};

    massiveResponsableState.values.map((res) => {
      selected.push(res.email);
    });

    toSelectResponsables.map((user) => {
      if (user.is_active) {
        friends.push({
          name: user.name + ' ' + user.lastname + ' (' + user.email + ')',
          value: user.email,
          photo: user.image || fakeAvatar,
          object: user
        });
        dictionary[user.email] = user;
      }
    });

    return (
      <SelectSearch
        printOptions="always"
        className="select-search-massive select-search-massive--multiple"
        options={friends}
        value={selected}
        renderOption={renderFriend}
        onChange={(val) => {
          massiveResponsableState.values = val;
        }}
        search
        placeholder={t('search_res_text')}
      />
    );
  };

  /**
   * This function prints the header with actions to deal with massive selection
   */
  const renderMassiveActionHeader = () => (
    <Animated
      style={{ height: massiveSelection.length ? 30 : 0, overflow: 'hidden' }}
      animationIn="fadeIn"
      animationInDuration={250}
      animationOut="fadeOut"
      animationOutDuration={250}
      isVisible={Boolean(massiveSelection.length) || false}>
      <Row className="massive-action-header">
        <Col span={12} offset={6} style={{ height: '100%' }}>
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            style={{ height: '100%' }}>
            {massiveOnProcess ? null : (
              <Col style={{ textAlign: 'center' }}>
                {/* Selection text indicator */}
                <span style={{ color: '#7DFF8A' }}>
                  {massiveSelection.length + ' '}
                </span>
                <span style={{ color: '#FFFFFF', marginRight: 23 }}>
                  {t('constraint_label_only')}
                  {massiveSelection.length > 1
                    ? userLang.includes('es')
                      ? 'es'
                      : 's'
                    : null}{' '}
                  {t('lookahead_resources.masive_selected')}
                  {massiveSelection.length > 1
                    ? userLang.includes('es')
                      ? 's'
                      : null
                    : null}
                </span>

                {/* Massive Type */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {constraintTypes &&
                          constraintTypes.map((option, index) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              key={index}
                              onClick={() => massiveTypeHandle(option)}>
                              <span style={{ marginLeft: 5 }}>
                                {capitalize(option.name)}
                              </span>
                            </div>
                          ))}
                      </span>
                    }
                    trigger="click">
                    <Tooltip placement="top" title={t('type_only_label')}>
                      <span>
                        <img
                          className="massive-icons"
                          width={12}
                          src={typeMassive}
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive commitment date */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        <DatePicker
                          allowClear={false}
                          className="custom-date-picker-planification"
                          style={{ left: -4 }}
                          id={'massiveDateCommitment'}
                          format={'YYYY/MM/DD'}
                          placeholder={t('select_date_label_only')}
                          onChange={async (date, dateString) => {
                            const dateComm = cloneDeep(dateString);
                            showConfirm(dateComm);
                          }}
                        />
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('lookahead_constraints.commitment_date')}>
                      <img width={12} src={datesMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive Priority */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {tableMetadata
                          .find((col) => col.name == 'priority')
                          .from_values.map((option, index) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              key={index}
                              onClick={() => massivePriorityHandle(option)}>
                              <img
                                src={option.icon}
                                width={11}
                                style={{ position: 'relative', top: 0 }}
                              />
                              <span style={{ marginLeft: 5 }}>
                                {capitalize(option.label)}
                              </span>
                            </div>
                          ))}
                      </span>
                    }
                    trigger="click">
                    <Tooltip placement="top" title={t('priority_label_only')}>
                      <span>
                        <img
                          className="massive-icons"
                          width={12}
                          src={priorityMassive}
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive Status */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {tableMetadata
                          .find((col) => col.name == 'status')
                          .from_values.filter((el) => el.visible)
                          .map((option, index) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              key={index}
                              onClick={() => massiveStatusHandle(option)}>
                              <i
                                className="fas fa-circle border-icon"
                                style={{
                                  fontSize: 11,
                                  color: option.color,
                                  position: 'relative',
                                  top: -1
                                }}
                              />
                              <span style={{ marginLeft: 5 }}>
                                {capitalize(option.label)}
                              </span>
                            </div>
                          ))}
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('status_only_label_constraint')}>
                      <span>
                        <img
                          width={12}
                          src={statusMassive}
                          className="massive-icons"
                        />
                      </span>
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive Responsable */}
                <span className="massive-icon-style">
                  <Popover
                    overlayClassName="massive-selection-pop"
                    placement="bottom"
                    content={
                      <span className="progress-massive-style">
                        {renderMassiveResponsable()}
                        <div
                          className="progress-massive-btn"
                          onClick={massiveResponsableHandler}>
                          {t('apply_label')}
                        </div>
                      </span>
                    }
                    trigger="click">
                    <Tooltip
                      placement="top"
                      title={t('responsable_label_only')}>
                      <img width={12} src={responsableMassive} />
                    </Tooltip>
                  </Popover>
                </span>

                {/* Massive delete */}
                <span className="massive-icon-style">
                  <Popconfirm
                    onConfirm={() => massiveDeleteHandler()}
                    title={t('are_sure_general')}
                    icon={
                      <Icon type="question-circle-o" style={{ color: 'red' }} />
                    }>
                    <Tooltip placement="top" title={t('delete_task_label')}>
                      <img width={12} src={deleteMassive} />
                    </Tooltip>
                  </Popconfirm>
                </span>

                {/* Quit Dismiss */}
                <Tooltip placement="top" title={t('cancel_only_label')}>
                  <span
                    className="massive-icon-style"
                    onClick={quitDismissHandler}>
                    <img
                      width={12}
                      src={quitDismissIcon}
                      style={{ position: 'relative', top: -1 }}
                    />
                  </span>
                </Tooltip>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Animated>
  );

  /**
   * Render
   */

  const renderWithPermission = () => {
    if (permission == 'ACP' || permission == 'AC' || permission == 'V') {
      return (
        <Animated
          animationIn="fadeIn"
          animationInDuration={500}
          isVisible={true}>
          <Row>
            <Col>
              {renderFilterHeader()}
              {renderMassiveActionHeader()}
              {renderTable()}
            </Col>
          </Row>
          {/* Component Form Constraints */}
          {renderFormConstraints()}
        </Animated>
      );
    }
    return <div>{t('non_permission_access')}</div>;
  };

  /**
   * Render
   */
  return renderWithPermission();
}

export default withTranslation()(ConstraintView);
