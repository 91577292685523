import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../../redux/slices/projectSlice';
import { userService } from '../../../../services';
import { PlusOutlined } from '@ant-design/icons';
import { filterProjectsList } from '../../../../utils';
import useConfigurationPermissions from '../../../../hooks/useConfigurationPermissions';
import DropdownSystemV2 from '../../../DesignSystem/DropdownSystemV2/DropdownSystemV2';
import { TYPE_VIEWS } from './constants';
import ToolbarRight from './ToolbarRight';
import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { getSessionTokenData } from '../../../../utils/userUtils';
import Search from '../../../Search/Search';

function FiltersHeader({ t, typeView, setTypeView, setFilterText, zoomLevel }) {
  const dispatch = useDispatch();

  const projectState = useSelector((state) => state.projectState);
  const permissions = useConfigurationPermissions();
  const stateCompany = useSelector((state) => state.companyState);

  const [filter] = useState(projectState.props.filter || 'stage');

  useEffect(() => {
    dispatch(
      projectActions.setProps({
        ...projectState.props,
        filter: filter || filterProjectsList[0].value
      })
    );
  }, []);

  const renderButtonCreateProject = () => {
    const userStorage = getSessionTokenData();
    if (!userStorage) return null;

    if (
      userStorage.role === 'superadmin' ||
      (userStorage.role === 'admin' &&
        stateCompany?.currentCompany?.contract_type === 'enterprise')
    ) {
      return (
        <button
          disabled={permissions.project !== 'AC'}
          onClick={() =>
            dispatch(
              projectActions.setProps({
                ...projectState.props,
                openAddProject: true
              })
            )
          }>
          <PlusOutlined className="create-project__icon" />
          <span>{t('project_header.add_project')}</span>
        </button>
      );
    }

    return null;
  };
  const user = JSON.parse(localStorage.getItem('user'));
  const sessionTokenData = getSessionTokenData();
  const items = [
    {
      key: TYPE_VIEWS.CARD.key,
      label: t(`project_header.${TYPE_VIEWS.CARD.label}`),
      icon: TYPE_VIEWS.CARD.icon,
      onClick: () => {
        setTypeView('CARD');
        userService.setUserProjectsView(user.id, 'CARD');
        trackingEvent(
          'timeline_view_selected',
          {
            user_id: user.id,
            view_type: 'CARD',
            role: sessionTokenData.role
          },
          AMPLITUDE_SERVICE
        );
      },
      isDisabled: false,
      isGroup: false,
      isDivider: false,
      isActive: TYPE_VIEWS[typeView]?.key === TYPE_VIEWS.CARD.key,
      subitems: []
    },
    {
      key: TYPE_VIEWS.TIMELINE.key,
      label: t(`project_header.${TYPE_VIEWS.TIMELINE.label}`),
      icon: TYPE_VIEWS.TIMELINE.icon,
      onClick: () => {
        setTypeView('TIMELINE');
        userService.setUserProjectsView(user.id, 'TIMELINE');
        trackingEvent(
          'timeline_view_selected',
          {
            user_id: user.id,
            view_type: 'TIMELINE',
            role: sessionTokenData.role
          },
          AMPLITUDE_SERVICE
        );
      },
      isDisabled: false,
      isGroup: false,
      isDivider: false,
      isActive: TYPE_VIEWS[typeView]?.key === TYPE_VIEWS.TIMELINE.key,
      subitems: []
    }
  ];

  const updateFilterText = (value) => {
    setFilterText(value);
  };

  return (
    <div id="toolbar_filters_header" className="toolbar-filters-header">
      <div className="filters-header__left">
        <Search t={t} callback={updateFilterText} typeView={typeView} />

        <div className="left__dropdown">
          {DropdownSystemV2({
            theme: 'light',
            version: 'v2',
            trigger: ['click'],
            height: 32,
            label: t(`project_header.${TYPE_VIEWS[typeView].label}`),
            icon: TYPE_VIEWS[typeView].icon,
            items
          })}
        </div>
      </div>
      <div className="filters-header__right">
        {typeView === 'TIMELINE' && (
          <div className="right__toolbar">
            <ToolbarRight t={t} zoomLevel={zoomLevel} />
          </div>
        )}
        <div className="right__create-project">
          {renderButtonCreateProject()}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(FiltersHeader);
