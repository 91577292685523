import { base } from '../services/base';
import {
  PROD,
  PROD2,
  LOCAL,
  QA,
  DEV,
  AMPLITUDE_TOKEN_LOCAL,
  AMPLITUDE_TOKEN_PROD,
  ALL_SERVICES,
  AMPLITUDE_SERVICE,
  TOTANGO_SERVICE
} from './constants';

const enabledEnviroment = [PROD, PROD2, LOCAL, QA, DEV];
const enabledAnalyticsService = [
  ALL_SERVICES,
  AMPLITUDE_SERVICE,
  TOTANGO_SERVICE
];

const amplitudeTokens = (enviroment) => {
  const tokens = {
    local: AMPLITUDE_TOKEN_LOCAL,
    qa: AMPLITUDE_TOKEN_LOCAL,
    dev: AMPLITUDE_TOKEN_LOCAL,
    prod: AMPLITUDE_TOKEN_PROD,
    prod2: AMPLITUDE_TOKEN_PROD
  };

  return tokens[enviroment] || tokens[LOCAL];
};

export const getSectorCompanyAndProject = () => {
  const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
  const currentProject = JSON.parse(sessionStorage.getItem('currentProject'));
  const currentCompany = JSON.parse(sessionStorage.getItem('company'));
  return {
    currentSector,
    currentProject,
    currentCompany
  };
};

export const getBasicAmplitudEventProperties = () => {
  const { currentSector, currentProject, currentCompany } =
    getSectorCompanyAndProject();
  return {
    company_name: currentCompany?.name,
    company_id: currentCompany?.id,
    project_name: currentProject?.name,
    project_id: currentProject?.id,
    stage_name: currentSector?.name,
    stage_id: currentSector?.id
  };
};

export const isEnabledEnviroment = () =>
  enabledEnviroment.some((enviroment) => enviroment === base.returnEnviroment);

export const getTokenAmplitude = () => amplitudeTokens(base.returnEnviroment);

export const isEnabledAnalyticsService = (serviceName) =>
  enabledAnalyticsService.some(
    (currentService) => currentService === serviceName
  );
