/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react';
/** PNG to put on users without image */
import fakeAvatar from '../../../../assets/img/fake_user.png';
import noImageProject from '../../../../assets/img/noimage.png';

/**  import library for animations effects */
import { Animated } from 'react-animated-css';

/** Import elements from library Antd */
import { Avatar, Col, Tooltip, Spin } from 'antd';

/** import icons from antd framework */
import { CaretDownOutlined, LoadingOutlined } from '@ant-design/icons';

/** Moment for dates using */
import moment from 'moment';

import {
  userService,
  projectService,
  sectorService
} from '../../../../services';

/** import components */
import ActionsMenu from '../../ActionsMenu'; /** menu project (edit copy delete) */

import { compareValues } from '../../../../utils';
/** Redux */
import { useDispatch, useSelector } from 'react-redux';
import * as projectActions from '../../../../redux/slices/projectSlice';
import { withTranslation } from 'react-i18next';
moment.locale('es');

function ItemList(props) {
  const { t } = props;
  const { project } = props;
  const [loading, setLoading] = useState(true); /** handle image load */

  const [proyectProgress, setProyectProgress] = useState('');
  const [lastweekcommitments, setLastweekcommitments] = useState('');
  const [lastweekcommitmentstask, setLastweekcommitmentstask] = useState('0');
  const [constraints, setConstraints] = useState('0');
  const [constraintsArray, setConstraintsArray] = useState([]);
  const [constraintscount, setConstraintscount] = useState('No Data');
  const [users, setUsers] = useState([]);
  const [endDateArr, setEndDateArr] = useState([]);

  const numUsers = 5;

  /** redux */
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);

  useEffect(() => {
    const abortController = new AbortController();
    sectorService
      .getSectorsByProjectWithMasterActivity(project.id)
      .then(async (response) => {
        if (response.sectors) {
          /** select active sectors  */
          const filterSectors = response.sectors.filter(
            (e) => e.projectId === project.id && e.status === true
          );
          const arrEndDate = [];
          filterSectors.map((sec) => {
            if (sec.activities[0] && sec.activities[0].end_date) {
              arrEndDate.push({
                id: sec.id,
                end_date: sec.activities[0].end_date
              });
            }
          });
          setEndDateArr(arrEndDate);
        }
      })
      .catch((err) => console.log(err));

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    /** get week number */
    if (project) {
      getProyect();
      getUser();
    }
  }, [project]);

  async function getUser() {
    const users_ = await userService.getByProject(project.id);
    setUsers(users_.users.filter((el) => el.is_active));
  }

  async function getProyect() {
    const project_s = await projectService.showAnalitic(project.id);
    getPpcLastWeek(project_s.weekcommitments);
    getPcr(project_s.constraints);
  }

  function getPpcLastWeek(weekcommitments) {
    let total = 0;
    let realized = 0;
    let ppc_exist = false;
    const actual_week_ = moment(new Date()).week();
    const actual_week = actual_week_ - 1;
    weekcommitments.map((weekcommitment) => {
      let weeknumber = moment(weekcommitment.start_date);
      if (weeknumber.week() == actual_week) {
        ppc_exist = true;
        total = total + weekcommitment.commitment_tasks;
        realized = realized + weekcommitment.realized_tasks;
      }
    });
    setLastweekcommitmentstask(realized + '/' + total + ' Tareas');
    const commitment_percentaje = (realized / total) * 100;
    if (total > 0) {
      setLastweekcommitments(commitment_percentaje.toFixed(2) + '%');
    } else {
      if (ppc_exist) {
        setLastweekcommitments('0' + '%');
      } else {
        setLastweekcommitments('0' + '%');
      }
    }
  }

  function getPcr(constraints) {
    let total = 0;
    let realized = 0;
    let ppc_exist = false;
    const actual_week = moment(new Date()).week();
    constraints.map((constraint) => {
      const commitmentDate = moment(constraint.commitmentDate);
      if (commitmentDate.week() == actual_week) {
        ppc_exist = true;
        total++;
        if (constraint.status == 'released') {
          realized++;
        }
      }
    });

    if (total > 0) {
      setConstraints(((realized / total) * 100).toFixed(2) + '%');
    } else {
      if (ppc_exist) {
        setConstraints('0' + '%');
      } else {
        setConstraints('0' + '%');
      }
    }
    setConstraintscount(realized + '/' + total + ' Restricciones');
  }

  /** get min of array of objects (date_end) */
  const getMinMaxDate = (tasks, field = 'end_date', type = 'min') => {
    const min = tasks.reduce((prev, curr) => {
      if (type === 'min') {
        return prev[field] < curr[field] ? prev : curr;
      }
      return prev[field] > curr[field] ? prev : curr;
    }, moment());
    return min[field];
  };

  async function getSectors() {
    const resp = await sectorService.index();
    return resp ? resp.sectors : false;
  }

  const maxEndDate = getMinMaxDate(endDateArr, 'end_date', 'max');

  /** render */
  return (
    <Col span={24} align="center">
      <Spin
        indicator={<LoadingOutlined />}
        className={loading ? 'imgProjectSpin' : 'imgProjectSpin hidden'}
      />
      <Animated animationIn="fadeIn" animationInDuration={1000}>
        <div
          className={
            loading ? 'cardProject itemList hidden' : 'cardProject itemList'
          }
          onClick={async () => {
            dispatch(projectActions.setProject(project.id));

            const sectors = await getSectors();

            if (sectors) {
              /** select active sectors  */
              let filterSectors = sectors.filter(
                (e) => e.projectId === project.id && e.status === true
              );
              filterSectors.sort(compareValues('order'));

              /** update state sectors */
              dispatch(projectActions.setAllSectors(filterSectors));

              /** set sector by default */
              const firstSector =
                filterSectors[0] && filterSectors[0].id
                  ? filterSectors[0].id
                  : null;
              if (!projectState.sectorSelected) {
                /** set sector by default */
                if (filterSectors[0] && filterSectors[0].id) {
                  dispatch(projectActions.setSector(firstSector));
                }
              } else {
                /** sector selected exist */
                const findSector = filterSectors.find(
                  (e) => e.id === projectState.sectorSelected
                );
                if (findSector) {
                  /** belongs to current project? */
                  dispatch(
                    projectActions.setSector(projectState.sectorSelected)
                  );
                } else {
                  dispatch(projectActions.setSector(firstSector));
                }
              }
            }

            setTimeout(() => {
              props.history.push(
                'analytics' /* `/analytics/dashboard/${project.id}` */
              );
            }, 150);
          }}>
          <div className="logoproject">
            <img
              src={project.image ? project.image : noImageProject}
              className="imgProject"
              onLoad={() => setLoading(false)}
              alt={project.name}
            />
            <ActionsMenu {...props} project={project} />
          </div>
          <div className="infoproject">
            <div>
              <span className="title-project">{project.name}</span>&nbsp;&nbsp;
              <span>{project.address || t('without_direction')}</span>
            </div>
            <div>
              {t('project_header.date_end')} {moment(maxEndDate).format('LL')}
            </div>
          </div>
          <div className="responsables">
            {users.length - numUsers > 0 ? (
              <div className="more-users">+{users.length - numUsers}</div>
            ) : null}
            {users.map((e, i) => {
              if (i > numUsers - 1) return null;
              return (
                <Tooltip title={e.email}>
                  <Avatar
                    key={i}
                    style={{ left: 25 * i }}
                    className="avatar-btn"
                    shape="circle"
                    size={26}
                    icon="user"
                    src={e.image ? e.image : fakeAvatar}
                  />
                </Tooltip>
              );
            })}
          </div>
          <div>
            <div className="titleList">PPC</div>
            <div className="mismatch blue-text">{lastweekcommitments}</div>
          </div>
          <div>
            <div className="titleList">PCR</div>
            <div className="pcr red-text">{constraints}</div>
          </div>
          <div>
            {/* <div className="titleList">Desfase</div>
                        <div className="mismatch red-text">
                            <CaretDownOutlined />24
                        </div> */}
          </div>
          <div>
            {/* <Progress
                            type="circle"
                            percent={75}
                            className='red-text'
                            width={60}
                            strokeColor={'red'}
                            strokeWidth={10}
                        /> */}
          </div>
        </div>
      </Animated>
    </Col>
  );
}

export default withTranslation()(ItemList);
