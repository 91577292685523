import React from 'react';
import './styles.scss';
import useMasterplanPermissions from '../../../../hooks/useMasterplanPermissions';
import { getModuleView } from '../../../../utils/projectUtils';
import { HOME_TIMELINE_VIEW } from '../../../../hooks/useSelectProject';

const handleProjectClick = (gantt, project, defaultModuleView) => () => {
  if (!gantt.processedProjects[project.id]) {
    return;
  }

  gantt.onClickProject(
    gantt.processedProjects[project.id],
    HOME_TIMELINE_VIEW,
    defaultModuleView,
    gantt.analytics
  );
};

const ProjectCell = ({ gantt, project, mainSchedule }) => {
  const userGanttPermissions = useMasterplanPermissions();
  const defaultModuleView = getModuleView(userGanttPermissions.gantt.gantt);
  return (
    <div className="project-cell">
      <span
        className="name"
        onClick={handleProjectClick(gantt, project, defaultModuleView)}>
        {project.name}
      </span>{' '}
      <span className="main-schedule">{mainSchedule}</span>
    </div>
  );
};

export default ProjectCell;
