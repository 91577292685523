import React from 'react';
import ReactDOM from 'react-dom';
import { Gantt } from '../../../../assets/gantt/dhtmlxgantt';
import columns from './columns';
import { changeScaleVisualization, fixScaleDateHeader } from './utils';
import { init_scroll_zoom_config } from '../../../../assets/js/gantt_layout/scroll-zooming-conf';
import { taskRowClass } from './templates';
import { adjustGridWidth } from '../../../../assets/js/functions/adjustGridWidth';
import { zoomLevels } from '../../../GanttVisualization/constants';

const generalLayout = {
  css: 'gantt_container schedule-of-schedules',
  cols: [
    {
      width: 900,
      rows: [
        {
          view: 'grid',
          scrollX: 'gridScroll',
          scrollable: true,
          scrollY: 'scrollVer'
        },
        { view: 'scrollbar', id: 'gridScroll', group: 'horizontal' }
      ]
    },
    { resizer: true, width: 1 },
    {
      rows: [
        { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
        { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' }
      ]
    },
    { view: 'scrollbar', id: 'scrollVer' }
  ]
};

const externalRender = {
  isElement: (element) => React.isValidElement(element),
  renderElement: (element, container) => ReactDOM.render(element, container)
};

const rightSideText = (start, end, task) => {
  return `<span class="right-size-text">${task.name}</span>`;
};

const plugins = {
  marker: true
};

const config = {
  open_tree_initially: true,
  external_render: externalRender,
  duration_unit: 'day',
  work_time: false,
  date_format: '%Y-%m-%d %H:%i',
  drag_links: false,
  drag_progress: false,
  drag_resize: false,
  drag_move: false,
  layout: generalLayout,
  details_on_dblclick: false
};

const createMarkerFactory = (gantt, t) => () => {
  gantt.addMarker({
    start_date: new Date(),
    css: 'today-custom-timeline',
    text: t('today_label_only'),
    title:
      t('today_label_only') +
      ': ' +
      gantt.date.date_to_str(gantt.config.task_date)(new Date())
  });
};

const scrollToTodayAtChart = (gantt) => () => gantt.showDate(new Date());

const onBeforeLightbox = () => () => false;
const onAfterZoom =
  (gantt, { setZoomLevel }) =>
  () => {
    const currentZoomLevel = gantt.ext.zoom.getCurrentLevel();
    setZoomLevel(zoomLevels.length - 1 - currentZoomLevel);
  };
const onGridResizeEnd = (gantt) => () => {
  setTimeout(() => {
    gantt.adjustGridWidth();
  }, 500);
  return true;
};

const EVENT_HANDLERS = {
  gantt: { onBeforeLightbox, onGridResizeEnd },
  zoom: { onAfterZoom }
};

const ganttEventSources = (gantt) => ({
  gantt,
  zoom: gantt.ext.zoom
});

const addEvent = (gantt) => (source, event, handler) => {
  const eventSources = ganttEventSources(gantt);
  const eventId = eventSources[source].attachEvent(event, handler);
  gantt.eventIds[source].push(eventId);
};

const removeEvents = (gantt) => () => {
  const eventSources = ganttEventSources(gantt);
  Object.entries(eventSources).forEach(([source, eventSource]) => {
    const eventIds = gantt.eventIds[source];
    while (eventIds.length) {
      eventSource.detachEvent(eventIds.pop());
    }
  });
};

export const ScheduleOfSchedulesBuilder = {
  create() {
    this.gantt = Gantt.getGanttInstance();
    this.gantt.eventIds = {
      gantt: [],
      zoom: []
    };
  },
  addColumns(options) {
    this.gantt.config.columns = columns(this.gantt, options);
  },
  addPlugins() {
    this.gantt.plugins(plugins);
  },
  addCustomFunctions(options) {
    this.gantt.fixScaleDateHeader = fixScaleDateHeader(this.gantt);
    this.gantt.templates.rightside_text = rightSideText;
    this.gantt.scrollToTodayAtChart = scrollToTodayAtChart(this.gantt);
    this.gantt.adjustGridWidth = adjustGridWidth(this.gantt);
    this.gantt.handleDelete = options.handleDelete;
    this.gantt.handleEdit = options.handleEdit;
    this.gantt.processedProjects = options.processedProjects;
    this.gantt.onClickProject = options.onClickProject;
    this.gantt.addEvent = addEvent(this.gantt);
    this.gantt.removeEvents = removeEvents(this.gantt);
  },
  configure(options) {
    this.gantt.config = { ...this.gantt.config, ...config };
    this.gantt.ext.zoom.init(init_scroll_zoom_config(this.gantt));
    this.gantt.changeScaleVisualization = changeScaleVisualization(this.gantt);
    this.gantt.createMarker = createMarkerFactory(this.gantt, options.t);
    this.gantt.optimizedRender = this.gantt.render;
    this.gantt.analytics = options.analytics;
  },
  addTemplates() {
    this.gantt.templates.task_class = taskRowClass;
  },
  configureEvents(options) {
    Object.entries(EVENT_HANDLERS).forEach(([source, handlers]) =>
      Object.entries(handlers).forEach(([name, event]) =>
        this.gantt.addEvent(source, name, event(this.gantt, options))
      )
    );
  },
  get() {
    return this.gantt;
  }
};
