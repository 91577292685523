/* eslint-disable no-useless-escape */
/* eslint-disable no-case-declarations */

/** React components  */
import React, { useState, useMemo, useEffect } from 'react';

/** library to redirect  */
import { Redirect, useLocation } from 'react-router-dom';

/** Import elements from library Antd */
import { Icon, Row, Spin } from 'antd';

import { socket } from '../../../services/socket.service';

/** import icons from antd framework */
import { CaretRightOutlined } from '@ant-design/icons';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import * as projectActions from '../../../redux/slices/projectSlice';

/** components */
import ItemCard from '../../../components/Projects/ListProjects/ItemCard';
import ItemList from '../../../components/Projects/ListProjects/ItemList';
import FormProjects from '../../../components/Projects/FormProjects';
import FormDelete from '../../../components/Projects/FormDelete';

/** import Services */
import { projectService, userService } from '../../../services';

import { Animated } from 'react-animated-css';

/** import common functions from utils */
import {
  groupBy,
  dynamicSort,
  setAllProjectsGlobal,
  compareValues,
  getTodayWeekWithPday
} from '../../../utils';

/** import common functions from helper */
import { getLabel, filterProjects, getHeightSchedule } from '../project.helper';
import '../project.css';
import { useTranslation, withTranslation } from 'react-i18next';
import FormProjectsDummy from '../../../components/Projects/FormProjectsDummy/FormProjectsDummy';

import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import AddProject from '../../../components/Projects/AddProjectModal';
import ScheduleOfSchedules from '../../../components/ScheduleOfSchedules';
import { TYPE_VIEWS } from '../../../components/Projects/Filters/FiltersHeader/constants';
import Filters from '../../../components/Projects/Filters/FiltersHeader';
import useMasterplanPermissions from '../../../hooks/useMasterplanPermissions';
import { getAnalitycsByProject } from '../../weeklyPlan/weeklyPlan.helper';
import {
  getPPC,
  usePpcIndicatorProject
} from '../../../hooks/usePpcIndicatorProject';
import {
  getPRR,
  usePcrIndicatorProject
} from '../../../hooks/usePcrIndicatorProject';
import Loader from '../../../layout/loader';
import {
  HOME_CARD_VIEW,
  useSelectProject
} from '../../../hooks/useSelectProject';
import { getSessionTokenData, getSignedUser } from '../../../utils/userUtils';
import {
  filterByName,
  isProjectListEmpty,
  isFilteredProjectListEmpty
} from '../../../utils/projectUtils';
import EmptyFilteredProjects from '../EmptyFilteredProjects/EmptyFilteredProjects';
import EmptyHome from '../EmptyHome/EmptyHome';
import { getBasicAmplitudEventProperties } from '../../../analytics/utils';
import './projectList.scss';
import notificationSystemV2 from '../../../components/DesignSystem/NotificationSystemV2/NotificationSystemV2';

// TODO: review for each for projects (render)
function ProjectsView(props) {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const { onClickProject } = useSelectProject();

  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const [typeView, setTypeView] = useState('CARD');
  const { t, ready } = useTranslation();
  const [filter, setFilter] = useState('stage'); // handle filter option ()
  const [projectsGrouped, setProjectsGrouped] = useState(null); // handle filter option ()
  const [groupedByKey, setGroupedByKey] = useState(); // handle filter option ()
  const [loadingProjects, setLoadingProjects] =
    useState(true); /** handle porjects load */
  const [formProjectVisible, setFormProjectVisible] =
    useState(false); /** handle form project */
  const [formProjectVisibleDummy, setFormProjectVisibleDummy] =
    useState(false); /** handle form project */
  const [formDeleteVisible, setFormDeleteVisible] =
    useState(false); /** handle form delete/ archive project */
  const [isDummy, setIsDummy] = useState(false); /** handle isDummy flag */
  const [processedProjects, setProcessedProjects] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [filterResultsCount, setFilterResultsCount] = useState(0);

  const user = getSignedUser();
  const sessionTokenData = getSessionTokenData();
  /** Permissions */
  const userGanttPermissions = useMasterplanPermissions();
  const [zoomLevel, setZoomLevel] = useState(4);

  useEffect(() => {
    if (!ready || query.get('error') !== 'unauthorized') {
      return;
    }

    notificationSystemV2({
      key: 'error_views.no_project_access.toast',
      type: 'error',
      message: t('error_views.no_project_access.toast')
    });
  }, [ready]);

  const shouldExecuteAnalytics = () =>
    typeView !== 'CARD' ||
    !projectsGrouped ||
    !groupedByKey ||
    groupedByKey.length === 0;

  useEffect(() => {
    if (shouldExecuteAnalytics()) {
      return;
    }

    const results = Object.values(groupedByKey).reduce(
      (res, projectType) =>
        res +
        projectsGrouped[projectType.value].filter(filterByName(filterText))
          .length,
      0
    );

    setFilterResultsCount(results);

    if (results > 0) {
      return;
    }

    trackingEvent(
      'search_box_no_results',
      {
        search_text: filterText,
        event_source: HOME_CARD_VIEW
      },
      AMPLITUDE_SERVICE
    );
  }, [filterText, groupedByKey]);

  useEffect(() => {
    window.Appcues.page();
  });

  useEffect(() => {
    if (projectState.props.openFormProject) {
      setFormProjectVisible(true);
    }
  }, [projectState.props.openFormProject]);

  useEffect(() => {
    if (projectState.props.isDummy) {
      setFormProjectVisibleDummy(true);
      setIsDummy(true);
    }
  }, [projectState.props.isDummy]);

  const updateProjectsListData = () => {
    getProjectsLoad();
  };

  const realTimeProjectAdd = (projectAdd) => {
    updateProjectsListData();
  };

  useEffect(() => {
    socket.on('project_add_' + sessionTokenData?.companyId, realTimeProjectAdd);
    return () => {
      socket.off(
        'project_add_' + sessionTokenData?.companyId,
        realTimeProjectAdd
      );
    };
  }, []);

  useEffect(() => {
    if (user) {
      userService.getUserProjectsView(user.id).then((projectsView) => {
        setTypeView(projectsView);
      });
    }
  }, []);

  const getProjectsLoad = async () => {
    if (!user) return;
    const getProjects = await userService.projectsbyuserthrough(user.id);
    const { projects } = getProjects;
    if (!projects) return;
    const projectsActive = projects.filter((e) => e.stage !== 'deleted');
    updateProjectsList(projectsActive);
    setLoadingProjects(false);

    trackingEvent(
      'home_visualization',
      {
        project_count: projectsActive?.length || 0
      },
      AMPLITUDE_SERVICE
    );
  };

  const getCurrentSectorId = (sectors) => {
    for (const sector of sectors) {
      if (sector.set_current === true) {
        return sector.id;
      }
    }
    return null;
  };

  useEffect(() => {
    /** filter array of  projects */
    const searchProjectFilter = filterProjects(projectState);
    const getFilter = projectState.props?.filter || 'stage';
    setFilter(getFilter);

    /** group projects from state */
    const grouped = groupBy(
      searchProjectFilter || projectState.allProjects,
      'stage'
    );

    /** create array for sort by key (value is the group name) */
    const groupedSorted = Object.keys(grouped).map((el) => ({ value: el }));

    /** sort by key desc */
    let order = ''; /** normal asc */
    /** get order from state  */
    if (projectState.props.order === 'a-z') {
      order = '';
    } else {
      order = '-';
    }

    /** sort by value (order : 1, asc, -1 desc ) */
    groupedSorted.sort(dynamicSort(`${order}value`));

    /** update state */
    setGroupedByKey(groupedSorted);

    if (JSON.stringify(projectsGrouped) !== JSON.stringify(grouped)) {
      setProjectsGrouped(grouped);
    }
  }, [projectState]);

  useEffect(() => {
    const currentSectorId = getCurrentSectorId(projectState.allSectors);
    dispatch(projectActions.setSector(currentSectorId));
  }, [projectState.allSectors]);

  const getUserLight = async (userId) => {
    const projectListLight = await projectService.ProjectsByUser(userId);
    const listLength = projectListLight?.projects?.length;
    if (!listLength) {
      setLoadingProjects(false);
    } else {
      const localList = projectState?.allProjects;
      if (localList.length !== listLength) {
        /** regenerate cache with fetch data */
        updateProjectsListData();
      } else {
        const lastUpdatedList = projectState?.props?.timeStampAllProjects;
        const diffBetweenDates =
          Number(new Date() - new Date(lastUpdatedList)) / (1000 * 60);
        if (diffBetweenDates < 3) {
          /** diff in minutes > 3 minutes (get data of cache, state redux) */
          // check socket
          setLoadingProjects(false);
        } else {
          /** regenerate cache with fetch data */
          updateProjectsListData();
        }
      }
    }
  };

  useEffect(() => {
    user && getUserLight(user.id);
  }, []);

  useEffect(() => {
    const loadProjectsData = async () => {
      if (isProjectListEmpty(projectsGrouped) || typeView !== 'TIMELINE') {
        return;
      }
      if (window.loader) {
        window.loader.show();
      }
      const archived = projectsGrouped.archived || [];
      const started = projectsGrouped.started || [];
      const allProjects = [...started, ...archived];
      // This must be moved into schedule of schedule and work with react-query
      // allProjects.forEach(async (project) => {
      //   const projectAnalytic = await getAnalitycsByProject(project.id)
      //   const indicatorPpcProject = getPPC(
      //     projectAnalytic?.data,
      //     getTodayWeekWithPday
      //     );
      //     const indicatorPcrProject = getPRR(projectAnalytic?.data, t);
      //     project.ppc = indicatorPpcProject
      //     project.prr = indicatorPcrProject
      // })
      // await Promise.all(analyticsPromises)
      const projectIdsMap = {};
      allProjects.forEach((project) => {
        projectIdsMap[project.id] = project;
      });
      if (Object.keys(projectIdsMap).length === 0) return;
      setProcessedProjects(projectIdsMap);
    };
    loadProjectsData();
  }, [projectsGrouped, typeView]);

  /** Refresh projects list */
  const updateProjectsList = (arr) => {
    /** Redux event emitting function to set projects load from project actions */
    setAllProjectsGlobal(arr, dispatch, projectActions, projectState);
  };

  // redirects
  const signed = localStorage.getItem('signed');
  if (!signed) {
    return <Redirect to="/login" />;
  }

  const handleCloseModal = () => {
    dispatch(
      projectActions.setProps({
        ...projectState.props,
        openAddProject: false
      })
    );
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  // render
  return (
    <Animated
      animationIn="fadeIn"
      animationInDuration={500}
      isVisible={true}
      className="animationProjects">
      {loadingProjects && typeView !== 'TIMELINE' ? (
        <div
          className="wrappView wrapLoading"
          style={{
            height: getHeightSchedule()
          }}>
          <Spin className="loader-spinner-projects" indicator={antIcon} />
        </div>
      ) : (
        <div className="wrappView">
          <Filters
            typeView={typeView}
            setTypeView={setTypeView}
            filterText={filterText}
            setFilterText={setFilterText}
            zoomLevel={zoomLevel}
          />
          {typeView === 'TIMELINE' && (
            <ScheduleOfSchedules
              onClickProject={onClickProject}
              t={t}
              processedProjects={processedProjects}
              setFormProjectVisible={setFormProjectVisible}
              formProjectVisible={formProjectVisible}
              setFormDeleteVisible={setFormDeleteVisible}
              formDeleteVisible={formDeleteVisible}
              filterText={filterText}
              setZoomLevel={setZoomLevel}
              zoomLevel={zoomLevel}
            />
          )}
          {typeView === 'CARD' &&
            (isProjectListEmpty(projectsGrouped, filterText) ? (
              <EmptyFilteredProjects t={t} />
            ) : (
              Object.values(groupedByKey).map((projectType, index) => {
                const projectsSort = projectsGrouped[projectType.value].sort(
                  dynamicSort('name')
                );
                return (
                  <div className="sectionProjects" key={index}>
                    <Row gutter={24} className="section--project">
                      <CaretRightOutlined />
                      <span>{getLabel(t)(projectType.value, filter)}</span>
                    </Row>
                    <Row gutter={24} className="divProjects">
                      {projectsSort
                        .filter(filterByName(filterText))
                        .map((project, index) =>
                          projectState.props.currentTypeView === 'list' ? (
                            <ItemList
                              key={index}
                              {...props}
                              project={project}
                            />
                          ) : (
                            <ItemCard
                              onClickProject={onClickProject}
                              key={index}
                              {...props}
                              project={project}
                              setFormProjectVisible={setFormProjectVisible}
                              formProjectVisible={formProjectVisible}
                              setFormDeleteVisible={setFormDeleteVisible}
                              formDeleteVisible={formDeleteVisible}
                              analytics={{
                                search_text: filterText,
                                results_count: filterResultsCount
                              }}
                            />
                          )
                        )}
                    </Row>
                  </div>
                );
              })
            ))}
        </div>
      )}

      {/* Modal Form Project */}
      <FormProjects
        setFormProjectVisible={setFormProjectVisible}
        formProjectVisible={formProjectVisible}
      />
      <AddProject
        t={t}
        visible={projectState.props.openAddProject}
        setVisible={handleCloseModal}
      />

      {/* Modal Dummy Form Project */}
      <FormProjectsDummy
        setFormProjectVisibleDummy={setFormProjectVisibleDummy}
        formProjectVisibleDummy={formProjectVisibleDummy}
        isDummy={true}
      />

      {/* Modal From Delete/Archive Project */}
      <FormDelete
        setFormDeleteVisible={setFormDeleteVisible}
        formDeleteVisible={formDeleteVisible}
        sourceTypeView={typeView}
      />
    </Animated>
  );
}

export default withTranslation()(ProjectsView);
