import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PopoverSystemV2 from '../PopoverSystemV2';
import TooltipSystemV2 from '../TooltipSystemV2';
import TruncatedTextSystem from '../TruncatedTextSystem';
import {
  SearchIcon,
  WarningDiamontIcon,
  HorizontalMenuOutlineIcon,
  DeleteTagOutlineIcon,
  InfoIconOutlined
} from '../../../icons';
import Colors from '../../../assets/styles/variables.scss';

const LIGHT_THEME = 'light';
const HEIGHT_ITEM = 30;
const HEIGHT_ITEM_GROUP = 40;
const DEFAULT_COUNT_ITEMS_SHOW = 5;
const DEFAULT_WIDTH = 200;

const PopoverUniselectSystem = ({
  theme = LIGHT_THEME,
  width = DEFAULT_WIDTH,
  version,
  children,
  items = [],
  withSearch = true,
  countItemsShow = DEFAULT_COUNT_ITEMS_SHOW,
  ctaText,
  ctaIcon,
  ctaFunction,
  onFocusSearchFunction,
  onCleanSearchFunction,
  onUsedSearchFunction,
  onShowEmptyStateFunction,
  isCleanSearchTextOnClickItem = true,
  itemReference,
  ...props
}) => {
  const { t } = useTranslation();

  const { WHITE, BLACK, PRIMARY, DARK_MINT, DARK_GRAY, COOL_GRAY } = Colors;

  const [isEmptyState, setIsEmpyState] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setCurrentItems([...items]);
  }, [items]);

  const handleOnChangeSearch = (event) => {
    setSearchInput(event.target.value);

    if (event.target.value === '') {
      setCurrentItems([...items]);
      setIsEmpyState(false);
      return;
    }

    const filteredItems = filterItemsByKeyword(
      currentItems,
      event.target.value
    );

    if (filteredItems.length === 0) {
      setIsEmpyState(true);

      if (isValidFunction(onShowEmptyStateFunction)) {
        onShowEmptyStateFunction(event.target.value);
      }

      return;
    }

    setCurrentItems(filteredItems);
    setIsEmpyState(false);
  };

  const handleCleanSearch = (isExternal = false) => {
    if (isValidFunction(onCleanSearchFunction) && !isExternal) {
      onCleanSearchFunction(searchInput);
    }

    setSearchInput('');
    setCurrentItems([...items]);
    setIsEmpyState(false);
  };

  function filterItemsByKeyword(items, keyword) {
    const lowerKeyword = keyword.toLowerCase();

    function filterGroup(group) {
      return group.filter((subitem) =>
        subitem.name.toLowerCase().includes(lowerKeyword)
      );
    }

    return items
      .map((item) => {
        if (item.group.length === 0) {
          if (item.name.toLowerCase().includes(lowerKeyword)) {
            return item;
          }
        } else {
          const filteredGroup = filterGroup(item.group);
          if (filteredGroup.length > 0) {
            return {
              ...item,
              group: filteredGroup
            };
          }
        }
        return null;
      })
      .filter((item) => item !== null);
  }

  const calculateHeightItems = () => {
    const currentItemsCalculate = [...items];

    let groupItemsCount = 0;
    let itemsCount = 0;
    let itemsTotal = 0;
    let itemsTotalAll = 0;

    currentItemsCalculate.map((currentItem) => {
      if (currentItem.group.length > 0) {
        itemsTotalAll++;

        if (itemsTotal < countItemsShow) {
          groupItemsCount++;
          itemsTotal++;
        }

        currentItem.group.map(() => {
          itemsTotalAll++;

          if (itemsTotal < countItemsShow) {
            itemsCount++;
            itemsTotal++;
          }
        });

        return;
      }

      itemsTotalAll++;

      if (itemsTotal < countItemsShow) {
        itemsCount++;
        itemsTotal++;
      }
    });

    return {
      totalCount:
        groupItemsCount * HEIGHT_ITEM_GROUP + itemsCount * HEIGHT_ITEM,
      itemsTotalAll: itemsTotalAll
    };
  };

  const calculateWidthItems = (
    withIcon = false,
    withAdditionalIcon = false,
    widthSubitems = false,
    withGroup = false
  ) => {
    let base = 40;

    if (withIcon) base += 26;
    if (withAdditionalIcon) base += 26;
    if (widthSubitems) base += 26;
    if (withGroup) base += 10;

    return width - base;
  };

  const contentItem = (item, group) => {
    return (
      <div
        key={item.key}
        onClick={() => {
          isItemClickable(item.onClick, item.disabled, item.active);
          isOnUsedSearchFunction(item.key);
        }}
        className={`list__item ${item.active ? 'active' : ''} ${item.disabled ? 'disabled' : ''}`}
        style={{ paddingLeft: group ? 30 : 20 }}>
        <div className="item__text">
          <div className="text">
            {item.icon && <div>{item.icon}</div>}
            <TruncatedTextSystem
              name={item.name}
              color={theme === 'light' ? BLACK : item.active ? BLACK : WHITE}
              placement={'topLeft'}
              maxWidth={calculateWidthItems(
                item.icon,
                item.additionalIcon,
                item.subitems?.length,
                group
              )}>
              <span>{item.name}</span>
            </TruncatedTextSystem>
          </div>
          <div className="text__options">
            {item.additionalIcon && (
              <div className="options__additional-icon">
                {item.additionalIcon}
              </div>
            )}
            {item.subitems.length > 0 && (
              <PopoverSystemV2
                theme={theme}
                content={
                  <div
                    className={`popover-uniselect-design-system__subitems theme--${theme}`}>
                    {item.subitems.map((subitem) => contentSubItem(subitem))}
                  </div>
                }
                trigger="click"
                {...props}>
                <div onClick={(e) => e.stopPropagation()}>
                  <HorizontalMenuOutlineIcon
                    color={
                      theme === LIGHT_THEME
                        ? BLACK
                        : item.active
                          ? BLACK
                          : WHITE
                    }
                  />
                </div>
              </PopoverSystemV2>
            )}
          </div>
        </div>
      </div>
    );
  };

  const contentSubItem = (subitem) => {
    return (
      <div
        key={subitem.key}
        className={`subitems__content ${subitem.active ? 'active' : ''} ${subitem.disabled ? 'disabled' : ''} ${subitem.divider ? 'divider' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          isSubitemClickable(
            subitem.onClick,
            subitem.disabled,
            subitem.active,
            subitem.divider
          );
        }}>
        {subitem.divider ? (
          <div className="content__divider" />
        ) : (
          <>
            <div className="content__info">
              {subitem.icon && <div className="info__icon">{subitem.icon}</div>}
              <span>{subitem.name}</span>
            </div>
            {subitem.informationIcon &&
              (subitem.tooltipIformation ? (
                TooltipSystemV2({
                  title: subitem.tooltipIformation,
                  placement: 'top',
                  children: (
                    <div className="content__icon">
                      <InfoIconOutlined
                        color={theme === LIGHT_THEME ? DARK_MINT : PRIMARY}
                      />
                    </div>
                  )
                })
              ) : (
                <div className="content__icon">
                  <InfoIconOutlined
                    color={theme === LIGHT_THEME ? DARK_MINT : PRIMARY}
                  />
                </div>
              ))}
          </>
        )}
      </div>
    );
  };

  const contentGlobalItem = (item) => {
    if (item.group.length > 0) {
      return (
        <>
          <div key={item.key} className="list__item group">
            <div className="item__text">
              <div className="text">
                {item.icon && <div>{item.icon}</div>}
                <span>{item.name}</span>
              </div>
            </div>
          </div>
          {item.group.map((newItem) => contentItem(newItem, true))}
        </>
      );
    }

    return contentItem(item, false);
  };

  const countItemsFunction = (items) =>
    items.reduce(
      (items, { group }) =>
        group.length === 0 ? items + 1 : items + group.length,
      0
    );

  const isItemClickable = (onClick, disabled, active) => {
    if (!isValidFunction(onClick) || disabled || active) return;

    if (isCleanSearchTextOnClickItem) {
      handleCleanSearch(true);
    }

    onClick();
  };

  const isSubitemClickable = (onClick, disabled, active, divider) => {
    if (!isValidFunction(onClick) || disabled || active || divider) return;

    onClick();
  };

  const isOnUsedSearchFunction = (itemKey) => {
    if (!isValidFunction(onUsedSearchFunction)) return;

    onUsedSearchFunction(
      searchInput,
      countItemsFunction(currentItems),
      itemKey
    );
  };

  const isValidFunction = (functionValidation) =>
    typeof functionValidation === 'function';

  const handleOnVisibleChange = (visible) => {
    if (!visible) {
      handleCleanSearch(true);
    }
  };

  const contentPopover = () => (
    <div
      className={`popover-uniselect-design-system theme--${theme}`}
      style={{ width: width }}>
      {withSearch && (
        <div className="popover-uniselect-design-system__search">
          <input
            placeholder="Search"
            name="search"
            onChange={handleOnChangeSearch}
            value={searchInput}
            autoComplete="off"
            onFocus={() =>
              isValidFunction(onFocusSearchFunction)
                ? onFocusSearchFunction()
                : null
            }
          />
          <div
            className="search__clean"
            onClick={() =>
              searchInput.length > 0 ? handleCleanSearch() : null
            }>
            {searchInput.length > 0 && (
              <DeleteTagOutlineIcon
                color={theme === LIGHT_THEME ? DARK_GRAY : COOL_GRAY}
              />
            )}
          </div>
          <div className="search__icon">
            <SearchIcon color={theme === LIGHT_THEME ? DARK_MINT : PRIMARY} />
          </div>
        </div>
      )}
      <div
        className="popover-uniselect-design-system__items"
        style={{
          maxHeight: !isEmptyState
            ? calculateHeightItems().totalCount
            : 'inherit',
          overflowY:
            calculateHeightItems().itemsTotalAll > countItemsShow &&
            !isEmptyState
              ? 'scroll'
              : 'initial'
        }}>
        {isEmptyState ? (
          <div className="items__empty">
            <div className="empty__icon">
              <WarningDiamontIcon
                color={theme === LIGHT_THEME ? DARK_GRAY : COOL_GRAY}
              />
            </div>
            <span>{t('popoverUniselectSystem.empty_state_message')}</span>
          </div>
        ) : (
          <div className="items__list">
            {currentItems.map((item) => contentGlobalItem(item))}
          </div>
        )}
      </div>
      {ctaText && ctaFunction && (
        <div
          className="popover-uniselect-design-system__cta"
          onClick={() => ctaFunction()}>
          {ctaIcon && <div className="cta__icon">{ctaIcon}</div>}
          <span>{ctaText}</span>
        </div>
      )}
    </div>
  );

  return (
    <PopoverSystemV2
      itemReference={itemReference}
      theme={theme}
      content={contentPopover()}
      onVisibleChange={(value) => handleOnVisibleChange(value)}
      {...props}>
      {children}
    </PopoverSystemV2>
  );
};

export default PopoverUniselectSystem;
