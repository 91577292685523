/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class SubcontractService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(subContractId) {
    const res = await this.http
      .get('subcontracts/' + subContractId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(subContractId) {
    const res = await this.http
      .delete('subcontracts/' + subContractId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('subcontracts', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async assignToProject(data) {
    const res = await this.http
      .post('subcontracts/assign-project', data)
      .catch((error) => console.error(error.response));
    return res?.data || res;
  }

  async update(data) {
    const res = await this.http
      .put('subcontracts/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showByProject(projectId) {
    const res = await this.http
      .get('subcontracts/project/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getByCompany(userId) {
    const res = await this.http
      .get('subcontracts/company/' + userId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async getByProject(projectId) {
    const res = await this.http
      .get('subcontracts/byproject/' + projectId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const subContractService = new SubcontractService();
