import TimeAgo from 'javascript-time-ago';

import { getCurrentLanguage } from '../i18n';

/**
 * Checks if a given instance is a valid date object.
 *
 * Note: The reason why this function checks the type description explicitly, instead of using instanceof,
 * is because instanceof doesn't work across different windows or frames.
 *
 * @param {Date} date - The date object to be tested
 * @return {boolean} - A boolean value indicating whether the date object is valid or not
 */
export const isValidDateObject = (date) =>
  Object.prototype.toString.call(date) === '[object Date]' &&
  !isNaN(date.getTime());

/**
 * Returns a localized string with the elapsed time since the date supplied as argument.
 *
 * @param {Date} date - The date object to be used as reference
 * @return {string} - A string with the localized elapsed time
 */
export const getElapsedTimeLabel = (date) => {
  const timeAgo = new TimeAgo(getCurrentLanguage());
  return timeAgo.format(date, 'round');
};
