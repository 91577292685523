import * as icons from '../../icons';
import LookaheadVisualizationOptions from '../LookaheadVisualizationOptions';
import LookAheadPlanExport from '../exportable/LookAheadPlan';
import colors from '../../stylesheets/variables.scss';
import color from '../../assets/styles/variables.scss';
import { isFeatureOn } from '../../utils/featureUtils';

export const ZOOM_LEVELS = ['DAYS', 'WEEKS', 'MONTH', 'QUARTERS', 'YEARS'];

export const TOOLBAR_LEFT_GROUP_ITEMS = [
  {
    props: {},
    comp: LookaheadVisualizationOptions
  },
  [
    {
      icon: icons.LookaheadRangeIcon,
      command: 'LOOKAHEAD',
      tooltipI18nKey: 'filters_label.lookahead_label'
    },
    {
      icon: icons.FilterIcon,
      command: 'FILTER',
      tooltipI18nKey: 'filters_label.filters_label'
    },
    {
      icon: icons.OrderIcon,
      command: 'ORDER',
      tooltipI18nKey: 'filters_label.order_label'
    },
    // {
    //   icon: icons.GroupIcon,
    //   command: 'GROUP',
    //   tooltipI18nKey: 'filters_label.group_label'
    // },
    {
      icon: icons.ColumnsIcon,
      command: 'COLUMNS',
      tooltipI18nKey: 'filters_label.columns_label'
    }
  ]
];

export const COLOR_SCHEME_TYPE_SUBMENU_ITEMS = [
  {
    icon: icons.BulletIcon,
    iconColor: colors.brandBlue40,
    i18nKey: 'status_only_label_constraint',
    command: 'CHANGE_COLOR_FOR_STATUS',
    isCheckable: true
  },
  {
    icon: icons.LeanstatusIcon,
    iconColor: colors.brandBlue40,
    i18nKey: 'lean_status_only',
    command: 'CHANGE_COLOR_FOR_LEANSTATUS',
    isCheckable: true
  },
  {
    icon: icons.WorkerIcon,
    iconColor: colors.brandBlue40,
    i18nKey: 'settings.subcontracts',
    command: 'CHANGE_COLOR_FOR_SUBCONTRACT',
    isCheckable: true
  },
  {
    icon: icons.TagIcon,
    iconColor: colors.brandBlue40,
    i18nKey: 'config_constraints.tags',
    command: 'CHANGE_COLOR_FOR_TAGS',
    isCheckable: true
  }
];

export const TOOLBAR_TIMELINE_OPTIONS = [
  {
    icon: icons.PinIcon,
    command: 'SCROLL_TO_TODAY',
    tooltipI18nKey: 'scroll_to_today',
    ignoreColumn: true
  },
  {
    command: 'SET_ZOOM_LEVEL',
    defaultValue: 'DAYS',
    tooltipI18nKey: 'change_zoom_level',
    ignoreColumn: true,
    options: ZOOM_LEVELS.map((level) => ({
      i18nKey: `zoom_levels.${level.toLowerCase()}`,
      value: level
    }))
  },
  {
    icon: icons.PaletteIcon,
    tooltipI18nKey: 'bar_colors',
    subitems: COLOR_SCHEME_TYPE_SUBMENU_ITEMS,
    ignoreColumn: true,
    visible: isFeatureOn('enable_color-schema')
  },
  {
    id: 'weekly-lookahead-preview',
    command: 'SHOW_PREVIEW',
    icon: icons.PreviewIcon,
    invertTextColor: true,
    activeBtn: false,
    i18nKey: 'new_weekly_lookahead.preview'
  },
  {
    id: 'weekly-lookahead-integration-btn',
    icon: icons.HandCommitIcon,
    i18nKey: 'new_weekly_lookahead.commit_btn',
    command: 'COMMIT_WEEKLY_PLAN',
    iconColor: color.primary,
    mainColor: true,
    invertTextColor: true
  }
];

export const TOOLBAR_RIGTH_GROUP_ITEMS = [
  {
    id: 'download-options',
    icon: icons.NewIcon,
    direction: 'RIGHT',
    tooltipI18nKey: 'filters_label.download_label',
    subitems: [
      {
        props: {},
        Comp: LookAheadPlanExport,
        icon: icons.ExcelIcon,
        iconColor: colors.brandBlue40
      },
      {
        icon: icons.PdfIcon,
        iconColor: colors.brandBlue40,
        i18nKey: 'week_interval_modal_view',
        command: 'DOWNLOAD_SCHEDULE'
      }
    ]
  }
];
