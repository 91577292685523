import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentCompany,
  getCurrentProject,
  getCurrentSector,
  getSignedUser
} from '../utils/userUtils';
import { useEffect, useState } from 'react';
import { setAllProjectsGlobal } from '../utils';
import * as projectActions from '../redux/slices/projectSlice';
import { companyActions } from '../redux/actions/companyActions';
import { filterActiveProjects } from '../utils/projectUtils';

const avoidPaths = [
  '/login',
  '/report',
  '/forgot',
  '/signup',
  '/register',
  '/invite',
  '/projects',
  '/selectCompany',
  '/',
  '/sso/login'
];

const avoidIncludePaths = [
  '/confirmation',
  '/signupconfirmation',
  '/admin',
  '/changepassword',
  'reportcommit',
  '/export/weekly'
];

const settingsPath = '/settings';
const projectsPath = '/projects';

const isAvoidPath = () =>
  avoidPaths.find((url) => window.location.pathname === url) ||
  avoidIncludePaths.find((url) => window.location.pathname.includes(url));

const redirectToProjects = () => {
  window.location.href = projectsPath;
};

export const useCheckActualSector = (userService) => {
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.projectState);
  const [loading, setLoading] = useState(!isAvoidPath());

  useEffect(() => {
    const user = getSignedUser();
    const sector = getCurrentSector();
    const project = getCurrentProject();

    if (isAvoidPath() || !user) {
      sessionStorage.removeItem('currentSector');
      sessionStorage.removeItem('currentProject');
      setLoading(false);
      return;
    }

    if (window.location.pathname.includes(settingsPath)) {
      userService
        .projectsbyuserthrough(user.id)
        .then(({ projects }) => {
          const active = filterActiveProjects(projects);
          setAllProjectsGlobal(active, dispatch, projectActions, projectState);
          dispatch(companyActions.setCurrentCompany(getCurrentCompany()));
          setLoading(false);
        })
        .catch(redirectToProjects);
      return;
    }

    if (!sector || !project || projectState.allProjects?.length) {
      setLoading(false);
      return;
    }

    userService
      .projectsbyuserthrough(user.id)
      .then(({ projects }) => {
        const active = filterActiveProjects(projects);
        setAllProjectsGlobal(active, dispatch, projectActions, projectState);
        dispatch(projectActions.setProject(project.id));
        dispatch(companyActions.setCurrentCompany(getCurrentCompany()));
        setLoading(false);
      })
      .catch(redirectToProjects);
  }, []);

  return { loading };
};
