/* eslint-disable prefer-const */

/** React components  */
import React, { useEffect, useState } from 'react';

/** React-query */
import { useQuery, useQueryClient, useMutation } from 'react-query';

/** Import elements from library Antd */
import { Upload, Popover, Row, Col, Button, Modal, Input, Tooltip } from 'antd';
import {
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  LoadingOutlined
} from '@ant-design/icons';

/** import base contant (setting urls)  */
import { base } from '../../../../services/base';

/** Function to clone objects on JS */
import cloneDeep from 'lodash/cloneDeep';

/** PNG for compromise weekly plan */
import handshakeImg from '../../../../assets/img/CP-Orange-50x50.png';
import progress from '../../../../assets/img/progressbar.svg';

/** import custom checkbox */
import CustomCheckbox from './CustomCheckbox';

/** import library for antd upload crop */
import ImgCrop from 'antd-img-crop';

/** Moment for dates using */
import moment from 'moment';

/** Import lodash functions */
import { startCase } from 'lodash';

/** import common functions from utils */
import {
  getTodayWeekWithPday,
  dynamicSort,
  openNotification,
  getBase64,
  headersSendForm,
  categoriesCncType,
  renderIconCat
} from '../../../../utils';

/** Redux implementation */
import { useSelector } from 'react-redux';
import { firstBy } from 'thenby';
import { trakerServiceAppCues } from '../../../../utils/appcues-util';
import {
  getCncTypes,
  createCnc
} from '../../../../views/weeklyPlan/weeklyPlan.helper';
import useSubtradeUser from '../../../../hooks/useSubtradeUser';
import {
  totangoEventTracking,
  totangoSetAccountAttributes
} from '../../../../analytics/implements/totango';
import { getSignedUser } from '../../../../utils/userUtils';

const CncForm = (props) => {
  const subtradeRole = useSubtradeUser();
  const queryClient = useQueryClient();
  const projectState = useSelector((state) => state.projectState);
  const projectSelected = projectState.allProjects.find(
    (el) => el.id == projectState.projectSelected
  );
  const stateCompany = useSelector((state) => state.companyState);
  /** use props */
  const {
    visibleForm,
    setVisibleForm,
    setCncAdded,
    getCncList,
    t,
    weekDefault,
    tasksSelected,
    closeForm
  } = props;
  /** Querys Mutators */
  /**
   * This function delete object from table & update cache
   * @param {*} data Object {} Object data to pass on to mutation side effects
   */
  const mutationCreate = useMutation((newData) => createCnc(newData), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        'cncListRq',
        projectState.sectorSelected
      ]);
      const newCncs = await queryClient.fetchQuery(
        ['cncListRq', projectState.sectorSelected],
        () => {}
      );
      const cncs = newCncs.cncs.map((el) => {
        const findUser = props.responsables.find(
          (user) => user.id === el.userId
        );
        return {
          ...el,
          users: [findUser]
        };
      });
      setCncAdded([
        {
          name: t('weekly_plan_cnc.title'),
          tasks: cncs
        }
      ]);
      props.setDataNoGroup([
        {
          name: t('weekly_plan_cnc.title'),
          tasks: cncs
        }
      ]);
      setLoading(false);
      openNotification(alertCncAddSuccess);
      resetCncForm();
    }
  });

  /** consts */
  const alertCncAddSuccess = {
    /** notification exit */ title: t && t('lang') === 'es' ? 'CNCs' : 'RFVs',
    description:
      t && t('lang') === 'es'
        ? 'CNC agregado satisfactoriamente'
        : 'RFV added successfully',
    type: 'success'
  };

  /** object by default for Cnc Form */
  const templateForm = {
    type: null,
    name: null,
    taskList: []
  };

  /** Hooks */
  const [loading, setLoading] = useState(false); /** handle load */
  const [typesCnc, setTypesCnc] = useState([]); /** handle validation name */
  const [weekCommitmentList, setWeekCommitmentList] = useState(
    []
  ); /** handle validation name */
  const [cncForm, setCncForm] =
    useState(templateForm); /** handle validation name */
  const [weekPop, setWeekPop] = useState({ visible: false });
  const [weekSelected, setWeekSelected] = useState({});
  const [stateUpload, setStateUpload] = useState({ loading: false });
  const [tasksAddedCnc, setTasksAddedCnc] = useState([]);
  const [resetFlag, setResetFlag] = useState(false);
  const [infoWeek, setInfoWeek] = useState({
    total: 0,
    realized: 0,
    pending: 0,
    deleted: 0
  });

  useEffect(() => {
    if (visibleForm) {
      loadInfoCnc();
    }
    if (props.cncListRq.isSuccess && props.cncListRq.data.cncs) {
      getListsTasks();
    }
  }, [visibleForm, resetFlag]);

  useEffect(() => {
    if (props.allTaskCommitments.length) {
      if (props.tasksSelected) {
        tasksSelected.map((task) => {
          const currentTask = props.allTaskCommitments.find(
            (el) => el.id === task.id
          );
          const newList = [...cncForm.taskList, currentTask];
          setCncForm({
            ...cncForm,
            taskList: newList
          });
        });
      }
    }
  }, [props.allTaskCommitments, visibleForm]);

  const getListsTasks = () => {
    /** get cncs */
    const listTmp = getCncList(props.cncListRq);
    if (listTmp.cncTasksArr) {
      setTasksAddedCnc(listTmp.cncTasksArr);
    }
  };

  useEffect(() => {
    /** get week number */
    const existSelectedWeek = Object.keys(weekSelected).length !== 0;
    const todayWeek = getTodayWeekWithPday(projectState);

    if (existSelectedWeek) {
      selectWeek(weekSelected);
    } else if (weekCommitmentList) {
      const newWeekSelected = weekDefault || parseInt(todayWeek) - 1;
      const find = weekCommitmentList.find((el) => el.week === newWeekSelected);
      if (find && find.taskcommitments.length) {
        const pendingSelect = getPending(find);
        if (pendingSelect.length) {
          selectWeek(find);
        }
      }
    }
  }, [weekCommitmentList]);

  // Queries
  const cncTypesRq = useQuery(['cncTypesRq', projectSelected], () =>
    getCncTypes(projectSelected)
  );

  const loadInfoCnc = async () => {
    const weekCommitments = cloneDeep(props.allWeekCommitments);
    const weekCommitmentsClosed = weekCommitments.filter((el) => el.closed);
    setWeekCommitmentList(weekCommitmentsClosed);
  };

  useEffect(() => {
    if (cncTypesRq.data) {
      setTypesCnc(cncTypesRq.data.cnctypes);
    }
  }, [cncTypesRq.data]);

  /** Component Logic */
  const resetCncForm = () => {
    /** close modal */
    setResetFlag(!resetFlag);
    setCncForm(templateForm);
    setStateUpload({ loading: false });
    setWeekSelected({});
    if (closeForm) {
      setVisibleForm(false);
    }
  };

  const handleClose = () => {
    resetCncForm();
    setVisibleForm(false);
  };

  /** handel popover of weeks */
  const handleWeekPop = (visible) => {
    setWeekPop({ visible });
  };

  const setInfoFn = (weekData) => {
    const weekFind = props.allWeekCommitments.find(
      (el) => el.id === weekData.id
    );
    const total = weekFind.taskcommitments.filter((el) => el.is_last_level);
    let realized = 0;
    let pending = 0;
    let deleted = 0;
    total.map((el) => {
      if (el.realized_percentaje >= el.commitment_percentaje) {
        realized++;
      } else {
        pending++;
        if (!el.taskId) {
          deleted++;
        }
      }
    });
    setInfoWeek({
      total: total.length,
      realized: realized,
      pending: pending - deleted,
      deleted: deleted
    });
  };

  const selectWeek = async (week) => {
    const originWeek = cloneDeep(week);
    setInfoFn(originWeek);

    /** get activities/tasks */
    const pending = getPending(week);
    week.pendings = [];

    /** filter only last level tasks */
    if (pending && pending.length) {
      week.taskcommitments = pending;
      week.pendings = pending;
    }
    setWeekPop({ visible: false });
    setWeekSelected(week);
  };

  const getPending = (week) => {
    /** task last level only */
    let taskLastLevel = week.taskcommitments;
    taskLastLevel = week.taskcommitments.filter((task) => {
      /** tasId is null. task was deleted */
      const currentTask = props.allTaskCommitments.find(
        (el) => el.id === task.taskId
      );
      if (!currentTask) {
        return false;
      }
      return task.is_last_level;
    });
    /** review percentage contidion */
    const taskPending = taskLastLevel.filter((task) => {
      const conditionPercentage =
        parseFloat(task.commitment_percentaje) >
        parseFloat(task.realized_percentaje);
      return conditionPercentage && task.taskId;
    });
    /** review if task is not added to other cnc */
    const taskPendingAdded = taskPending.filter((task) => {
      const conditionTasksAdded = tasksAddedCnc.filter(
        (el) => parseInt(el.week) === parseInt(week.week)
      );
      const added = conditionTasksAdded.some((el) => el.taskId === task.taskId);
      return !added;
    });
    return taskPendingAdded;
  };

  const RenderWeeklyPlan = () => {
    const filterWeekCommitmentListSort = processList(weekCommitmentList);
    const havePendings =
      filterWeekCommitmentListSort &&
      filterWeekCommitmentListSort.some((week) => {
        const pending = getPending(week);
        return pending.length > 0;
      });
    return havePendings ? (
      filterWeekCommitmentListSort &&
        filterWeekCommitmentListSort.map((week, index) => {
          /** calculating pending tasks */
          const pending = getPending(week);
          return pending.length > 0 ? (
            <div
              key={index}
              className={
                weekSelected.week === week.week
                  ? 'week-info week-info-select'
                  : 'week-info'
              }
              onClick={() => selectWeek(week)}>
              <span className="week-info-number">
                {t('week_sho')} {week.week}
              </span>
              <span className="week-info-range">
                {startCase(
                  moment(week.start_date).format('DD MMM').replace(/\./g, '')
                )}
                &nbsp;-&nbsp;
                {startCase(
                  moment(week.end_date).format('DD MMM').replace(/\./g, '')
                )}
              </span>
              <span className="week-info-pending">
                {pending.length} {t('pending_label')}
              </span>
            </div>
          ) : null;
        })
    ) : (
      <div className="no-pendings">{t('weekly_plan_cnc.no_pendings')}</div>
    );
  };

  const handleClickSelectTask = (currentTask) => {
    let newList = [];
    const exists = cncForm.taskList.find((t) => t.id === currentTask.id);
    if (exists) {
      /** delete task */
      newList = cncForm.taskList.filter((t) => t.id !== currentTask.id);
    } else {
      /** add task */
      newList = [...cncForm.taskList, currentTask];
    }
    setCncForm({
      ...cncForm,
      taskList: newList
    });
  };

  /** handle submit cnc */
  const handleSubmit = async (event) => {
    event.preventDefault();
    trakerServiceAppCues('RFV Creation');
    /** validate data */
    let newName = cncForm.name;
    let newType = cncForm.type;
    let validForm = true;
    if (!newName) {
      newName = '';
      validForm = false;
    }
    if (!newType) {
      newType = '';
      validForm = false;
    }

    if (!validForm) {
      setCncForm(() => ({
        ...cncForm,
        name: newName,
        type: newType
      }));
      return false;
    }
    setLoading(true);
    const loggedUser = getSignedUser();
    const currentCompany = stateCompany.currentCompany;
    const project = projectState.allProjects.find(
      (p) => p.id == projectState.projectSelected
    );

    totangoSetAccountAttributes(
      loggedUser,
      projectState.projectSelected,
      currentCompany?.name,
      currentCompany?.id,
      project?.name,
      project?.stage,
      project?.country
    );

    totangoEventTracking(
      `p_${projectState.projectSelected}`,
      loggedUser,
      'RFV Creation',
      'Weekly Plan'
    );

    /** build object data */
    const user = getSignedUser();
    const dataCnc = {
      name: cncForm.name,
      priority: 'normal',
      week: weekSelected.week,
      year: weekSelected.year,
      status: 'unsolved',
      create_date: moment(),
      cncTypeId: cncForm.type,
      userId: user.id,
      sectorId: projectState.sectorSelected,
      image: stateUpload.name ? stateUpload.name : null
    };

    /** build tasks for cnc */
    const arrTasks = [];
    cncForm.taskList.map((cnctask) => {
      const taskCommit = weekSelected.taskcommitments.find(
        (t) => t.taskId === cnctask.id
      );
      if (taskCommit) {
        const dataTask = {
          taskId: cnctask.id,
          commitment_percentaje: taskCommit.commitment_percentaje,
          realized_percentaje: taskCommit.realized_percentaje,
          taskcommitmentid: taskCommit.id,
          weekcommitmentid: weekSelected.id
        };
        arrTasks.push(dataTask);
      }
    });

    /** data to create cnc */
    const data = {
      cnc: dataCnc,
      tasks: arrTasks
    };
    mutationCreate.mutate(data);
  };

  /** this function is triggered when upload image */
  const onChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setStateUpload({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response
      getBase64(info.file.originFileObj, (imageUrl) =>
        setStateUpload({
          imageUrl,
          name: info.file.response.name,
          loading: false
        })
      );
    }
  };

  /** Component Button */
  const uploadButton = (
    <div>
      {stateUpload.loading ? (
        <LoadingOutlined />
      ) : (
        <i className="far fa-image fa-5x"></i>
      )}
    </div>
  );

  const processList = (weekList, order = 'asc') => {
    const filterWeekCommitmentList = weekList
      .filter((el) => getPending(el).length)
      .map((el) => {
        const check = moment(el.start_date, 'YYYY/MM/DD');
        const yyyy = check.format('YYYY');
        return {
          ...el,
          dateall: String(yyyy) + el.week.toString().padStart(2, '0')
        };
      });
    let strOrder = 'dateall';
    if (order !== 'asc') {
      strOrder = '-dateall';
    }
    const filterWeekCommitmentListSort = filterWeekCommitmentList.sort(
      dynamicSort(strOrder)
    );
    return filterWeekCommitmentListSort;
  };

  /** navigation */
  const handleIteration = (type = 'next') => {
    const filterWeekCommitmentListSort = processList(weekCommitmentList);

    /** limits */
    const min = 0;
    const max = filterWeekCommitmentListSort.length - 1;
    const findIndex = filterWeekCommitmentListSort.findIndex(
      (el) => el.week === weekSelected.week
    );

    let newIndex = findIndex - 1 < min ? min : findIndex - 1;
    if (type === 'next') {
      newIndex = findIndex + 1 > max ? max : findIndex + 1;
    }
    if (findIndex !== -1 && newIndex !== findIndex) {
      selectWeek(filterWeekCommitmentListSort[newIndex]);
    }
  };

  /** render */
  return (
    <Modal
      closable={false}
      className="frm-cnc"
      title=""
      visible={visibleForm}
      centered
      bodyStyle={{ height: '60vh', overflow: 'auto' }}
      keyboard={false}
      maskClosable={false}
      onCancel={handleClose}
      width={1000}
      footer={<div></div>}>
      <div className="title-frm-add">
        {t('adding_cnc_label')}
        <div className="title-close" onClick={handleClose}>
          <CloseOutlined />
        </div>
      </div>
      <Row className="frm">
        <Col span={12} className="frmCol">
          <div className="week-navigate">
            <Row className="">
              <div className="div-week">
                <Col span={8} className="week-left">
                  <LeftOutlined onClick={() => handleIteration('prev')} />
                </Col>
                <Col span={8}>
                  <Popover
                    placement="bottom"
                    content={<RenderWeeklyPlan />}
                    trigger="click"
                    visible={weekPop.visible}
                    onVisibleChange={handleWeekPop}
                    arrowPointAtCenter
                    autoAdjustOverflow
                    overlayClassName="week-navigation">
                    {weekSelected.week ? (
                      <span>
                        <div className="week-number">
                          {t('weekly_week')} {weekSelected.week}
                        </div>
                        <div className="range-date">
                          {startCase(
                            moment(weekSelected.start_date)
                              .format('DD MMM')
                              .replace(/\./g, '')
                          )}
                          &nbsp;-&nbsp;
                          {startCase(
                            moment(weekSelected.end_date)
                              .format('DD MMM')
                              .replace(/\./g, '')
                          )}
                        </div>
                      </span>
                    ) : (
                      <span>
                        <div className="week-number">{t('weekly_select')}</div>
                        <div className="range-date">{t('weekly_init_end')}</div>
                      </span>
                    )}
                  </Popover>
                </Col>
                <Col span={8} className="week-right">
                  <RightOutlined onClick={() => handleIteration('next')} />
                </Col>
              </div>
            </Row>
          </div>
          {weekSelected.week ? (
            <>
              <div className="filter-info">
                <div className="filter">
                  <i className="fas fa-filter filter-icon"></i>
                  {t('filter_by_label')}
                  <div className="hide">{JSON.stringify(infoWeek)}</div>
                </div>
                <div className="info">
                  {weekSelected.pendings.length} {t('pending_label')}
                </div>
              </div>

              <div className="list-task">
                {weekSelected?.pendings
                  .sort(
                    firstBy('taskId', { direction: 'asc', ignoreCase: true })
                  )
                  .map((taskComm, ind) => {
                    /** User capabilities validation */
                    if (
                      subtradeRole.isSubtradeRole &&
                      (!subtradeRole.subtrade ||
                        (subtradeRole.subtrade &&
                          taskComm.subcontractId !== subtradeRole.subtrade.id))
                    ) {
                      return;
                    }
                    if (taskComm.taskId) {
                      const currentTask = props.allTaskCommitments.find(
                        (el) => el.id === taskComm.taskId
                      );
                      // let isSelected = null
                      let isSelected = currentTask
                        ? cncForm.taskList.some(
                            (task) => task.id === currentTask.id
                          )
                        : null;
                      // if (isSelected === null) {
                      //  isSelected = isSelected || tasksSelected.includes(currentTask.id)
                      // }

                      // if (tasksSelected.includes(currentTask.id)) {
                      //     if (isSelected) {

                      //     }
                      // }

                      let routeString = 'No Route';
                      if (currentTask) {
                        const findActivity = currentTask?.activity;
                        routeString = findActivity
                          ? findActivity.activityRoute +
                            ' > ' +
                            findActivity.name
                          : 'No Route';
                      }
                      return currentTask ? (
                        <div className="item" key={ind}>
                          <CustomCheckbox
                            onClick={(checked) => {
                              let newList = [];
                              if (checked) {
                                /** add task */
                                newList = [...cncForm.taskList, currentTask];
                              } else {
                                /** delete task */
                                newList = cncForm.taskList.filter(
                                  (t) => t.id !== currentTask.id
                                );
                              }
                              setCncForm({
                                ...cncForm,
                                taskList: newList
                              });
                            }}
                            active={isSelected || null}
                          />
                          <div
                            className={`card-task ${isSelected ? 'card-selected' : null}`}
                            onClick={() => handleClickSelectTask(currentTask)}>
                            <Row>
                              <Col span={16} className="info-task">
                                <div>{currentTask.name}</div>
                                <div className="route-task">
                                  <Tooltip title={routeString}>
                                    {routeString}
                                  </Tooltip>
                                </div>
                              </Col>
                              <Col span={8}>
                                <div className="percentaje-tasks">
                                  <div>
                                    <img
                                      className="img-handshake"
                                      src={handshakeImg}
                                    />
                                    {parseFloat(
                                      taskComm.commitment_percentaje
                                    ).toFixed(2)}
                                    %
                                  </div>
                                  <div>
                                    <img
                                      className="img-progress"
                                      src={progress}
                                    />
                                    {parseFloat(
                                      taskComm.realized_percentaje
                                    ).toFixed(2)}
                                    %
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : null;
                    }
                  })}
              </div>
            </>
          ) : (
            <div className="info-cnc-form">
              <div className="no-tasks">{t('weekly_select')}</div>
            </div>
          )}
        </Col>
        {cncForm.taskList.length ? (
          <Col span={12} className="frmCol">
            <div className="info-cnc-form">
              <div className="title-cnc">{t('cnc_information_label')}</div>
              <div className="info-div">
                <Row>
                  <Col span={6} className="image-cnc">
                    <ImgCrop rotate shape="rect" modalWidth={850}>
                      <Upload
                        action={`${base.api}cncs/upload`}
                        listType="picture-card"
                        showUploadList={false}
                        headers={headersSendForm}
                        onChange={onChangeUpload}>
                        {stateUpload.imageUrl ? (
                          <div className="img-cnc">
                            <img src={stateUpload.imageUrl} width="65" />
                          </div>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </ImgCrop>
                  </Col>
                  <Col span={18} className="name-cnc">
                    <span
                      className={
                        cncForm.name !== null && cncForm.name === ''
                          ? ' error'
                          : null
                      }>
                      {t('field_name_cnc')}
                    </span>
                    <Input
                      placeholder={t('field_name_cnc')}
                      className="inp-cnc-name"
                      onChange={(e) =>
                        setCncForm({
                          ...cncForm,
                          name: e.target.value
                        })
                      }
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="type-cnc">
              <div
                className={
                  cncForm.type !== null && cncForm.type === ''
                    ? 'title-type-cnc error'
                    : 'title-type-cnc'
                }>
                {t('field_select_cnc_type')}
              </div>
              <div className="list-type">
                {typesCnc &&
                  typesCnc.map((type, ind) => {
                    const findItem = categoriesCncType.find(
                      (el) => el.value === type.type
                    );
                    return (
                      <div
                        key={ind}
                        className={
                          cncForm.type === type.id
                            ? 'card-type card-selected'
                            : 'card-type'
                        }
                        onClick={() =>
                          setCncForm({
                            ...cncForm,
                            type: type.id
                          })
                        }>
                        <Tooltip title={findItem.label}>
                          <img
                            className="cat-icon"
                            src={renderIconCat(findItem.icon)}
                            alt=""
                          />
                        </Tooltip>
                        <div className="txt-type">{type.name}</div>
                      </div>
                    );
                  })}
              </div>
              <div className="div-btns">
                <Button
                  loading={loading}
                  shape="round"
                  size="small"
                  className="btn-style btn-create-cnc"
                  onClick={(e) => handleSubmit(e)}>
                  {t('create_cnc_label')}
                </Button>
              </div>
            </div>
          </Col>
        ) : (
          weekSelected.week && (
            <Col span={12} className="frmCol">
              <div className="info-cnc-form">
                <div className="no-tasks">{t('tasks_unselected_cnc')}</div>
              </div>
            </Col>
          )
        )}
      </Row>
    </Modal>
  );
};

export default CncForm;
