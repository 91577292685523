import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { timelineActions } from '../../redux/actions/lookaheadTimelineActions';
import { Row, Col, Dropdown, Menu, Icon, Empty } from 'antd';
import SingleOrderRow from '../SingleOrderRow';
import update from 'immutability-helper';
import './index.css';
export default function LookaheadOrder(props) {
  const [showedFilters, setShowedFilters] = useState({
    value: []
  }); /** Array with active filters */
  const [addFilterOptions, setAddFilterOptions] = useState(
    []
  ); /** Array with all options to add filters */
  const lookaheadState = useSelector((state) => state[props.lookaheadState]);
  const dispatch = useDispatch();
  const { orderLookaheadData, t } = props;

  /**
   * This function is just a middleware function between prop function to execute order
   * @param {*} toOrder Filters to be ordered
   */
  const localOrderData = (toOrder) => {
    dispatch(timelineActions.updateParsedValue(false));
    orderLookaheadData(toOrder);
  };

  useEffect(() => {
    props.checkBackgrondOrder(showedFilters);
  });

  useEffect(() => {
    if (props.currentOrderFilter) {
      setShowedFilters({ value: props.currentOrderFilter });
    }
  }, []);

  useEffect(() => {
    if (showedFilters.value.length != 0) {
      localOrderData(showedFilters);
    }
  }, [lookaheadState.notifyChangeOrder]);

  useEffect(() => {
    const opts = (
      <Menu>
        {props.options.map((op, index) => {
          if (op.orderable) {
            return (
              <Menu.Item key={index}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => addNewFilter(op)}>
                  {op.label}
                </a>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );

    setAddFilterOptions(opts);
  }, [
    showedFilters
  ]); /** If there are DOM elements on the JSX, associated with other useState hooks, needs to be updated to fit with the same STATE */

  /**
   * This function handle add new filters to the dinamic list
   * @param {*} filter Specific filter coming from props.options
   */
  const addNewFilter = (filter) => {
    const doesExist = showedFilters.value.filter((f) => {
      if (f.name == filter.name) return true;
      return false;
    });

    if (doesExist.length == 0) {
      filter.order_by = 'asc';
      showedFilters.value.push(filter);

      if (showedFilters.value.length > 0) {
        const { ganttVisualization } = window;
        if (ganttVisualization) {
          ganttVisualization.isDataOrdered = true;
        }
      }

      updateRender();
      localOrderData(showedFilters);
    }
  };

  /**
   * This function handle delete icon no single filter activated
   * @param {*} filter Specific filter coming from props.options
   */
  const deleteFilter = (filter) => {
    const n = showedFilters.value.filter((op) => {
      if (op != filter) return true;
      return false;
    });
    showedFilters.value = n;

    if (showedFilters.value.length === 0) {
      const { ganttVisualization } = window;
      if (ganttVisualization) {
        ganttVisualization.isDataOrdered = false;
      }
    }

    updateRender();
    localOrderData(showedFilters);
  };

  /**
   * This function handle order_by attr comming from single filter activated
   * @param {*} e event from input
   * @param {*} filter Specific filter coming from props.options
   */
  const onChangeOrderBy = (e, filter) => {
    const { value } = e.target;
    filter.order_by = value;
    updateRender();
    localOrderData(showedFilters);
  };

  /**
   * This functions allow developer to re load on force the render (virtual DOM)
   */
  const updateRender = () => {
    setShowedFilters({ ...showedFilters });
  };

  const moveCard = async (dragIndex, hoverIndex) => {
    const dragCard = showedFilters.value[dragIndex];
    showedFilters.value = update(showedFilters.value, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard]
      ]
    });
    updateRender();
    localOrderData(showedFilters);
  };

  const renderShowedFilters = () =>
    showedFilters.value.map((filter, index) => (
      <SingleOrderRow
        key={filter.name}
        index={index}
        id={index}
        filter={filter}
        moveCard={moveCard}
        deleteFilter={deleteFilter}
        onChangeOrderBy={onChangeOrderBy}
      />
    ));

  const checkIfFiltersAreActive = () => {
    if (showedFilters.value.length) {
      return renderShowedFilters();
    }
    return (
      <div className="non-selected-order">
        {t('master_plan.no_fields_filter_selected')}
      </div>
    );
  };

  return (
    <div className="order-main-container">
      <Row className="full-width-style">{checkIfFiltersAreActive()}</Row>
      <Row className="full-width-style">
        <Col className="switch-col-style">
          <Dropdown overlay={addFilterOptions} trigger={['click']}>
            <a
              onClick={(e) => e.preventDefault()}
              style={{ color: '#53C255', marginLeft: 3 }}>
              <Icon type="plus" style={{ color: '#53C255' }} />
              {t('master_plan.add_fields_filter')}
            </a>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
}
