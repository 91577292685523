import React from 'react';
import EmptyStateSystem from '../../../components/DesignSystem/EmptyStateSystem';
import { ScheduleTimeIcon } from '../../../icons';
import { getHeightSchedule } from '../project.helper';
import { Colors } from '../../../constants/colors.constants';

export default function EmptyFilteredProjects({ t = () => {} }) {
  const { DARK_GRAY } = Colors;
  const description = t('projects.empty_state_message.no_projects_matching');
  return (
    <div
      style={{
        height: getHeightSchedule()
      }}>
      {EmptyStateSystem({
        icon: <ScheduleTimeIcon color={DARK_GRAY} />,
        description
      })}
    </div>
  );
}
