import React, { useRef } from 'react';
import './styles.scss';
import useConfigurationPermissions from '../../hooks/useConfigurationPermissions';
import { getHeightSchedule } from '../../views/project/project.helper';
import EmptyFilteredProjects from '../../views/project/EmptyFilteredProjects/EmptyFilteredProjects';
import { useScheduleOfSchedules } from '../../hooks/useScheduleOfSchedules';

const ScheduleOfSchedules = ({
  t,
  processedProjects,
  setFormProjectVisible,
  setFormDeleteVisible,
  setZoomLevel,
  zoomLevel,
  onClickProject,
  filterText
}) => {
  const permissions = useConfigurationPermissions();
  const { ganttRef, filteredSchedules, isLoading } = useScheduleOfSchedules({
    t,
    permissions,
    processedProjects,
    setFormDeleteVisible,
    setFormProjectVisible,
    setZoomLevel,
    zoomLevel,
    onClickProject,
    filterText
  });

  if (isLoading) {
    return null;
  }

  if (!filteredSchedules.length) {
    return <EmptyFilteredProjects t={t} />;
  }

  return (
    <div
      className="schedule-of-schedule"
      ref={ganttRef}
      style={{
        height: getHeightSchedule(),
        paddingTop: '1px'
      }}
    />
  );
};

export default ScheduleOfSchedules;
