/* eslint-disable quote-props */
import { createHttpClient } from '../utils/httpUtils';

class CalendarService {
  constructor() {
    this.http = createHttpClient();
  }

  async show(calendarId) {
    const res = await this.http
      .get('calendars/' + calendarId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async showBySector(SectorId) {
    const res = await this.http
      .get('calendars/sector/' + SectorId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async destroy(calendarId) {
    const res = await this.http
      .delete('calendars/' + calendarId)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }

  async create(data) {
    const res = await this.http.post('calendars/', data).catch((error) => {
      console.log(error.response);
      return false;
    });
    return res ? res.data : res;
  }

  async update(data) {
    const res = await this.http
      .put('calendars/' + data.id, data)
      .catch((error) => {
        console.log(error.response);
        return false;
      });
    return res ? res.data : res;
  }
}

export const calendarService = new CalendarService();
