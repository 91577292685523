import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { timelineActions } from '../../redux/actions/lookaheadTimelineActions';
import closeImg from '../../assets/img/order-esc.png';
import arrowSelectImg from '../../assets/img/groupping-arrow.png';
import {
  Switch,
  Row,
  Col,
  Dropdown,
  Menu,
  Icon,
  Select,
  Input,
  InputNumber,
  DatePicker
} from 'antd';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import './index.css';
import moment from 'moment';
const { Option } = Select;

/**
 * This component allows users to filter Gantt Data through a dinamic filter opts
 * @param {*} props Props must receive a { options, gantt }
 */
export default function LookaheadFilter(props) {
  const { current: optionsArray } = useRef({});
  const { current: selectedOption } = useRef({ value: [] });
  const filterSubOptions = useRef([]);
  const [andFilter, setAndFilter] = useState({
    value: false
  }); /** Saves value to combine through AND/OR the whole filter list */
  const [showedFilters, setShowedFilters] = useState({
    value: []
  }); /** Array with active filters */
  const [addFilterOptions, setAddFilterOptions] = useState(
    []
  ); /** Array with all options to add filters */
  const lookaheadState = useSelector((state) => state[props.lookaheadState]);
  const dispatch = useDispatch();
  const { filterLookaheadData, t } = props;

  useEffect(() => {}, []);

  useEffect(() => {
    const filter = selectedOption.value.filter((option) =>
      showedFilters.value.find((filter) => filter.name != option.name)
    );

    selectedOption.value = filter;
    props.checkBackgrondFilter(showedFilters);
  }, [showedFilters]);

  /** apply filter when change reApplyFilters */
  useEffect(() => {
    !props.isPlanification && localFilterData(showedFilters, andFilter);
  }, [props.reApplyFilters]);

  useEffect(() => {
    if (props.currentFilter) {
      setShowedFilters({ value: props.currentFilter.queryFilter });
      setAndFilter({ value: props.currentFilter.conditionalFilter });
    }
  }, []);

  useEffect(() => {
    if (props.setIsFilter) {
      props.currentFilter.queryFilter.length
        ? props.setIsFilter(true)
        : props.setIsFilter(false);
    }
  }, [props.currentFilter]);

  useEffect(() => {
    if (showedFilters.value.length != 0) {
      localFilterData(showedFilters, andFilter);
    }
  }, [lookaheadState.notifyChangeFilter]);

  useEffect(() => {
    const options = validViewToApplyIdFilter();
    const opts = (
      <Menu>
        {options.map((op, index) => {
          if (op.filterable) {
            return (
              <Menu.Item key={index}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => addNewFilter(op)}>
                  {op.label}
                </a>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );

    setAddFilterOptions(opts);
  }, [
    showedFilters,
    props.options
  ]); /** If there are DOM elements on the JSX, associated with other useState hooks, needs to be updated to fit with the same STATE */

  /**
   * This function is just a middleware function between prop function to execute order
   * @param {*} toFilter Filters to by applied
   * @param {*} andFilter Defines if there is an && or an || in conditional filtering
   */
  const localFilterData = (toFilter, andFilter) => {
    dispatch(timelineActions.updateParsedValue(false));
    filterLookaheadData(toFilter, andFilter);
  };

  const validViewToApplyIdFilter = () => {
    if (
      (props.isPlanification && props.viewType.name != 'takt') ||
      (props.groupCriteria == 'activityId' && props.isFilterByActivityId)
    ) {
      return props.options;
    }

    return props.options.filter((option) => option.name != 'parent_id');
  };
  /**
   * This function handle the and/or filter
   * @param {*} checked Boolean value
   * @param {*} event Event on press
   */
  const onChangeAndSwitch = (checked, event) => {
    andFilter.value = checked;
    updateRender();
    localFilterData(showedFilters, andFilter);
  };

  /**
   * This function handle add new filters to the dinamic list
   * @param {*} filter Specific filter coming from props.options
   */
  const addNewFilter = (filter) => {
    // eslint-disable-next-line no-self-assign
    andFilter.value = andFilter.value;
    if (filter.data_type == 'string') {
      filter.equals = 'includes';
    } else if (filter.data_type == 'date') {
      filter.equals = 'afterthan';
    } else if (filter.data_type == 'number') {
      filter.equals = 'equals';
    } else if (filter.data_type.includes('array/')) {
      filter.equals = 'issomeof';
    } else {
      filter.equals = false;
    }
    filter.filter_by = '';
    showedFilters.value.push(filter);

    if (showedFilters.value.length > 0) {
      const { ganttVisualization } = window;
      if (ganttVisualization) ganttVisualization.isDataFiltered = true;
    }

    updateRender();
    localFilterData(showedFilters, andFilter);
  };

  /**
   * This function handle delete icon no single filter activated
   * @param {*} filter Specific filter coming from props.options
   */
  const deleteFilter = (filter) => {
    const n = showedFilters.value.filter((op) => {
      if (op != filter) return true;
      return false;
    });
    showedFilters.value = n;

    if (showedFilters.value.length === 0) {
      const { ganttVisualization } = window;
      if (ganttVisualization) ganttVisualization.isDataFiltered = false;
    }

    updateRender();
    localFilterData(showedFilters, andFilter);
  };

  /**
   * This function handle is/isnt option from single filter activated
   * @param {*} checked Boolean value
   * @param {*} event Event from on click
   * @param {*} filter Specific filter coming from props.options
   */
  const onChangeIsFilter = (checked, event, filter) => {
    filter.equals = checked;
    updateRender();
    localFilterData(showedFilters, andFilter);
  };

  /**
   * This function handle filter_by attr comming from single filter activated
   * @param {*} e event from input
   * @param {*} filter Specific filter coming from props.options
   */
  const onChangeFilterBy = (e, filter) => {
    const { value } = e.target;
    filter.filter_by = value;
    updateRender();
    localFilterData(showedFilters, andFilter);
  };

  const onNumberInputChange = (value, filter) => {
    filter.filter_by = value;
    updateRender();
    localFilterData(showedFilters, andFilter);
  };

  const onDateInputChange = (date, dateString, filter) => {
    filter.filter_by = dateString;
    updateRender();
    localFilterData(showedFilters, andFilter);
  };

  const onSelectSingleChange = (val, filter) => {
    filter.filter_by = val;
    updateRender();
    localFilterData(showedFilters, andFilter);
  };

  const updateSelectedOptions = (filter) => {
    props.options.forEach((option) => {
      if (option.name == filter.name) {
        filter.from_values = option.from_values;
      }
    });

    filter.from_values.map((el) => {
      if (filter.filter_by.includes(el.value)) {
        selectedOption.value.push({
          name: filter.name,
          label: el.label,
          value: el.value
        });
      }
    });

    filterSubOptions.current = filter.from_values;
  };

  /**
   * This functions allow developer to re load on force the render (virtual DOM)
   */
  const updateRender = () => {
    const a = JSON.stringify(showedFilters);
    const b = JSON.stringify(andFilter);
    setShowedFilters((prev) => {
      prev = JSON.parse(a);
      return prev;
    });

    setAndFilter((prev) => {
      prev = JSON.parse(b);
      return prev;
    });
  };

  const renderShowedFilters = () =>
    showedFilters.value.map((filter, index) => {
      let inputFilter;
      let filterableSelect;

      if (filter.data_type == 'string') {
        inputFilter = (
          <Input.Search
            value={filter.filter_by}
            onChange={(e) => onChangeFilterBy(e, filter)}
            size="small"
            className="gantt-header-search-bar custom-search-bar-prop"
            placeholder=""
          />
        );

        filterableSelect = (
          <Select
            suffixIcon={
              <div>
                <img src={arrowSelectImg} className="filter-arrow-style"></img>
              </div>
            }
            className="filter-lookahead-select-string filter-container-array"
            dropdownClassName="filter-lookahead-select-string-dropdown"
            defaultValue={filter.equals}
            onChange={(e) => {
              onChangeIsFilter(e, null, filter);
            }}>
            {/* <Option className="optionForFilter" value='notincludes'>{t('filters_label.string_data.notincludes')}</Option>
                    <Option className="optionForFilter" value='is'>{t('filters_label.string_data.is')}</Option>
                <Option className="optionForFilter" value='isnot'>{t('filters_label.string_data.isnot')}</Option> */}
          </Select>
        );
      } else if (filter.data_type == 'number') {
        inputFilter = (
          <InputNumber
            type="number"
            value={filter.filter_by}
            onChange={(e) => onNumberInputChange(e, filter)}
            size="small"
            controls={false}
            className="gantt-header-search-bar custom-search-bar-prop input-number-filter-hidden"
            placeholder=""
          />
        );

        filterableSelect = (
          <Select
            suffixIcon={
              <div>
                <img src={arrowSelectImg} className="filter-arrow-style"></img>
              </div>
            }
            className="filter-lookahead-select-string filter-container-array"
            dropdownClassName="filter-lookahead-select-string-dropdown"
            defaultValue={filter.equals}
            onChange={(e) => {
              onChangeIsFilter(e, null, filter);
            }}>
            <Option className="optionForFilter" value="equals">
              =
            </Option>
            <Option className="optionForFilter" value="notequals">
              ≠
            </Option>
            <Option className="optionForFilter" value="morethan">
              {'>'}
            </Option>
            <Option className="optionForFilter" value="lessthan">
              {'<'}
            </Option>
            <Option className="optionForFilter" value="moreequalsthan">
              ≥
            </Option>
            <Option className="optionForFilter" value="lessequalsthan">
              ≤
            </Option>
          </Select>
        );
      } else if (filter.data_type == 'date') {
        inputFilter = (
          <DatePicker
            onChange={(date, dateString) =>
              onDateInputChange(date, dateString, filter)
            }
            defaultValue={moment(filter.filter_by, 'YYYY/MM/DD')}
            format="YYYY/MM/DD"
            size="small"
            className="gantt-header-search-bar"
            placeholder=""
          />
        );

        filterableSelect = (
          <Select
            suffixIcon={
              <div>
                <img src={arrowSelectImg} className="filter-arrow-style"></img>
              </div>
            }
            className="filter-lookahead-select-string filter-container-array"
            dropdownClassName="filter-lookahead-select-string-dropdown"
            defaultValue={filter.equals}
            onChange={(e) => {
              onChangeIsFilter(e, null, filter);
            }}>
            <Option className="optionForFilter" value="afterthan">
              {t('filters_label.date_data.afterthan')}
            </Option>
            <Option className="optionForFilter" value="beforethan">
              {t('filters_label.date_data.beforethan')}
            </Option>
          </Select>
        );
      } else if (filter.data_type.includes('array/')) {
        if (filter.data_type.includes('string')) {
          updateSelectedOptions(filter);
          inputFilter = (
            <ReactMultiSelectCheckboxes
              onMenuClose={() => {
                props.setIsSubDropActive({ value: false });
                if (props.isSubDropActive.value === false) {
                  props.setIsSubDropActive({ value: true });
                }
              }}
              placeholder={t('master_plan.search')}
              className="filter-lookahead-multi-check"
              getDropdownButtonLabel={() => (
                <div
                  style={{
                    color: '#121212',
                    fontSize: '12px',
                    paddingRight: '32px'
                  }}
                  onClick={() => {
                    props.setIsSubDropActive({ value: true });
                    setTimeout(() => {
                      const a = document.getElementsByClassName(
                        'css-1pcexqc-container'
                      );
                      a[0].parentNode.style.marginTop = '0px';
                    }, 20);
                  }}>
                  <span className="select-custom-checkbox-filter-string">
                    {t('filter_by_label')}
                  </span>
                  <img
                    src={arrowSelectImg}
                    className="select-custom-checkbox-filter-arrow"></img>
                </div>
              )}
              onChange={(e) => {
                props.setIsSubDropActive({ value: true });
                selectedOption.value = e;
                const data = e.map((el) => el.value);
                onSelectSingleChange(data, filter);
              }}
              value={selectedOption.value}
              options={filterSubOptions.current}
            />
          );
        } else if (filter.data_type.includes('icon')) {
          updateSelectedOptions(filter);
          inputFilter = (
            <ReactMultiSelectCheckboxes
              onMenuClose={() => {
                props.isSubDropActive.value = false;
              }}
              placeholder={t('master_plan.search')}
              className="filter-lookahead-multi-check"
              getDropdownButtonLabel={() => (
                <div
                  style={{
                    color: '#121212',
                    fontSize: '12px',
                    paddingRight: '32px'
                  }}
                  onClick={() => {
                    props.isSubDropActive.value = true;
                    setTimeout(() => {
                      const a = document.getElementsByClassName(
                        'css-1pcexqc-container'
                      );
                      a[0].parentNode.style.marginTop = '0px';
                    }, 20);
                  }}>
                  <span className="select-custom-checkbox-filter-string">
                    {t('filter_by_label')}
                  </span>
                  <img
                    src={arrowSelectImg}
                    className="select-custom-checkbox-filter-arrow"></img>
                </div>
              )}
              onChange={(e) => {
                props.isSubDropActive.value = true;
                selectedOption.value = e;
                const data = e.map((el) => el.value);
                onSelectSingleChange(data, filter);
              }}
              value={selectedOption.value}
              options={filterSubOptions.current}
            />
          );
        } else if (filter.data_type.includes('images')) {
          if (!optionsArray[filter.name]) {
            optionsArray[filter.name] = [];
            props[filter.from_values].map((option) => {
              if (option.is_active) {
                optionsArray[filter.name].push({
                  label:
                    option[filter.el_to_label_from[0]] +
                    ' ' +
                    option[filter.el_to_label_from[1]],
                  value: option[filter.el_to_extract_from]
                });

                if (
                  filter.filter_by.includes(option[filter.el_to_extract_from])
                ) {
                  selectedOption.value.push({
                    name: filter.name,
                    label:
                      option[filter.el_to_label_from[0]] +
                      ' ' +
                      option[filter.el_to_label_from[1]],
                    value: option[filter.el_to_extract_from]
                  });
                }
              }

              if (filter.name != 'responsables') {
                optionsArray[filter.name].push({
                  label: option.name,
                  value: option.id
                });
              }
            });
          }
          inputFilter = (
            <ReactMultiSelectCheckboxes
              onMenuClose={() => {
                props.isSubDropActive.value = false;
              }}
              placeholder={t('master_plan.search')}
              className="filter-lookahead-multi-check"
              getDropdownButtonLabel={() => (
                <div
                  style={{
                    color: '#121212',
                    fontSize: '12px',
                    paddingRight: '32px'
                  }}
                  onClick={() => {
                    props.isSubDropActive.value = true;
                    setTimeout(() => {
                      const a = document.getElementsByClassName(
                        'css-1pcexqc-container'
                      );
                      if (a[0]?.parentNode) {
                        a[0].parentNode.style.marginTop = '0px';
                      }
                    }, 20);
                  }}>
                  <span className="select-custom-checkbox-filter-string">
                    {t('filter_by_label')}
                  </span>
                  <img
                    src={arrowSelectImg}
                    className="select-custom-checkbox-filter-arrow"></img>
                </div>
              )}
              onChange={(e) => {
                props.isSubDropActive.value = true;
                selectedOption.value = e;
                const data = e.map((el) => el.value);
                onSelectSingleChange(data, filter);
              }}
              value={selectedOption.value}
              options={optionsArray[filter.name]}
            />
          );
        }

        filterableSelect = (
          <Select
            className="filter-lookahead-select-string filter-container-array"
            dropdownClassName="filter-lookahead-select-string-dropdown"
            defaultValue={filter.equals}
            suffixIcon={
              <div>
                <img src={arrowSelectImg} className="filter-arrow-style"></img>
              </div>
            }
            onChange={(e) => {
              onChangeIsFilter(e, null, filter);
            }}>
            <Option className="optionForFilter" value="issomeof">
              {t('filters_label.array_data.issomeof')}
            </Option>
            <Option className="optionForFilter" value="isnotsomeof">
              {t('filters_label.array_data.isnotsomeof')}
            </Option>
          </Select>
        );
      }

      return (
        <Col key={index}>
          <Row className="single-filter-row">
            <Col
              onClick={() => deleteFilter(filter)}
              className="delete-filter-style"
              span={1}>
              <img
                src={closeImg}
                width={6}
                style={{ position: 'relative', top: -2 }}
              />
            </Col>
            <Col span={2}>{t('filters_label.where_label')}</Col>
            <Col span={6} offset={1}>
              {filter.label}
            </Col>
            <Col
              span={8}
              style={{ maxHeight: 27, overflow: 'hidden', marginLeft: -27 }}>
              {filterableSelect}
            </Col>
            <Col span={6} style={{ maxHeight: 29 }}>
              {inputFilter}
            </Col>
          </Row>
        </Col>
      );
    });

  const checkIfFiltersAreActive = () => {
    if (showedFilters.value.length) {
      return renderShowedFilters();
    }
    return (
      <div className="non-selected-order">
        {t('master_plan.no_fields_filter_selected')}
      </div>
    );
  };

  return (
    <div className="filter-main-container">
      <Row className="full-width-style">
        <Col className="switch-col-style">
          <span style={{ marginRight: 5 }}>
            {t('lang') === 'es' ? 'Y' : 'And'}
          </span>
          <Switch
            onChange={(checked, event) => onChangeAndSwitch(checked, event)}
            checked={andFilter.value}
            size="small"
          />
          <span style={{ marginLeft: 5 }}>
            {t('lang') === 'es' ? 'O' : 'Or'}
          </span>
        </Col>
      </Row>
      <Row className="full-width-style">{checkIfFiltersAreActive()}</Row>
      <Row className="full-width-style">
        <Col className="switch-col-style">
          <Dropdown overlay={addFilterOptions} trigger={['click']}>
            <a
              onClick={(e) => e.preventDefault()}
              style={{ color: '#53C255', marginLeft: 3 }}>
              <Icon type="plus" style={{ color: '#53C255' }} />
              {t('master_plan.add_fields_filter')}
            </a>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
}
