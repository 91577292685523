import React, { useState, useEffect } from 'react';
import EventEmitter from 'react-native-eventemitter';
import { Animated } from 'react-animated-css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import { Spin, Icon } from 'antd';

import UnitsView from './units';
import AssignView from './assign';
import useWindowDimensions from '../../hooks/useWindowDimensions';
// import useWeeklyplanPermissions from '../../hooks/useWeeklyplanPermissions'

import './index.css';
import { withTranslation } from 'react-i18next';
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

function TaktPlanningView(props) {
  const [loaded, setLoaded] = useState(false);
  const { height, width } = useWindowDimensions();
  // const weeklyplanPermissions = useWeeklyplanPermissions()
  const match = useRouteMatch();
  const { t } = props;

  const [buttons, setButtons] = useState([]);
  const [btnLoaded, setBtnLoaded] = useState(false);
  const [selected, setSelected] = useState(false);
  const [btnSyle, setBtnStyle] = useState('lookahead-btn-style');

  useEffect(() => {
    window.Appcues.page();
  });

  useEffect(() => {
    setTimeout(() => {
      const buttonsJson = [
        {
          btnTitle: t('takt_units.units_label'),
          routeName: `${match.url}/units`,
          onClick: (params) => {
            props.history.push(`${match.url}/units`);
          },
          hide: false // weeklyplanPermissions.plan == 'SA'
        },
        {
          btnTitle: t('takt_units.assign_label'),
          routeName: `${match.url}/assign`,
          onClick: (params) => {
            props.history.push(`${match.url}/assign`);
          },
          hide: false // weeklyplanPermissions.cncs == 'SA'
        }
      ];

      setButtons([...buttonsJson]);
      setBtnLoaded(true);
      setLoaded(true);
    }, 500);
  }, []);

  const handleClick = (e, btn, shouldGo = true) => {
    setSelected(true);
    setBtnStyle('lookahead-btn-style lookahead-disabled-btn-style');
    setButtons(
      buttons.map((btn) => {
        delete btn.className;
        return btn;
      })
    );
    btn.className = 'lookahead-btn-style';
    if (shouldGo) btn.onClick();
  };

  /** Similar to did mount */
  useEffect(() => {
    const callback = (data) => {
      data.route(props.history);
    };

    EventEmitter.on('changeMainRoute', callback);

    if (buttons.length && btnLoaded) {
      const url = window.location.href;
      setButtons(
        buttons.map((btn) => {
          if (url.includes(btn.routeName)) {
            handleClick(null, btn, false);
          }
          return btn;
        })
      );

      setLoaded(true);
    }

    return () => {
      EventEmitter.removeListener('changeMainRoute', callback);
    };
  }, [btnLoaded, match]);

  const renderLoadedContent = () => (
    <Row>
      <Col className="lookahead-container-header">
        {buttons.map((btn, index) => {
          if (btn.hide) return null;
          return (
            <Button
              key={index}
              className={btn.className ? btn.className : btnSyle}
              type="primary"
              onClick={(e) => handleClick(e, btn)}>
              {btn.btnTitle}
            </Button>
          );
        })}
      </Col>
    </Row>
  );

  return (
    <Animated
      animationIn="fadeIn"
      animationInDuration={500}
      isVisible={true}
      style={{ height: height - 72 }}>
      {loaded ? (
        renderLoadedContent()
      ) : (
        <Spin className="loader-spinner-lookahead-header" indicator={antIcon} />
      )}
      <Switch>
        <Route path={`${match.path}/units`}>
          <UnitsView {...props} /* permission={weeklyplanPermissions.plan} */ />
        </Route>
        <Route path={`${match.path}/assign`}>
          <AssignView
            {...props} /* permission={weeklyplanPermissions.cncs} */
          />
        </Route>
      </Switch>
    </Animated>
  );
}

export default withTranslation()(TaktPlanningView);
