/* eslint-disable max-lines-per-function */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'antd';
import { PDFDownloadLink } from '@react-pdf/renderer';
import './DownloadReportContainer.scss';
import pdfDownloadIcon from '../../../assets/img/icons/icon-pdf-download.svg';

const DownloadReportContainer = ({ t, pdfDocument, type }) => {
  const history = useHistory();

  return (
    <div className="report_container">
      <div>
        <section className="report_header">
          <img src={pdfDownloadIcon} alt="pdf-download-icon" />
          <p>{t('download_report.title')}</p>
        </section>
        <section className="report_content">
          <PDFDownloadLink
            className="button_download"
            document={pdfDocument}
            fileName={`${type}_report.pdf`}>
            {({ blob, url, loading, error }) =>
              loading ? (
                <Icon
                  type="loading"
                  style={{ fontSize: 20 }}
                  className="download__spinner"
                />
              ) : (
                t('download_report.buttons.download')
              )
            }
          </PDFDownloadLink>
          <Button className="button_back" onClick={() => history.goBack()}>
            {t('download_report.buttons.back')}
          </Button>
        </section>
      </div>
    </div>
  );
};

export default DownloadReportContainer;
