import { createSlice } from '@reduxjs/toolkit';
import dummy from './viewDummy';

export const ganttSliceKey = 'ganttState';

const initialState = {
  activatedColumns: [],
  isRangeDateFiltered: false,
  dateRangeToFilter: [],
  notifyChange: false,
  notifyChangeGroup: false,
  notifyChangeFilter: false,
  notifyChangeOrder: false,
  ganttType: true,
  userPreferenceTableGantt: { gridTable: [], ganttChart: 800 },
  visualizationConfig: {
    areLinksVisible: true,
    areBaselineVisible: true,
    areNumtasksVisible: true,
    isTodaylineVisible: true,
    areSubmittalsVisible: true,
    colorSchemeType: 'status',
    colorRowSchemeType: 'default'
  },
  modificationRequests: [],
  selectedActivities: [],
  lastUniqueCorrelativeIds: [],
  dateFormat: 'DD/MM/YYYY',
  filteredByLinkedActivities: [],
  showedFilters: [],
  views: {},
  loading: true
};

/** actions */
const { actions, reducer } = createSlice({
  name: ganttSliceKey,
  initialState,
  reducers: {
    setActivatedColumns(state, { payload }) {
      state.activatedColumns = payload;
    },
    setIsRangeFiltered(state, { payload }) {
      state.isRangeDateFiltered = payload.boolean;
      state.dateRangeToFilter = payload.range;
    },
    setUserPreferenceTableGantt(state, { payload }) {
      const { table, ganttChart } = payload;

      state.userPreferenceTableGantt = {
        gridTable: table || state.userPreferenceTableGantt.gridTable,
        ganttChart: ganttChart || state.userPreferenceTableGantt.ganttChart
      };
    },
    notifyLookaheadUpdate(state) {
      state.notifyChange = !state.notifyChange;
    },
    notifyLookaheadUpdateOrder(state) {
      state.notifyChangeOrder = !state.notifyChangeOrder;
    },
    notifyLookaheadUpdateFilter(state) {
      state.notifyChangeFilter = !state.notifyChangeFilter;
    },
    notifyLookaheadUpdateGroup(state) {
      state.notifyChangeGroup = !state.notifyChangeGroup;
    },
    setGanttValidate(state, { payload }) {
      state.ganttType = payload;
    },
    setSelectedActivities(state, { payload }) {
      state.selectedActivities = payload;
    },
    setLastUniqueCorrelativeIds(state, { payload }) {
      state.lastUniqueCorrelativeIds = payload;
    },
    setDateFormat(state, { payload: dateFormat }) {
      state.dateFormat = dateFormat;
    },
    notifyGanttVisualizationConfigChange(
      state,
      {
        payload: {
          areLinksVisible,
          areBaselineVisible,
          areNumtasksVisible,
          isTodaylineVisible,
          colorSchemeType,
          areSlackVisible,
          colorRowSchemeType,
          areSubmittalsVisible
        }
      }
    ) {
      // Modification requests Drawer now display dates according to the current Gantt format
      const payload = {};
      if (areLinksVisible !== undefined)
        payload.areLinksVisible = areLinksVisible;
      if (areSlackVisible !== undefined)
        payload.areSlackVisible = areSlackVisible;
      if (areBaselineVisible !== undefined)
        payload.areBaselineVisible = areBaselineVisible;
      if (areNumtasksVisible !== undefined)
        payload.areNumtasksVisible = areNumtasksVisible;
      if (isTodaylineVisible !== undefined)
        payload.isTodaylineVisible = isTodaylineVisible;
      if (areSubmittalsVisible !== undefined)
        payload.areSubmittalsVisible = areSubmittalsVisible;
      if (colorSchemeType !== undefined)
        payload.colorSchemeType = colorSchemeType;
      if (colorRowSchemeType !== undefined)
        payload.colorRowSchemeType = colorRowSchemeType;

      state.visualizationConfig = {
        ...state.visualizationConfig,
        ...payload
      };
    },
    modificationRequestsFetchSucceeded(state, { payload }) {
      state.modificationRequests = payload;
    },
    modificationRequestsFetchFailed(state) {
      state.modificationRequests = [];
    },
    setFilteredByLinkedActivities(state, { payload }) {
      state.filteredByLinkedActivities = payload.filtered;
    },
    setShowedFilters(state, { payload }) {
      state.showedFilters = payload;
    },
    setViews(state, { payload }) {
      state.views = payload;
    },
    setGanttLoading(state, { payload }) {
      state.loading = payload;
    },
    setInitialGanttState(state, { payload }) {
      return (state = { ...initialState });
    },

    // Dummy actions used in sagas
    modificationRequestsFetchRequested() {},
    modificationRequestsApproveRequested() {},
    modificationRequestsApproveSucceeded() {},
    modificationRequestsApproveFailed() {},
    modificationRequestsRejectRequested() {},
    modificationRequestsRejectSucceeded() {},
    modificationRequestsRejectFailed() {},
    modificationRequestsEnablePreviewRequested() {},
    modificationRequestsDisablePreviewRequested() {}
  }
});

reducer.blacklistedProperties = [
  'selectedActivities',
  'modificationRequests',
  'filteredByLinkedActivities',
  'showedFilters',
  'loading',
  'visualizationConfig'
];

const baseSelector = (state) => state[ganttSliceKey];

export function getVisualizationConfigSelector(state) {
  return baseSelector(state).visualizationConfig;
}

export function getSelectedActivitiesSelector(state) {
  return baseSelector(state).selectedActivities;
}

export function getLastUniqueCorrelativeIds(state) {
  return baseSelector(state).lastUniqueCorrelativeIds;
}

export function getModificationRequestsSelector(state) {
  return baseSelector(state).modificationRequests;
}

export function getGanttDateFormatSelector(state) {
  return baseSelector(state).dateFormat;
}

export function getShowedFiltersSelector(state) {
  return baseSelector(state).showedFilters;
}

export function getGanttLoadingSelector(state) {
  return baseSelector(state).loading;
}

export const ganttReducer = reducer;
export const {
  setActivatedColumns,
  setIsRangeFiltered,
  setUserPreferenceTableGantt,
  notifyLookaheadUpdate,
  notifyLookaheadUpdateOrder,
  notifyLookaheadUpdateFilter,
  notifyLookaheadUpdateGroup,
  setGanttValidate,
  setSelectedActivities,
  setLastUniqueCorrelativeIds,
  setDateFormat,
  setShowedFilters,
  setViews,
  setGanttLoading,
  setInitialGanttState,
  notifyGanttVisualizationConfigChange,
  modificationRequestsFetchRequested,
  modificationRequestsFetchFailed,
  modificationRequestsFetchSucceeded,
  modificationRequestsApproveRequested,
  modificationRequestsApproveSucceeded,
  modificationRequestsApproveFailed,
  modificationRequestsRejectRequested,
  modificationRequestsRejectSucceeded,
  modificationRequestsRejectFailed,
  modificationRequestsEnablePreviewRequested,
  modificationRequestsDisablePreviewRequested,
  setFilteredByLinkedActivities
} = actions;
