import React from 'react';
import ProjectCell from '../../../components/ProjectCell';

export default (gantt, { t }) => ({
  name: 'name',
  label: t('scheduleofschedule.columns.name'),
  disableActCard: true,
  width: '*',
  onrender: (item) => (
    <ProjectCell gantt={gantt} project={item} mainSchedule={item.sector_name} />
  )
});
