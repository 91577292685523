import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Outbuild from '../../../assets/img/proplanner.svg';
import NotificationSystem from '../../../components/DesignSystem/NotificationSystem';
import InputSystem from '../../../components/DesignSystem/InputSystem';
import { REGEX_EMAIL, REGEXT_BUSINESS_EMAIL } from '../constants';
import { authService, userService } from '../../../services';
import { base } from '../../../services/base';
import { STEPS } from '../constants';
import { trackingEvent } from '../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../analytics/constants';
import './index.scss';
import LayoutLoginOutbuild from '../../../components/LayoutLoginOutbuild';

const FirstScreen = (props) => {
  const { t, dataForm, setDataForm, setCurrentStep } = props;

  const [showNotification, setShowNotification] = useState(false);
  const [showEmailError, setShowEMailError] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const goToSignIn = (event) => {
    event.preventDefault();
    props.history.push('/login');
  };

  const handleInputChange = (event) => {
    setDataForm({
      ...dataForm,
      [event.target.name]: event.target.value
    });
  };

  const handleOnBlur = () => {
    setShowEMailError(isNotValidFormatEmail());
  };

  const isNotValidFormatEmail = () =>
    !REGEX_EMAIL.test(dataForm.email) ||
    !REGEXT_BUSINESS_EMAIL.test(dataForm.email);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingButton(true);

    if (!isNotValidFormatEmail() && !isLoadingButton) {
      try {
        const data = {
          user: dataForm.email.toLowerCase()
        };
        const response = await authService.alreadyExist(data);

        if (response.status === 200) {
          const {
            data: { already_exist_user }
          } = response;

          if (already_exist_user) {
            setShowNotification(true);
            setIsLoadingButton(false);

            trackingEvent(
              'email_existence',
              {
                email: dataForm.email
              },
              AMPLITUDE_SERVICE
            );

            return;
          }

          const userLang = navigator.language || navigator.userLanguage;
          const dataInviteUserFreeTrial = {
            email: dataForm.email,
            link: base.front + 'signupconfirmation/',
            lang: userLang.includes('es') ? 'es' : 'en'
          };

          const responseInviteUserFreeTrial =
            await userService.inviteUserFreeTrial(dataInviteUserFreeTrial);

          if (responseInviteUserFreeTrial.status === 200) {
            const domain = dataForm.email.split('@');
            trackingEvent(
              'email_entered',
              {
                email_domain: domain[1],
                is_work_email: true
              },
              AMPLITUDE_SERVICE
            );

            setCurrentStep(STEPS.SECOND_SCREEN);

            return;
          }
        }
      } catch (err) {
        console.log(`Error in onSubmit FirstScreen, error: ${err}`);
      }
    }
  };

  const renderForm = (
    <div className="first-screen__body">
      <div className="body__title">
        <h2>{t('signup.title')}</h2>
      </div>
      {showNotification && (
        <div className="body__notification">
          <NotificationSystem label={t('signup.email_already_exist_label')} />
        </div>
      )}
      <form onSubmit={onSubmit} className="body__form">
        {InputSystem({
          label: t('signup.email_label'),
          size: 'large',
          onBlur: () => handleOnBlur(),
          onChange: handleInputChange,
          name: 'email',
          autoComplete: 'off',
          hint: showEmailError ? t('signup.email_hint') : '',
          isError: showEmailError
        })}
        <button
          type="submit"
          disabled={isNotValidFormatEmail() || isLoadingButton}>
          {isLoadingButton ? <LoadingOutlined /> : t('signup.button_label')}
        </button>
      </form>
      <div className="body__links">
        <span>
          {t('signup.sign_in_text')}{' '}
          <a href="javascript:void(0);" onClick={goToSignIn}>
            {t('signup.sign_in_link')}
          </a>
        </span>
      </div>
    </div>
  );

  return <LayoutLoginOutbuild>{renderForm}</LayoutLoginOutbuild>;
};

export default FirstScreen;
