/* eslint-disable no-eval */
import React from 'react';
import { taskService, activityService, sectorService } from '../services';
import { base } from '../services/base';
import { Tooltip } from 'antd';
import { ganttAPI } from './customGanttPlugin';
import { transformHourToDays } from '../views/ganttContainer/gantt/gantt.helper';
import moment from 'moment';

import calendarModificationIcon from '../assets/img/activitymodification/calendar.png';
/** Import PNG for flag */
import constraintIcon from '../assets/img/newlookahead/name/constraint.png';
import flagGrey from '../assets/img/newlookahead/priority/low.png';
import flagBlue from '../assets/img/newlookahead/priority/normal.png';
import flagYellow from '../assets/img/newlookahead/priority/high.png';
import flagRed from '../assets/img/newlookahead/priority/urgent.png';

import blueStatus from '../assets/img/newlookahead/status/advancement.png';
import greenStatus from '../assets/img/newlookahead/status/ontime.png';
import redStatus from '../assets/img/newlookahead/status/overdue.png';
import greyStatus from '../assets/img/newlookahead/status/none.png';
import orangeStatus from '../assets/img/newlookahead/status/what.png';

import {
  getCompanyTextColor,
  getFlattenActivities,
  openNotification
} from '../utils';
import { TagIcon } from '../icons';
import { avoidOptimizationTimelineBlinking } from '../components/GanttVisualization/GanttVisualization.helper';
import TooltipHTML from '../components/TooltipHTML';
import MoneySymbolString from '../components/MoneySymbolString';
import { assignInitialCommitmentPercentaje } from '../views/weeklyPlan/weeklyPlan.helper';
import * as Sentry from '@sentry/react';
import { getSignedUser } from './userUtils';

const CHAR_PER_PIXEL = 5;

/**
 * This functions shows a pretty alert to user
 * @param {*} data Object { title, message, type }
 */
export const notifyMessage = (data) => {
  const alertErrorMailExists = {
    title: data.title,
    description: data.message,
    type: data.type
  };
  openNotification(alertErrorMailExists);
};

/**
 * This function search recursively inside of a array with elements with ID attr and children elements
 * @param {*} id id to find
 * @param {*} array array with children elements, and ID attr at his elements
 * @param {*} res Array to push result
 */
export const recursiveSearch = (id, array, res) => {
  array.map((el) => {
    if (el.id == id) {
      res.push(el);
    } else {
      recursiveSearch(id, el.children, res);
    }
  });
};

/**
 * This function search a task at state data by it ID
 * @param {*} task_id id of task to find the object (REQUIRED)
 * @param {*} taskParent parent to iterate inside it the search
 * @param {*} activity parent to iterate inside it the search (REQUIRED)
 */
export const getTask = (task_id, taskParent = null, activity) => {
  let res = [];

  if (!taskParent) {
    res = activity.tasks.filter((el) => el.id == task_id);
  }

  if (!res.length) {
    if (!taskParent) {
      taskParent = activity.tasks;
    } else {
      taskParent = taskParent.children;
    }
    const a = [];

    recursiveSearch(task_id, taskParent, a);

    return a;
  }

  return res;
};

/**
 * This function search a task at state data by it ID
 * @param {*} task_id id of task to find the object (REQUIRED)
 * @param {*} taskParent parent to iterate inside it the search
 * @param {*} activity parent to iterate inside it the search (REQUIRED)
 */
export const getTaskForProgress = (task_id, taskParent = null, activity) => {
  let res;

  if (task_id == activity.id) {
    res = [activity];
  } else if (!taskParent) {
    res = activity.tasks.filter((el) => el.id == task_id);
  }

  if (!res.length) {
    if (!taskParent) {
      taskParent = activity.tasks;
    } else {
      taskParent = taskParent.children;
    }
    const a = [];

    recursiveSearch(task_id, taskParent, a);
    return a;
  }

  return res;
};

export const getSumDuration = (parent) => {
  let total = 0;
  parent.children.map((el) => {
    total += el.duration;
  });
  parent.sumDuration = total;
  return total;
};

/**
 * This function deals with auto ponderator feature from PP
 * @param {*} parent Parent to get auto ponderators
 * @param {*} activity Activity container
 * @param {*} updateAsyncTask  Function to handle socket feature
 */
export const calculatePonderators = (
  parent,
  activity,
  updateAsyncTask = null,
  projectState = null
) => {
  const navigatorLang = navigator.language || navigator.userLanguage;
  let projectSelected;
  let taskCriteria = 'Duration';
  let companyValidation = true;
  if (projectState) {
    projectSelected = projectState.allProjects.find(
      (el) => el.id == projectState.projectSelected
    );
    taskCriteria = projectSelected.task_creter;
  }
  // 299
  companyValidation = Boolean(navigatorLang.includes('en'));
  /** Project state from redux */
  if (parent && !parent.hasCustomPonderator) {
    /** Define the name of attr to extract from parent object */
    const toExtractTasks = parent.tasks ? 'tasks' : 'children';
    if (parent[toExtractTasks]) {
      /**
       *  numerator   -> Number as 100 less ponderators with progress
       *  ---------
       *  denominator -> Sum of tasks ponderator criteria (hh/cost/duration)
       */
      let numerator = 100;
      let denominator = 0;
      parent[toExtractTasks].map((task) => {
        /** Tasks on editable state */
        if (task.progress != 0 && !companyValidation) {
          numerator -= task.ponderator;
        } else {
          switch (taskCriteria.toUpperCase()) {
            case 'DURATION':
              if (task?.children?.length) {
                denominator += getSumDuration(task);
              } else {
                denominator += task.duration;
              }
              break;
            case 'COST':
              denominator += parseFloat(task.cost);
              break;
            case 'HH':
              denominator += task.hhWorkTime;
              break;
          }
          /** Hardcoded by now just to duration */
        }
      });

      /** This constant must be used to multiply each criteria (hh/duration/cost) whitout progress */
      let relationValue = numerator / denominator;
      if (!denominator) relationValue = 0;

      /**
       * Note: This section code below, allow to inline editing.
       * Just for now, this is working on calculating ponderators which includes follow actions:
       * - Add task
       * - Delete Task
       * - Change duration on a task
       */
      parent[toExtractTasks].map((task) => {
        const originalPonderator = parseFloat(JSON.stringify(task.ponderator));
        if (task.progress == 0 || companyValidation) {
          let newPonderator;
          switch (taskCriteria.toUpperCase()) {
            case 'DURATION':
              if (task?.children?.length) {
                newPonderator = relationValue * getSumDuration(task);
              } else {
                newPonderator = relationValue * task.duration;
              }
              break;
            case 'COST':
              newPonderator = relationValue * parseFloat(task.cost);
              break;
            case 'HH':
              newPonderator = relationValue * task.hhWorkTime;
              break;
          }

          /** We assign the new auto ponderator with the multiplier constant with criteria */
          task.ponderator = newPonderator;
          if (!task.ponderator)
            Sentry.captureException(new Error('Null or 0 Weight'));
          if (originalPonderator != newPonderator) {
            if (newPonderator) {
              if (updateAsyncTask) {
                updateAsyncTask(task);
              } else {
                taskService.update(task);
              }
            }
          }
        }
      });
    }
  }
};

/**
 * This function launch onBeforeSave event from inlineeditors, to connect common inline behaviour
 * @param {*} task task lookahead object ref
 * @param {*} field field name to be updated
 * @param {*} value new value for that field
 * @param {*} timer timer for the internal timeout which is default 500 ms
 */
export const launchInlineEditorEvent =
  (gantt) =>
  (task, field, value, timer = 500) => {
    if (!task || !field || !value) return;
    if (gantt?.ext?.inlineEditors?.callEvent) {
      const editorState = {
        id: task.id,
        columnName: field,
        newValue: value,
        oldValue: null
      };
      setTimeout(() => {
        gantt.ext.inlineEditors.callEvent('onBeforeSave', [editorState]);
        gantt.ext.inlineEditors.callEvent('onEditEnd', [editorState]);
      }, timer);
    }
  };

/**
 * This function handles at virtual dom to show an icon when task dates dont fit at activity parent
 * @param {*} task Task to define if has problems of dates fit (REQUIRED)
 * @param {*} activity Parent activity to request a modification if necessary (REQUIRED)
 */
export const hasUnfitProblem = (
  task,
  activity,
  updateAsyncTask,
  handleActivityModificationRequest,
  onlyRead = false,
  t
) => {
  let handleActivityModificationTooltipRef = null;
  const oldUnfitType = JSON.parse(JSON.stringify(task.unfitType));
  detectUnfitDate(activity, task);

  if (oldUnfitType != task.unfitType) {
    updateAsyncTask(task);
  }

  if (task.unfitType) {
    let tooltip;
    switch (task.unfitType) {
      case 'start':
        tooltip = t('start_unfit_tooltip');
        break;
      case 'end':
        tooltip = t('end_unfit_tooltip');
        break;
      case 'both':
        tooltip = t('both_unfit_tooltip');
        break;
    }

    return (
      <Tooltip
        title={tooltip}
        ref={(r) => {
          handleActivityModificationTooltipRef = r;
        }}>
        <span
          className="unfit-task-icon"
          style={onlyRead ? { cursor: 'not-allowed' } : {}}
          onClick={() => {
            handleActivityModificationTooltipRef &&
              handleActivityModificationTooltipRef.tooltip.setState({
                visible: false
              });
            if (!onlyRead) {
              handleActivityModificationRequest(task, activity);
            }
          }}>
          <img
            src={calendarModificationIcon}
            width={12}
            className="unfit-icon-calendar"
          />
        </span>
      </Tooltip>
    );
  }
};

/**
 * This function handles at virtual dom to show an icon when task dates dont fit at activity parent
 * @param {*} task Task to define if has problems of dates fit (REQUIRED)
 * @param {*} activity Parent activity to request a modification if necessary (REQUIRED)
 */
export const hasUnfitProblem2 = (
  task,
  activity,
  updateAsyncTask,
  handleActivityModificationRequest,
  onlyRead = false,
  t
) => {
  const handleActivityModificationTooltipRef = null;
  const oldUnfitType = JSON.parse(JSON.stringify(task.unfitType));
  detectUnfitDate(activity, task);

  if (oldUnfitType != task.unfitType) {
    updateAsyncTask(task);
  }

  if (task.unfitType) {
    let tooltip;
    switch (task.unfitType) {
      case 'start':
        tooltip = t('start_unfit_tooltip');
        break;
      case 'end':
        tooltip = t('end_unfit_tooltip');
        break;
      case 'both':
        tooltip = t('both_unfit_tooltip');
        break;
    }

    return `<span class="unfit-task-icon" style="cursor: ${onlyRead ? 'not-allowed' : 'pointer'}" onclick="window.ganttVisualization.lookaheadTemplates_actionActivityModification(${task.id})">
            ${TooltipHTML({
              text: tooltip,
              container: `<img src=${calendarModificationIcon} width="12" class="unfit-icon-calendar" />`,
              task_id: task.id,
              name: `${task.id}-task-tooltip`
            })}
        </span>`;
  }
};

/**
 * This code section deals with relation of constraints
 * @param {*} task task to check if has constraints
 */
export const hasConstraint = (
  task,
  openAddConstraint,
  onlyRead = false,
  t,
  showConstraintIndicator = true
) => {
  let color = task.constraints.length ? '#e50101' : 'grey';
  let constraintIndicatorTooltipRef = null;
  let addConstraintTooltipRef = null;
  let indicator;
  /**
   * Released indicator
   * released -> Only with released enum type of constraint status
   * --------
   * total -> Total of constraint (dont matter it status)
   */
  if (task.constraints.length) {
    const released = task.constraints.filter(
      (constraint) => constraint.status == 'released'
    );
    indicator = `${released.length}/${task.constraints.length}`;
    if (released.length == task.constraints.length) {
      color = '#15a1b7';
    }
  }

  return (
    <span>
      {showConstraintIndicator && indicator ? (
        <Tooltip
          ref={(r) => {
            constraintIndicatorTooltipRef = r;
          }}
          title={t('constraints_counter')}>
          <span
            style={{ fontSize: 12, paddingRight: 5, color: color }}
            onClick={() => {
              addConstraintTooltipRef &&
                addConstraintTooltipRef.tooltip.setState({ visible: false });
              constraintIndicatorTooltipRef &&
                constraintIndicatorTooltipRef.tooltip.setState({
                  visible: false
                });
            }}>
            {indicator}
          </span>
        </Tooltip>
      ) : null}

      {onlyRead ? null : (
        <Tooltip
          ref={(r) => {
            addConstraintTooltipRef = r;
          }}
          title={t('constraints_tooltip')}>
          <span
            style={{ cursor: 'pointer', color: color }}
            onClick={() => {
              avoidOptimizationTimelineBlinking();
              addConstraintTooltipRef &&
                addConstraintTooltipRef.tooltip.setState({ visible: false });
              constraintIndicatorTooltipRef &&
                constraintIndicatorTooltipRef.tooltip.setState({
                  visible: false
                });
              openAddConstraint(task);
            }}>
            <img
              style={{ opacity: 0.5 }}
              width={14}
              className="img-constraint"
              src={constraintIcon}
            />
          </span>
        </Tooltip>
      )}
    </span>
  );
};

export const hasConstraintHtml = (
  task,
  openAddConstraint,
  onlyRead = false,
  t,
  showConstraintIndicator = true,
  id = null,
  name = ''
) => {
  let color = task.constraints.length ? '#e50101' : 'grey';
  const constraintIndicatorTooltipRef = null;
  const addConstraintTooltipRef = null;
  let indicator;
  /**
   * Released indicator
   * released -> Only with released enum type of constraint status
   * --------
   * total -> Total of constraint (dont matter it status)
   */
  if (task.constraints.length) {
    const released = task.constraints.filter(
      (constraint) => constraint.status == 'released'
    );
    indicator = `${released.length}/${task.constraints.length}`;
    if (released.length == task.constraints.length) {
      color = '#15a1b7';
    }
  }

  window.ganttVisualization.hasConstraintHtml = async (task_id) => {
    if (!task_id) return;
    avoidOptimizationTimelineBlinking();
    addConstraintTooltipRef &&
      addConstraintTooltipRef.tooltip.setState({ visible: false });
    constraintIndicatorTooltipRef &&
      constraintIndicatorTooltipRef.tooltip.setState({ visible: false });
  };
  const task_id = task.id;
  const name_tooltip = name !== '' ? 'roadblock-' + name : 'roadblock';
  return `<span>
            ${
              showConstraintIndicator && indicator
                ? TooltipHTML({
                    text: t('constraints_counter'),
                    container: `<span style="font-size: 12px; padding-right: 5px; color: ${color}">${indicator}</span>`,
                    task_id: id,
                    name: name_tooltip
                  })
                : ''
            }

            ${
              onlyRead
                ? ''
                : `
                    <span style="cursor: 'pointer'; color: ${color}" >
                        <img
                            style="opacity: 0.25"
                            width="14"
                            class="img-constraint"
                            src=${constraintIcon}
                        />
                    </span>
                `
            }
        </span>`;
};

export const hasConstraintActivityParent = (task, onlyRead = false, t) => (
  <>
    {onlyRead && (
      <Tooltip title={t('constraints_tooltip')}>
        <span style={{ cursor: 'pointer', color: '#15a1b7' }}>
          <img width={12} className="img-constraint" src={constraintIcon} />
        </span>
      </Tooltip>
    )}
  </>
);

export const transformDate = (date) => {
  switch (typeof date) {
    case 'string':
      return date.split('T')[0];
    case 'object':
      return date.toISOString().split('T')[0];
  }
};

export const transformDateWithHour = (date) => {
  switch (typeof date) {
    case 'string':
      return date;
    case 'object':
      return moment(date.toString()).format('YYYY/MM/DD H:mm');
  }
};

export const detectUnfitDate = (activity, task) => {
  /** Objects to calculate fit (copy of them to not perturb data) */
  const auxEndActivity = new Date(activity.end_date);
  const auxStartActivity = new Date(activity.start_date);
  const auxEndTask = new Date(transformDateWithHour(task.end_date));
  const auxStartTask = new Date(transformDateWithHour(task.start_date));

  /** Fit hours */
  /* auxEndActivity.setDate(auxEndActivity.getDate() + 1)
    auxEndActivity.setHours(23)
    auxEndActivity.setMinutes(59)
    auxEndActivity.setSeconds(59)
    auxEndActivity.setMilliseconds(59)
    auxEndTask.setHours(23)
    auxEndTask.setMinutes(59)
    auxEndTask.setSeconds(59)
    auxEndTask.setMilliseconds(59) */

  /** If end dates dont fit, but starts does */
  if (
    auxEndTask.getTime() > auxEndActivity.getTime() &&
    auxStartTask.getTime() >= auxStartActivity.getTime()
  ) {
    task.unfitType = 'end';
    return true;

    /** If start dates dont fit, but ends does */
  } else if (
    auxStartTask.getTime() < auxStartActivity.getTime() &&
    auxEndTask.getTime() <= auxEndActivity.getTime()
  ) {
    task.unfitType = 'start';
    return true;

    /** If none of both fit */
  } else if (
    auxEndTask.getTime() > auxEndActivity.getTime() &&
    auxStartTask.getTime() < auxStartActivity.getTime()
  ) {
    task.unfitType = 'both';
    return true;
  }
  task.unfitType = null;
  return false;
};

export const defineTitleModification = (task, t) => {
  switch (task.unfitType) {
    case 'start':
      return t('modals.lookahead.activity_modification_request.start_unfit');
    case 'end':
      return t('modals.lookahead.activity_modification_request.end_unfit');
    case 'both':
      return t('modals.lookahead.activity_modification_request.both_unfit');
  }
};

export const defineDateUnfit = (task, activity, t) => {
  let startClass = 'date-task-container-activity-modification';
  let endClass = 'date-task-container-activity-modification';
  let startIndicator;
  let endIndicator = '';
  let startTextColor = '#2C3421';
  let endTextColor = '#2C3421';
  /** This fix crash screen when trying to create an modification request from timeline view */
  if (!task.unfitType && window.ganttVisualization) {
    hasUnfitProblem2(
      task,
      activity,
      (t) => taskService.update(t),
      (a) => {
        activityService.update(a).then(() => {
          activityService.updateTreeProgress(a.id);
        });
      },
      window.ganttVisualization.shouldOnlyRead,
      t
    );
  }

  if (task.unfitType == 'start') {
    endIndicator = transformDate(activity.end_date);
    endClass += ' fit-date-activity-modification';
    endTextColor = '#C1C1C1';
    startIndicator = transformDate(task.start_date);
  } else if (task.unfitType == 'end') {
    startIndicator = transformDate(activity.start_date);
    startClass += ' fit-date-activity-modification';
    startTextColor = '#C1C1C1';
    endIndicator = transformDate(task.end_date);

    /** Code blow does the same that end_date for tasks (fit end dates to 0) */
    const dateObject = new Date(endIndicator);
    // dateObject.setHours(0)
    endIndicator = dateObject.toISOString().split('T')[0];
  } else if (task.unfitType == 'both') {
    startIndicator = transformDate(task.start_date);
    endIndicator = transformDate(task.end_date);

    /** Code blow does the same that end_date for tasks (fit end dates to 0) */
    const dateObject = new Date(endIndicator);
    // dateObject.setHours(0)
    endIndicator = dateObject.toISOString().split('T')[0];
  }

  return (
    <div>
      <div
        className="dates-container-task-activity-modification"
        style={{ marginBottom: 0 }}>
        <span style={{ display: 'inline-block', width: '50%', paddingLeft: 2 }}>
          <span
            style={{
              float: 'left',
              paddingLeft: 2,
              color: startTextColor,
              fontSize: 11
            }}>
            {t('modals.lookahead.activity_modification_request.start_date')}
          </span>
        </span>
        <span style={{ display: 'inline-block', width: '50%' }}>
          <span
            style={{
              float: 'left',
              paddingLeft: 8,
              color: endTextColor,
              fontSize: 11
            }}>
            {t('modals.lookahead.activity_modification_request.end_date')}
          </span>
        </span>
      </div>

      <div className="dates-container-task-activity-modification">
        <span className={startClass} style={{ marginRight: 6 }}>
          {startIndicator.split(' ')[0].split('-').join('/')}
        </span>
        <span className={endClass}>
          {endIndicator.split(' ')[0].split('-').join('/')}
        </span>
      </div>
    </div>
  );
};

/**
 * This function use gantt DHTMLX API to get end date with calendars as does at gantt chart view
 * @param {*} task lookahead task to convert to a gantt task (plugin apis) (REQUIRED)
 * @param {*} activity Parent activity object to check problems on dates range
 */
export const getEndDateByGantt = (task, activity = null) => {
  const calendarId = activity ? activity.calendarId : null;
  const res = ganttAPI.getEndByDuration(
    task.start_date,
    task.duration,
    task.id,
    calendarId
  );
  task.start_date = res.start_date;
  task.duration = res.duration;
  task.end_date = res.end_date;
  if (typeof task.base_duration !== 'undefined' && task.base_duration !== 0) {
    const base = ganttAPI.getEndByDuration(
      task.base_start_date,
      task.base_duration,
      task.id,
      calendarId
    );
    task.base_start_date = base.start_date;
    task.base_end_date = base.end_date;
    task.base_duration = base.duration;
  }
  /** Validation of fit of activity date range */
  if (activity) {
    detectUnfitDate(activity, task);
  }
};

/**
 * This function calculates the progress for all tree recursively
 * @param {*} task Element which one was modified the progress
 * @param {*} parent Parent element can be task or activity (REQUIRED)
 * @param {*} activity Activity which to belongs the task to can use getTask (REQUIRED)
 * @param {*} updateAsyncTask Function to handle socket feature
 * @param {*} updateAsyncActivity Function to handle socket feature
 */
export const calculateProgress = (
  task,
  parent,
  activity,
  updateAsyncTask = null,
  updateAsyncActivity = null
) => {
  const childs = parent.tasks || parent.children;

  let progress = 0;
  childs.map((t) => {
    const toExtractFrom = t.editValues || t;
    progress += (toExtractFrom.ponderator / 100) * toExtractFrom.progress;
  });

  const toExtractFromParent = parent.editValues || parent;
  toExtractFromParent.progress = progress;

  if (parent.tasks) {
    if (updateAsyncActivity) {
      updateAsyncActivity(parent);
    } else {
      activityService.update(parent).then(() => {
        activityService.updateTreeProgress(parent.id);
      });
    }
  } else {
    if (updateAsyncTask) {
      updateAsyncTask(parent);
    } else {
      taskService.update(parent);
    }
  }

  const newParentId = parent.parent_id || parent.activityId;

  if (newParentId) {
    const newParent = getTaskForProgress(newParentId, null, activity);
    if (newParent.length)
      calculateProgress(
        parent,
        newParent[0],
        activity,
        updateAsyncTask,
        updateAsyncActivity
      );
  }
  if (window.ganttVisualization) {
    const identifier = parent.tasks ? 'unique_id' : 'id';
    const object = window.ganttVisualization.getTask(parent[identifier]);
    object.progress = parent.progress;
    window.ganttVisualization.updateTask(object.id);
  }
};

export const calculateLeanStatus = (
  task,
  parent,
  activity,
  updateAsyncTask = null,
  updateAsyncActivity = null
) => {
  /** get childs, only tasks */
  const childs = parent.children || [];

  /** check childs lean status */
  const checkChildsLeanStatus = childs.some((el) => el.lean_status === 'Will');
  if (checkChildsLeanStatus) {
    parent.lean_status = 'Will';
  } else {
    /** check childs with equal lean status  */
    if (childs.length) {
      const checkChildsAllLeanStatus = childs.every(
        (el) => el.lean_status === task.lean_status
      );
      /** all childs are Will */
      if (checkChildsAllLeanStatus) {
        parent.lean_status = task.lean_status;
      } else {
        parent.lean_status = 'Debit';
      }
    }
  }

  /** update parent */
  if (!parent.tasks) {
    /** is task */
    if (updateAsyncTask) {
      updateAsyncTask(parent);
    } else {
      taskService.update(parent);
    }
  }

  /** get parent of current object && recursivity */
  const newParentId = parent.parent_id;
  if (newParentId) {
    /** get parent's reference */
    const newParent = getTask(newParentId, null, activity);
    if (newParent.length) {
      calculateLeanStatus(
        parent,
        newParent[0],
        activity,
        updateAsyncTask,
        updateAsyncActivity
      );
    }
  }
};
export const getFlattenTasks = (data, activity) =>
  data.reduce((result, next) => {
    result.push(next);
    let childrens = next.tasks || next.children;
    if (childrens) {
      result = [...result, ...getFlattenActivities(childrens)];
      childrens = [];
    }
    return result;
  }, []);

/**
 * Recursive function that adds childs from a task to delete
 * @param {*} task Task to delete and find childs to delete
 * @param {*} finalArray Array which will be pushed to add new tasks to delete
 */
export const getTasksToDelete = (task, finalArray) => {
  finalArray.push(task);
  const childTasks = task.children;
  if (childTasks.length != 0) {
    childTasks.map((t) => {
      getTasksToDelete(t, finalArray);
    });
  }
};

/**
 * This function deletes a task from an activity
 * @param {*} task Task to delete (REQUIRED)
 * @param {*} activity Parent activity of task if it is from first lvl (REQUIRED)
 * @param {*} parent If task is from another than first level must be specified the parent task to attack it children array
 * @param {*} updateAsyncTask  Function to handle socket feature
 */
export const deleteTaskHandler = async (
  task,
  activity,
  parent = null,
  updateAsyncTask = null,
  updateAsyncActivity = null,
  projectState = null,
  isLastChildren = false
) => {
  const res = await taskService.destroy(task.id);

  if (res) {
    /** Get array without task */
    if (parent) {
      parent.children = parent.children.filter((t) => {
        if (t != task) return true;
        return false;
      });
    } else {
      activity.tasks = activity.tasks.filter((t) => {
        if (t != task) return true;
        return false;
      });
    }

    calculatePonderators(
      isLastChildren ? activity : parent || activity,
      activity,
      updateAsyncTask,
      projectState
    );
    if (!isLastChildren) {
      calculateProgress(
        null,
        parent || activity,
        activity,
        updateAsyncTask,
        updateAsyncActivity
      );
    }

    if (activity.tasks.length == 0 && activity.isOnLookahead) {
      const s = await activityService.assignLookahead({
        id: activity.id,
        is_lookahead: false
      });
      activity.isOnLookahead = false;
    }

    // notifyMessage({ title: 'Eliminado', message: 'Tarea eliminada exitosamente', type: 'success' })
  } else {
    // notifyMessage({ title: 'Error', message: 'No ha sido posible eliminar los cambios', type: 'error' })
  }
};

export const renderTag = (props, option, snapshot, className) => {
  if (option.name === 'NONE_TAG_EXIST') {
    return <div className="none-tag-exist"></div>;
  }
  const tag = option.object;
  const { description, name } = tag;

  let toShowName = `${name}`;
  if (toShowName) {
    if (toShowName.length > 25) {
      toShowName = toShowName.slice(0, 25) + '...';
    }
  }

  const textColor = getCompanyTextColor(description);

  return (
    <button
      {...props}
      className={className}
      type="button"
      style={{ background: description }}>
      <span style={{ display: 'inline-flex' }}>
        <div
          style={{
            position: 'relative',
            display: 'inline-flex'
          }}>
          <span
            title={tag.name}
            className="cut-text-and-dot"
            style={{
              position: 'relative',
              fontSize: 12,
              maxWidth: 140,
              left: 3,
              color: textColor
            }}>
            <TagIcon color={textColor} className="tag-class" />
            {name}
          </span>
        </div>
      </span>
    </button>
  );
};

/**
 * This function builds a JSX to select responsables at inline edition
 */
export const renderFriend = (props, option, snapshot, className) => {
  const imgStyle = {
    borderRadius: '50%',
    verticalAlign: 'middle',
    marginRight: 10
  };

  let toShowName = `${option.object.name} ${option.object.lastname}`;
  if (toShowName) {
    if (toShowName.length > 25) {
      toShowName = toShowName.slice(0, 25) + '...';
    }
  }

  return (
    <button
      {...props}
      className={className}
      type="button"
      title={option.object.email}>
      <span style={{ display: 'flex' }}>
        <img
          alt=""
          style={imgStyle}
          width="16"
          height="16"
          src={option.photo}
        />
        <div
          className="cut-text-and-dot"
          style={{ fontSize: 12, width: '100%' }}
          title={toShowName}>
          {toShowName}
        </div>
      </span>
    </button>
  );
};

export const renderSubcontract = (props, option, snapshot, className) => {
  let toShowName = `${option.name}`;
  if (toShowName) {
    if (toShowName.length > 25) {
      toShowName = toShowName.slice(0, 25) + '...';
    }
  }

  return (
    <button
      {...props}
      className={className}
      type="button"
      style={{
        backgroundColor: option.color,
        color: getCompanyTextColor(option.color)
      }}>
      <span style={{ display: 'flex' }}>
        <div
          className="cut-text-and-dot"
          style={{ fontSize: 12, width: '100%' }}
          title={toShowName}>
          {toShowName}
        </div>
      </span>
    </button>
  );
};

/**
 * Calculate the width of the text.
 *
 * @param {Object} text - The text to be displayed.
 * @returns {number} The width of the text.
 */
function getWidthOfText(text) {
  const element = document.createElement('div');
  element.style.display = 'inline-block';
  element.innerHTML = text;
  document.body.appendChild(element);
  const width = element.offsetWidth;
  document.body.removeChild(element);
  return width;
}

export const splitText = (text, column) => {
  if (typeof text === 'string') {
    let showedText = text;
    let tooltip;
    const correlativeNumberLength = Math.ceil(
      Math.log10(column.correlative_id + 1)
    );
    let colAdjustWidth = Math.trunc(
      column.width - column.marginLeft - correlativeNumberLength * 5 - 20
    );

    let textAdjustWidth = showedText.length * 2;
    if (column.name == 'taskRoute' || column.name == 'name') {
      switch (column.view) {
        case 'planninggroup':
          colAdjustWidth = Math.trunc(column.width / 3.6);
          break;
        default:
          colAdjustWidth = Math.trunc(column.width / 3.8);
          break;
      }
    } else if (column.name == 'activityNameOnPanel') {
      textAdjustWidth = getWidthOfText(showedText);
    }

    if (textAdjustWidth > colAdjustWidth) {
      if (column.name == 'activityNameOnPanel') {
        const letterWidth = textAdjustWidth / text.length;
        showedText =
          text.slice(0, Math.trunc(colAdjustWidth / letterWidth / 1.15)) +
          '...';
      } else {
        showedText = text.slice(0, Math.trunc(colAdjustWidth / 2)) + '...';
      }
      tooltip = true;
    }

    return tooltip ? (
      <Tooltip placement="top" title={text}>
        {showedText}
      </Tooltip>
    ) : (
      showedText
    );
  }
  return text;
};

/**
 * Check if the text length exceeds the permited column width.
 *
 * @param {string} text - The text to be displayed.
 * @param {Object} column - The column object containing properties like width, marginLeft, and correlative_id.
 * @returns {boolean} True if the text is too long for the column width, false otherwise.
 */
function isTextTooLong(text, column) {
  const textAdjustWidth = getWidthOfText(text);
  return textAdjustWidth > column.width;
}

/**
 * Shorten the text if it is too long for the column width.
 *
 * @param {string} text - The text to be displayed.
 * @param {Object} column - The column object containing properties like width, marginLeft, and correlative_id.
 * @returns {string} The original text if it fits the column width, otherwise a shortened version.
 */
function getShortenedText(text, column) {
  const maxChars = Math.floor(column.width / CHAR_PER_PIXEL);
  return text.slice(0, maxChars);
}

/**
 * Split the activity tree text based on the column width.
 * If the text is too long, it is shortened and displayed in a Tooltip component.
 *
 * @param {string} text - The text to be displayed.
 * @param {Object} column - The column object containing properties like width, marginLeft, and correlative_id.
 * @returns {Object|string} The Tooltip component with the shortened text or the original text if it fits the column width.
 */
export const splitTextActivityTree = (text, column) => {
  if (typeof text !== 'string') {
    return text;
  }

  const shouldShortenText = isTextTooLong(text, column);
  const shortenedText = shouldShortenText
    ? getShortenedText(text, column)
    : text;

  if (shouldShortenText) {
    return (
      <Tooltip placement="top" title={text}>
        {shortenedText}
      </Tooltip>
    );
  }

  return text;
};

export const splitTextHTML = (text, column, id) => {
  if (typeof text === 'string') {
    let showedText = text;
    let tooltip;
    const correlativeNumberLength = Math.ceil(
      Math.log10(column.correlative_id + 1)
    );
    let colAdjustWidth = Math.trunc(
      column.width - column.marginLeft - correlativeNumberLength * 5 - 20
    );
    let textAdjustWidth = showedText.length * 2;
    const getWidthOfText = function (text) {
      const element = document.createElement('div');
      element.style.display = 'inline-block';
      element.innerHTML = text;
      document.body.appendChild(element);
      const width = element.offsetWidth;
      document.body.removeChild(element);
      return width;
    };
    if (column.name == 'taskRoute' || column.name == 'name') {
      switch (column.view) {
        case 'planninggroup':
          colAdjustWidth = Math.trunc(column.width / 3.6);
          break;
        default:
          colAdjustWidth = Math.trunc(column.width / 3.8);
          break;
      }
    } else if (column.name == 'activityNameOnPanel') {
      textAdjustWidth = getWidthOfText(showedText);
    }

    if (textAdjustWidth > colAdjustWidth) {
      if (column.name == 'activityNameOnPanel') {
        const letterWidth = textAdjustWidth / text.length;
        showedText =
          text.slice(0, Math.trunc(colAdjustWidth / letterWidth / 1.15)) +
          '...';
      } else {
        showedText = text.slice(0, Math.trunc(colAdjustWidth / 2)) + '...';
      }
      tooltip = true;
    }

    return tooltip
      ? `
            ${TooltipHTML({
              text: text,
              container: showedText,
              task_id: id
            })}`
      : showedText;
  }
  return text;
};

export const splitTextDescription = (task, column, t) => {
  let text = task[column.name];
  if (typeof text === 'string') {
    if (text === '') {
      return <div className="input-description">{t('input_description')}</div>;
    }
    let showedText = text;
    let tooltip;
    const colAdjustWidth = Math.trunc(column.width / 5);
    const textAdjustWidth = showedText.length * 2;
    if (textAdjustWidth > colAdjustWidth) {
      showedText =
        text.slice(0, Math.trunc(colAdjustWidth - colAdjustWidth * 0.3)) +
        '...';
      tooltip = true;
    }
    text = task[column.name]
      ? task[column.name].split('\n').map((item, key) => (
          <span key={key}>
            {item}
            <br />
          </span>
        ))
      : text;
    return tooltip ? (
      <Tooltip placement="top" title={text}>
        <div style={{ width: column.width || '100%', paddingLeft: '7%' }}>
          {showedText}
        </div>
      </Tooltip>
    ) : (
      <div style={{ width: column.width || '100%', paddingLeft: '7%' }}>
        {task[column.name]}
      </div>
    );
  }
  return (
    text || (
      <div
        className="input-description"
        style={{ opacity: 0, width: column.width || '100%' }}>
        {t('input_description')}
      </div>
    )
  );
};

export const replacePlainTextToLinksPattern =
  /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;

export const replacePlainTextToLinksTemplate = (match) => {
  if (match.includes('@')) {
    return `<a href="mailto:${match}" target="_blank">${match}</a>`;
  }

  if (!match.includes('http://') && !match.includes('https://')) {
    return `<a href="https://${match}" target="_blank">${match}</a>`;
  }

  return `<a href="${match}" target="_blank">${match}</a>`;
};

export const splitTextDescriptionHTML = (task, column, t) => {
  if (!column || !task)
    return `<div class="input-description">${t('input_description')}</div>>`;

  let text = task[column.name];

  if (typeof text === 'string' && text !== '') {
    let showedText = text;

    if (!showedText) {
      return `<div class="input-description">${t('input_description')}</div>`;
    }

    let tooltip;
    const colAdjustWidth = Math.trunc(column.width / 5);
    const textAdjustWidth = showedText.length * 2;

    if (textAdjustWidth > colAdjustWidth) {
      showedText =
        text.slice(0, Math.trunc(colAdjustWidth - colAdjustWidth * 0.3)) +
        '...';
      tooltip = true;
    }

    text = task[column.name]
      ? task[column.name].split('\n').map(
          (item) => `<span>
                ${item}
                <br />
              </span>`
        )
      : text;
    return tooltip
      ? TooltipHTML({
          text: text,
          container: showedText,
          task_id: task.id
        })
      : `<div style="width: ${column.width || '100%'}; padding-left: 7%;">
                  ${task[column.name]}
              </div>`;
  }
  return (
    text ||
    `<div
            class="input-description"
            style="opacity: 0; width: ${column.width || '100%'};"
          >
            ${t('input_description')}
          </div>`
  );
};

/**
 * This function calculates an expected about it start, end and actual date
 * @param {*} task Task to be checeked the expected
 * @param {*} ganttAPI gantt custom API instance to calculate expected
 * @param {*} calendar_id calendar id rules to be implemented in the process
 * @param {*} gantt gantt masterplan or any other which use a custom gantt instance
 * @returns returns an % number which represetns the expected
 */

export const calculateExpected = (
  task,
  ganttAPI,
  calendar_id = null,
  gantt = null,
  base = false
) => {
  let init;
  const today = new Date();
  let date_actual_tmp;
  let end;
  const dateFormatStr = 'YYYY/MM/DD H:mm';
  if (base) {
    if (task.base_start_date !== null) {
      if (task.base_start_date.toISOString) {
        init = moment(task.base_start_date.toString()).format(dateFormatStr);
        date_actual_tmp = moment(today.toString()).format(dateFormatStr);
        end = moment(task.base_end_date.toString()).format(dateFormatStr);
      } else {
        init = moment(task.base_start_date).format(dateFormatStr);
        date_actual_tmp = moment().format(dateFormatStr);
        end = moment(task.base_end_date).format(dateFormatStr);
      }
    }
  } else {
    if (task.start_date.toISOString) {
      init = moment(task.start_date.toString()).format(dateFormatStr);
      date_actual_tmp = moment(today.toString()).format(dateFormatStr);
      end = moment(task.end_date.toString()).format(dateFormatStr);
    } else {
      init = moment(task.start_date).format(dateFormatStr);
      date_actual_tmp = moment().format(dateFormatStr);
      end = moment(task.end_date).format(dateFormatStr);
    }
  }

  /** fix date actual after format */
  const date_actual = new Date(date_actual_tmp);
  if (gantt) {
    /** compare with end of day, in the masterplan */
    date_actual.setHours(23);
    date_actual.setMinutes(59);
  }

  /** Hour integration with gantt API */
  let actual;
  let final;
  if (gantt) {
    actual = ganttAPI.calculateDuration(init, date_actual, calendar_id, gantt);
    final = ganttAPI.calculateDuration(init, end, calendar_id, gantt);
  } else {
    actual = ganttAPI.calculateDuration(init, date_actual, calendar_id);
    final = ganttAPI.calculateDuration(init, end, calendar_id);
  }

  let expected = (actual * 100) / final; // task.duration;
  if (new Date(init).getTime() > new Date(date_actual).getTime()) {
    expected = 0;
  } else if (new Date(end).getTime() < new Date(date_actual).getTime()) {
    expected = 100;
  } else if (new Date(init).getTime() == new Date(end).getTime()) {
    expected = 100;
  }
  return expected;
};

/**
 * This function return expected week for a especific task
 * @param {*} task task object
 * @param {*} ganttAPI api gantt
 * @param {*} calendar_id calendar id from activity
 * @param {*} gantt gantt reference. if it is null, use default calendar
 * @param {*} dateRange
 * @returns
 */
export const calculateExpectedForWeek = (
  task,
  ganttAPI,
  calendar_id = null,
  gantt = null,
  dateRange
) => {
  let init;
  const today = new Date(dateRange.end);
  today.setHours(23);
  today.setMinutes(59);
  let date_actual;
  let end;
  if (task.start_date.toISOString) {
    init = moment(task.start_date.toString()).format('YYYY/MM/DD H:mm');
    date_actual = moment(today.toString()).format('YYYY/MM/DD H:mm');
    end = moment(task.end_date.toString()).format('YYYY/MM/DD H:mm');
  } else {
    init = moment(task.start_date).format('YYYY/MM/DD H:mm');
    date_actual = moment(today.toString()).format('YYYY/MM/DD H:mm');
    end = moment(task.end_date).format('YYYY/MM/DD H:mm');
  }

  /** Hour integration with gantt API */
  let actual;
  if (gantt) {
    actual = ganttAPI.calculateDuration(init, date_actual, calendar_id, gantt);
  } else {
    actual = ganttAPI.calculateDuration(init, date_actual, calendar_id);
  }
  actual = transformHourToDays(actual);
  let expected = (actual * 100) / task.duration;

  if (new Date(init).getTime() > new Date(date_actual).getTime()) {
    expected = 0;
  } else if (new Date(end).getTime() < new Date(date_actual).getTime()) {
    expected = 100;
  } else if (new Date(init).getTime() == new Date(end).getTime()) {
    expected = 100;
  }
  return expected;
  // return <span className="vertical-center">{expected.toFixed(2)}%</span>;
};

export const calculateExpectedForWeekByRangeLast = (
  task,
  ganttAPI,
  calendar_id = null,
  gantt = null,
  dateRange
) => {
  let init;
  const today = new Date(dateRange.end);
  today.setHours(23);
  today.setMinutes(59);
  let date_actual;
  let end;
  if (task.start_date.toISOString) {
    init = moment(task.start_date.toString()).format('YYYY/MM/DD H:mm');
    date_actual = moment(today.toString())
      .subtract(7, 'days')
      .format('YYYY/MM/DD H:mm');
    end = moment(task.end_date.toString()).format('YYYY/MM/DD H:mm');
  } else {
    init = moment(task.start_date).format('YYYY/MM/DD H:mm');
    date_actual = moment(today.toString())
      .subtract(7, 'days')
      .format('YYYY/MM/DD H:mm');
    end = moment(task.end_date).format('YYYY/MM/DD H:mm');
  }

  /** Hour integration with gantt API */
  let actual;
  if (gantt) {
    actual = ganttAPI.calculateDuration(init, date_actual, calendar_id, gantt);
  } else {
    actual = ganttAPI.calculateDuration(init, date_actual, calendar_id);
  }
  actual = transformHourToDays(actual);
  let expected = (actual * 100) / task.duration;

  if (new Date(init).getTime() > new Date(date_actual).getTime()) {
    expected = 0;
  } else if (new Date(end).getTime() < new Date(date_actual).getTime()) {
    expected = 100;
  } else if (new Date(init).getTime() == new Date(end).getTime()) {
    expected = 100;
  }
  return expected;
  // return <span className="vertical-center">{expected.toFixed(2)}%</span>;
};

export const calculateExpectedCost = (task, ganttAPI, calendar_id = null) => {
  let init;
  const today = new Date();
  let date_actual;
  let end;
  if (task.start_date.toISOString) {
    init = moment(task.start_date.toString()).format('YYYY/MM/DD H:mm');
    date_actual = moment(today.toString()).format('YYYY/MM/DD H:mm');
    end = moment(task.end_date.toString()).format('YYYY/MM/DD H:mm');
  } else {
    init = moment(task.start_date).format('YYYY/MM/DD H:mm');
    date_actual = moment(today.toString()).format('YYYY/MM/DD H:mm');
    end = moment(task.end_date).format('YYYY/MM/DD H:mm');
  }

  /** Hour integration with gantt API */
  let actual;
  actual = ganttAPI.calculateDuration(init, date_actual, calendar_id);
  actual = transformHourToDays(actual);

  let expected = (actual * 100) / task.duration;
  if (new Date(init).getTime() > new Date(date_actual).getTime()) {
    expected = 0;
  } else if (new Date(end).getTime() < new Date(date_actual).getTime()) {
    expected = 100;
  } else if (new Date(init).getTime() == new Date(end).getTime()) {
    expected = 100;
  }

  const cost = task.cost * (expected / 100);
  return cost;
};

/**
 * This function calculates a recursive sum, from a tree structure from the parent to their children.
 * @param {*} parent Task parent to get recursive value
 * @param {*} attributeToIterate Attribute from teh object to Sum
 * @param {*} updateRenderFunction Function to render virtual dom if necessary
 */
export const getRecursiveFromParentTask = (
  parent,
  attributeToIterate,
  updateRenderFunction,
  updateAsyncTask = taskService.update
) => {
  const originalValue = JSON.stringify(parent[attributeToIterate]);
  let total = 0;
  parent.children.map((el) => {
    total += Number(el[attributeToIterate]);
  });
  parent[attributeToIterate] = total;

  if (JSON.stringify(total) != originalValue) {
    updateAsyncTask(parent);
    updateRenderFunction();
  }
};

export const diffTableConfigColumns = (tableSaved, defaultTable) => {
  const compare = (arrayToCompare) => (current) =>
    arrayToCompare.filter((toCompare) => toCompare.name === current.name)
      .length === 0;

  const onlyInFirst = tableSaved.filter(compare(defaultTable));
  const onlyInSecond = defaultTable.filter(compare(tableSaved));

  return onlyInFirst.concat(onlyInSecond);
};

export const formatMoney = (
  amount,
  symbol_pre = '$',
  decimalCount = 2,
  decimal = ',',
  thousands = '.'
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? ' -' : ' ';
    // if (amount === 0) symbol_pre = ''
    symbol_pre = symbol_pre != '' ? MoneySymbolString() : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      symbol_pre +
      negativeSign +
      (j ? i.substring(0, j) + thousands : '') +
      i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
};

export const formatMoneyV2 = (
  amount,
  symbol_pre = '$',
  decimalCount = 2,
  decimal = ',',
  thousands = '.'
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    symbol_pre = symbol_pre != '' ? MoneySymbolString() : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      symbol_pre +
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
};

export const createActivityModificationRequest = async (
  task,
  activity,
  t,
  modalModification,
  setModalModification,
  activityModificationService,
  dispatch,
  projectState,
  userActions
) => {
  const loggedUser = getSignedUser();
  const projectId = projectState.projectSelected;
  const sectorId = projectState.sectorSelected;
  const link = base.front + 'masterplan/' + sectorId + '/' + projectId;

  const activityModification = {
    type: task.unfitType,
    state: 'waiting',
    description: modalModification.description,
    activityId: activity.id,
    userRequestId: loggedUser.id,
    startOriginal: activity.start_date,
    endOriginal: activity.end_date,
    user: loggedUser,
    projectId,
    sectorId,
    link
  };

  if (task.unfitType == 'start') {
    activityModification.startNew = task.start_date;
    activityModification.endNew = activity.end_date;
  } else if (task.unfitType == 'end') {
    activityModification.startNew = activity.start_date;
    activityModification.endNew = task.end_date;
  } else if (task.unfitType == 'both') {
    activityModification.startNew = task.start_date;
    activityModification.endNew = task.end_date;
  }

  const res = await activityModificationService.create(activityModification);
  dispatch(userActions.setUserUpdate());
  if (res) {
    openNotification({
      type: 'success',
      title: t('success_request_notify')
    });
    setModalModification({
      ...modalModification,
      visible: false,
      description: ''
    });
  }
};

export const updateTaskNameWidthColumn = (width, columns, idx = 0) => {
  if (columns.length <= idx) {
    return columns;
  }

  if (columns[idx].name == 'name') {
    columns[idx].width = width;
    return columns;
  }

  return updateTaskNameWidthColumn(width, columns, idx + 1);
};

export const calculateTaskColumnSize = (configTable, columnsWidth, width) => {
  const sideNavWith = 200;
  const widthContainer = width - sideNavWith;
  const taskColumn = configTable
    .filter((column) => column.name == 'name')
    .pop();

  let columnTaskNameWidth =
    columnsWidth >= widthContainer
      ? widthContainer * 0.3
      : widthContainer - columnsWidth;
  columnTaskNameWidth =
    columnTaskNameWidth <= widthContainer * 0.3
      ? widthContainer * 0.3
      : columnTaskNameWidth;

  return columnTaskNameWidth;
};

export const resizeNameTaskColumn = (configTable, width) => {
  const totalColumnsWidthReduce = (acc, column) => {
    acc += column.width;
    return acc;
  };

  const columnsWidth = configTable
    .filter((column) => column.visible && !column.ignore_as_column)
    .reduce(totalColumnsWidthReduce, 0);

  const columnTaskNameWidth = calculateTaskColumnSize(
    configTable,
    columnsWidth,
    width
  );

  return updateTaskNameWidthColumn(columnTaskNameWidth, configTable);
};

const adjustActivatedColumns = ({
  activatedColumns = [],
  configTable = [],
  columnIgnored = 'name',
  widthScreen = 900,
  columnResized = null,
  totalColumnsWidthReduce = (_) => {}
}) => {
  const totalColumnsSize = configTable
    .filter((column) => activatedColumns.includes(column.name))
    .reduce(totalColumnsWidthReduce, 0);

  widthScreen = widthScreen - 200;

  if (totalColumnsSize < widthScreen) {
    const columnResizedWidth = columnResized ? columnResized.width : 0;
    const columnIgnoredSizes =
      configTable.find((column) => column.name == columnIgnored)?.width || 0;
    const activated = columnResized
      ? activatedColumns.length - 2
      : activatedColumns.length - 1;
    const columnSizes =
      (widthScreen - columnIgnoredSizes - columnResizedWidth) / activated;

    if (columnSizes < 100) {
      return configTable;
    }

    return configTable.map((column) => {
      if (
        activatedColumns.includes(column.name) &&
        column.name != columnResized.name &&
        !column.ignore_as_column
      ) {
        column.width = columnSizes;
      }

      column.visible = activatedColumns.includes(column.name);

      return column;
    });
  }

  return configTable.map((column) => {
    column.visible = activatedColumns.includes(column.name);
    return column;
  });
};

export const adjustColumnsSize = ({
  configTable = [],
  widthScreen = 900,
  columnIgnored = 'name',
  columnResized = null,
  activatedColumns = []
}) => {
  const totalColumnsWidthReduce = (acc, column) => {
    if (!columnIgnored) return;
    column.name != columnIgnored && (acc += column.width);
    return acc;
  };

  if (activatedColumns.length) {
    return adjustActivatedColumns({
      configTable,
      widthScreen,
      activatedColumns,
      columnResized,
      totalColumnsWidthReduce
    });
  }

  const visibleColumns = configTable.filter(
    (column) => column.visible && !column.ignore_as_column
  );
  const totalColumnsSize = visibleColumns.reduce(totalColumnsWidthReduce, 0);

  widthScreen = widthScreen - 200;

  if (totalColumnsSize < widthScreen) {
    const dateColumnsSizes = 200;
    const columnIgnoredSizes =
      configTable.find((column) => column.name == columnIgnored)?.width || 0;
    const columnResizedWidth = columnResized ? columnResized.width : 0;
    const totalVisibleColumns =
      widthScreen > 1200 ? visibleColumns.length - 2 : visibleColumns.length;
    const calculateFixedColumns =
      widthScreen > 1200
        ? widthScreen -
          columnIgnoredSizes -
          columnResizedWidth -
          dateColumnsSizes * 2
        : widthScreen - columnIgnoredSizes - columnResizedWidth;

    const columnSizes = calculateFixedColumns / totalVisibleColumns;

    if (columnSizes < 100) {
      return configTable;
    }

    return configTable.map((column) => {
      if (column.visible && !column.ignore_as_column) {
        column.width = columnSizes;
      }

      if (
        widthScreen > 1200 &&
        column.name == 'commitmentDate' &&
        column.name == 'constraintTypeId'
      ) {
        column.width = dateColumnsSizes;
      }

      return column;
    });
  }

  return configTable;
};

export const changeColumnPosition = (tableConfig, columnToMove, position) => {
  const idx = tableConfig.findIndex((col) => col.name == columnToMove.name);
  tableConfig.splice(idx, 1);
  tableConfig.splice(position, 0, columnToMove);
  return tableConfig;
};

export const handleLeanStatusColPosition = (
  tableConfig,
  isChangeColVisibility,
  leanStatusColVisibility
) => {
  if (!isChangeColVisibility) {
    return tableConfig;
  }

  const leanStatusColumn = tableConfig.find(
    (column) => column.name == 'lean_status'
  );
  leanStatusColVisibility.current =
    leanStatusColVisibility.current ?? leanStatusColumn.visible;

  if (leanStatusColumn.visible == leanStatusColVisibility.current) {
    return tableConfig;
  }

  leanStatusColVisibility.current = leanStatusColumn.visible;

  const columnVisibles = tableConfig.filter((column) => column.visible);
  const colIdx = Math.floor(columnVisibles.length / 2) + 3;

  return changeColumnPosition(tableConfig, leanStatusColumn, colIdx);
};
export const leanStatusArray = [
  {
    color: '#E50101',
    value: 'Restricted',
    label: 'Restringida',
    description: 'LEAN debit status means...',
    weigth: 4
  },
  {
    color: '#121212',
    value: 'Debit',
    label: 'Debe',
    description: 'LEAN debit status means...',
    weigth: 3
  },
  {
    color: '#34AF00',
    value: 'Can',
    label: 'Puede',
    description: 'LEAN can status means...',
    weigth: 2
  },
  {
    color: '#309FE9',
    value: 'Will',
    label: 'Plan Semanal',
    description: 'LEAN debit status means...',
    weigth: 1
  },
  {
    color: '#F59D04',
    value: 'Committed',
    label: 'Comprometida',
    description: 'LEAN debit status means...',
    weigth: 0
  }
];

export const statusArray = [
  {
    icon: 'fas fa-clock',
    customIcon: blueStatus,
    color: 'yellow',
    value: 'Advancement',
    label: 'Adelantada',
    weigth: 4
  },
  {
    icon: 'fas fa-clock',
    customIcon: redStatus,
    color: 'yellow',
    value: 'Overdue',
    label: 'Atrasada',
    weigth: 3
  },
  {
    icon: 'fas fa-clock',
    customIcon: orangeStatus,
    color: 'yellow',
    value: 'Doing',
    label: 'En desarrollo',
    weigth: 2
  },
  {
    icon: 'far fa-check-circle',
    customIcon: greenStatus,
    color: 'green',
    value: 'Done',
    label: 'Finalizada',
    weigth: 1
  },
  {
    icon: 'fas fa-minus-circle',
    customIcon: greyStatus,
    color: 'grey',
    value: 'Waiting',
    label: 'No Iniciada',
    weigth: 0
  }
];

export const priorityArray = [
  {
    icon: flagGrey,
    color: 'lightgrey',
    value: 'Low',
    label: 'Baja',
    weigth: 3
  },
  {
    icon: flagBlue,
    color: 'darkturquoise',
    value: 'Normal',
    label: 'Normal',
    weigth: 2
  },
  {
    icon: flagYellow,
    color: 'orange',
    value: 'High',
    label: 'Alta',
    weigth: 1
  },
  {
    icon: flagRed,
    color: 'red',
    value: 'Urgent',
    label: 'Urgente',
    weigth: 0
  }
];

/**
 * This function give us the modified object of tasks. This object is used in the activity Card for map activity and tasks data.
 * @param {*} arrTasks Array of task to modify
 * @param {*} progress Progress to update in the especified task
 * @param {*} task task to update
 * @param {*} type is the type of action as we want to modify activity or task
 * @returns modified object with updated task progress
 */
export const getTaskstoModifyProgress = (
  arrTasks,
  progress,
  task,
  type = 'activity'
) => {
  let ret = {};
  if (type === 'activity') {
    /** update tasks in activity object */
    const tasksToModifyInActivity = arrTasks;
    const findIndex = tasksToModifyInActivity.findIndex(
      (el) => el.id === task.id
    );
    tasksToModifyInActivity[findIndex] = {
      ...tasksToModifyInActivity[findIndex],
      progress: progress
    };
    ret = tasksToModifyInActivity;
  } else if (type === 'task') {
    /** update tasks in tasks array */
    const tasksToModifyInArrayTasks = arrTasks;
    const findIndex = tasksToModifyInArrayTasks.findIndex(
      (el) => el.id === task.id
    );
    tasksToModifyInArrayTasks[findIndex] = {
      ...tasksToModifyInArrayTasks[findIndex],
      originalTaskObject: {
        ...tasksToModifyInArrayTasks[findIndex].originalTaskObject,
        progress: progress
      }
    };
    ret = tasksToModifyInArrayTasks;
  }
  return ret;
};
/**
 * This function calculate the expected_cost, commitment_percentaje, expectedweek and quantity_parcial_percentaje about new weekly lookahead flow
 * @param {*} taskRef Task in pu
 */
export const calculateCommitmentPercentaje = (taskRef, dateRange) => {
  if (!taskRef.customCommitmentPercentaje) {
    const task = taskRef;
    const findActivity = task.activity;
    task.expected_cost = calculateExpectedCost(
      task,
      ganttAPI,
      findActivity.calendarId
    );
    try {
      const expWeek = calculateExpectedForWeek(
        task,
        ganttAPI,
        findActivity.calendarId,
        null,
        dateRange
      );
      task.commitment_percentaje = parseFloat(expWeek).toFixed(2);
      task.expectedweek = parseFloat(expWeek).toFixed(2);

      if (task.commitment_percentaje < task.progress) {
        let quantity_parcial = 0;
        let newCompromise = task.progress;
        if (task.total_quantity) {
          newCompromise =
            task.progress + (quantity_parcial * 100) / task.total_quantity;
        }
        task.commitment_percentaje = newCompromise;
        quantity_parcial = 0;
      }
      const quantity_parcial_percentaje = parseFloat(
        parseFloat(task.commitment_percentaje || 0) - parseFloat(task.progress)
      );
      task.quantity_parcial_percentaje = quantity_parcial_percentaje;
    } catch (e) {
      console.log(e);
    }
  }
};

/**
 * This function initializes the commitment value, to the expected weekly
 * @param {*} arrayData Array of lastlevelactivities (tree mode)
 * @returns return Array of lastlevelactivities (tree mode), with commitment_percentaje, initialized
 */
export const initialStatus = (startMarker, finalEndMarker) => (arrayData) => {
  const dateRange = {
    start: startMarker,
    end: finalEndMarker
  };
  return assignInitialCommitmentPercentaje(arrayData, dateRange);
};

/**
 * This function check marks to a given object range weekly data
 * @param {*} planificationDay Planification from project
 * @param {*} objectRangeData From weekly to check
 * @param {*} hasCustomHours object {startHour, endHour} will be setted to the start and end given
 * @returns {start, end, duration} Object which represent a task along the weekly range
 */
export const checkMarkersByDates = (
  planificationDay,
  objectRangeData,
  hasCustomHours = false
) => {
  const initHelper = new Date();
  const currentDay = initHelper.getDay();
  const diffToCurrent = currentDay - planificationDay;
  if (diffToCurrent !== 0) {
    /** diffToCurrent is diff to current date, which allows to always stand up at first day of current week to create task with calendar refs */
    initHelper.setDate(initHelper.getDate() - diffToCurrent);
  }

  const startMarker = new Date(objectRangeData.start);
  const finalEndMarker = new Date(objectRangeData.end);

  if (Object.keys(hasCustomHours).length !== 0) {
    const startHourArr = hasCustomHours.startHour.split(':');
    const startHour = parseInt(startHourArr[0]);

    const endHourArr = hasCustomHours.endHour.split(':');
    const endHour = parseInt(endHourArr[0]);

    if (!isNaN(startHour)) {
      startMarker.setHours(startHour);
      startMarker.setMinutes(0);
      startMarker.setSeconds(0);
    }

    if (!isNaN(endHour)) {
      finalEndMarker.setHours(endHour);
      finalEndMarker.setMinutes(0);
      finalEndMarker.setSeconds(0);
    }

    if (objectRangeData) {
      objectRangeData.startWithHour = startMarker;
      objectRangeData.endWithHour = new Date(finalEndMarker);
    }
  }
  return {
    startMarker,
    finalEndMarker
  };
};

export const updateTaskWeekly = (task) => {
  /** quantity_parcial */
  let quantity_parcial =
    ((task.commitment_percentaje - task.progress) * task.total_quantity) / 100;
  if (task.commitment_percentaje < task.progress) {
    quantity_parcial = 0;
  }
  task.quantity_parcial = quantity_parcial;

  /** quantity_parcial_percentaje */
  const quantity_parcial_percentaje = parseFloat(
    parseFloat(task.commitment_percentaje || 0) - parseFloat(task.progress)
  );
  task.quantity_parcial_percentaje = quantity_parcial_percentaje;

  let newCommit;

  if (task.total_quantity) {
    newCommit =
      (100 * task.quantity_parcial) / task.total_quantity + task.progress;
    if (parseFloat(task.quantity_parcial_percentaje) < 0) {
      task.quantity_parcial_percentaje = 0;
    }
  } else {
    if (parseFloat(task.quantity_parcial_percentaje) > 0) {
      newCommit = task.quantity_parcial_percentaje + task.progress;
    } else {
      newCommit = task.progress;
    }
  }
  if (parseFloat(newCommit) > 100) {
    newCommit = 100;
    task.quantity_parcial = task.remaining_quantity;
  }
  task.commitment_percentaje = newCommit;
};

export const saveForwardWeeklyData = async (currentWeek, nullify = false) => {
  const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
  // console.log('We are on week')
  const finalData = { ...currentSector };
  /** NOTE: We validate,
   * that user only can go forward one week, so then in this code below,
   * we know where user did already close a week, but with a cron,
   * we will be checking if the current week reach the last closed week, then if their match,
   * this two flags will be reseted */
  finalData.currentClosedWeek = currentWeek;
  finalData.didCloseWeek = true;

  if (nullify) {
    finalData.currentClosedWeek = null;
    finalData.didCloseWeek = false;
  }
  /** Data should be saved */
  await sectorService.update(finalData);
  sessionStorage.setItem('currentSector', JSON.stringify(finalData));
};

/**
 * This function nests tasks based on their parent-child relationships.
 *
 * @param {Array} tasks - The list of tasks to be nested. Each task should be an object.
 * @param {number} tasks[].id - The unique identifier of the task.
 * @param {number|null} tasks[].parent_id - The parent task's identifier. If null, the task has no parent.
 * @returns {Array} - The nested list of tasks, where each task contains a 'children' property with its nested children.
 */
export const nestTasks = (tasks) => {
  const taskMap = {};
  tasks.forEach((task) => {
    task.children = [];
    taskMap[task.id] = task;
  });

  const nestedTasks = [];

  tasks.forEach((task) => {
    if (task.parent_id === null) {
      nestedTasks.push(task);
    } else if (taskMap[task.parent_id]) {
      taskMap[task.parent_id].children.push(task);
    }
  });

  return nestedTasks;
};
