import { debounce } from 'lodash';
import { defineColorByCriticalPath } from './defineColorByCriticalPath';
import { defineColorByStatus } from './defineColorByStatus';
import { defineColorBySubcontract } from './defineColorBySubcontract';
import { defineColorByTags } from './defineColorByTags';
import { calculateExpected } from '../../../../utils/lookahead-common';
import { ganttAPI } from '../../../../utils/customGanttPlugin';
import { transformHourToDays } from '../../../../views/ganttContainer/gantt/gantt.helper';

function updateExpectedGlobal(gantt = false) {
  if (!gantt) {
    gantt = window.to_use_react_gantt;
  }

  const masterplan = gantt.getTaskBy(
    (t) => t.parent === 0 || t.parent === '0'
  )[0];
  const activity = masterplan;
  debouncedRecursiveFunction(activity);
}

const debouncedRecursiveFunction = debounce((activity) => {
  const gantt = window.to_use_react_gantt;
  if (!gantt) {
    return;
  }
  recursiveFunction(activity);
  updateGlobal(gantt);
  gantt.render();
  function recursiveFunction(activity) {
    const childs = gantt.getChildren(activity.id);
    let projectExpectedBase = 0;
    let projectExpectedFollowUp = 0;
    let costAcum = 0;
    let workAcum = 0;

    childs.forEach((child) => {
      /** Get object from Gantt Library */
      if (!gantt.isTaskExists(child)) return '';
      const childActivity = gantt.getTask(child);
      const copy = { ...childActivity };

      if (childActivity.type == 'project') {
        const { real_cost, expected_progress, expected_progress_base } =
          recursiveFunction(childActivity);
        costAcum += real_cost;
        projectExpectedFollowUp +=
          expected_progress * (childActivity.ponderator || 0);
        projectExpectedBase +=
          expected_progress_base * (childActivity.ponderator || 0);
      } else {
        /**  We find the active baseline version through the baseline point */
        if (childActivity.baseline_points) {
          const activeBaseline2 = childActivity.baseline_points.find((base) => {
            if (base.sectorbaselineversion) {
              if (base.sectorbaselineversion.active) {
                return true;
              }
            }
          });

          /** We assign it */
          const last_baseline2 = activeBaseline2;
          if (last_baseline2 && last_baseline2.baseCalendarId) {
            /** Calculate the expected child */
            const childExpected = calculateExpected(
              last_baseline2,
              ganttAPI,
              last_baseline2.baseCalendarId + '-base',
              gantt
            );
            childActivity.expected_progress_base = childExpected;
            projectExpectedBase =
              projectExpectedBase +
              childActivity.expected_progress_base *
                (childActivity.ponderator || 0);
          } else if (last_baseline2 && gantt.defaultBaseCalendar) {
            /** Calculate the expected child */
            const childExpected = calculateExpected(
              last_baseline2,
              ganttAPI,
              gantt.defaultBaseCalendar,
              gantt
            );
            childActivity.expected_progress_base = childExpected;
            projectExpectedBase =
              projectExpectedBase +
              childActivity.expected_progress_base *
                (childActivity.ponderator || 0);
          }

          if (last_baseline2) {
            /** REAL COST REFACTOR */
            childActivity.real_cost =
              (childActivity.progress / 100) * last_baseline2.cost;
          } else {
            childActivity.real_cost = 0;
          }
        } else {
          childActivity.real_cost = 0;
        }
        let totalWork = 0;
        if (childActivity.tasks && childActivity.tasks.length) {
          totalWork = childActivity.tasks
            .map((el) => el.won_hh || 0)
            .reduce((x, y) => x + y);
        }

        copy.duration = transformHourToDays(copy.duration);
        childActivity.expected_progress = calculateExpected(
          copy,
          ganttAPI,
          copy.calendar_id,
          gantt
        );
        projectExpectedFollowUp =
          projectExpectedFollowUp +
          childActivity.expected_progress * (childActivity.ponderator || 0);
        costAcum += childActivity.real_cost;
        workAcum += childActivity.real_work;
      }
    });
    activity.real_cost = costAcum;
    activity.expected_progress = projectExpectedFollowUp / 100;
    activity.expected_progress_base = projectExpectedBase / 100;

    return {
      real_cost: activity.real_cost,
      expected_progress: activity.expected_progress,
      expected_progress_base: activity.expected_progress_base
    };
  }
}, 50);

function updateGlobal(gantt) {
  gantt.getTaskByTime().forEach((task) => {
    const expected_calculated =
      gantt.status_criteria === 'Baseline'
        ? task.expected_progress_base
        : task.expected_progress;
    if (Math.round(task.progress * 100) / 100 > 99.99) {
      task.status = 'Done';
    } else if (
      Math.round(expected_calculated * 100) / 100 >
      Math.round(task.progress * 100) / 100
    ) {
      task.status = 'Overdue';
    } else if (
      task.progress > 0 &&
      Math.round(task.progress * 100) / 100 >
        Math.round(expected_calculated * 100) / 100
    ) {
      task.status = 'Advancement';
    } else if (task.progress == 0 && expected_calculated == 0) {
      task.status = 'Waiting';
    } else if (
      task.progress > 0 &&
      Math.round(task.progress * 100) / 100 <
        Math.round(expected_calculated * 100) / 100
    ) {
      task.status = 'Doing';
    } else {
      task.status = 'Waiting';
    }

    if (gantt.visualizationColorActive == 'status') {
      defineColorByStatus(task);
    } else if (gantt.visualizationColorActive == 'subcontract') {
      defineColorBySubcontract(task);
    } else if (gantt.visualizationColorActive == 'tags') {
      defineColorByTags(task);
    } else if (gantt.visualizationColorActive == 'criticalpath') {
      defineColorByCriticalPath(task);
    }
  });
}

export { updateExpectedGlobal };
