const localHost = 'localhost'
export const getEnvironment = (hardcoded = false) => {
    if (hardcoded) {
        return hardcoded
    }
    const hostName = window.location.hostname
    if (hostName && hostName.includes('new.proplanner.app')) {
        return 'prod'
    } else if (hostName && hostName.includes('proplanner.app')) {
        return 'prod2'
    } else if (hostName && hostName.includes('app.outbuild.com')) {
        return 'prod2'
    } else {
        return 'prod2' // 'local', acá se cambia a 'beta' o 'prod2' !!!
    }
}
const getUrl = (service) => {
    const environment = getEnvironment()
    if (service == 'apianalytics') {
        switch (environment) {
        case 'prod':
            return 'https://prod_v2_backend_analitic.proplanner.app/api/'
        case 'prod2':
            return 'https://prod_v2_backend_analitic.proplanner.app/api/'
        case 'local':
            return 'http://' + localHost + ':3030/api/'
        }
    } else if (service == 'apitakt') {
        switch (environment) {
        case 'prod':
            return 'https://prod_v2_backend_takt.proplanner.app/api/'
        case 'prod2':
            return 'https://prod_v2_backend_takt.proplanner.app/api/'
        case 'local':
            return 'http://' + localHost + ':3030/api/'
        }
    } else if (service == 'api') {
        switch (environment) {
        case 'prod':
            return 'https://prod_v2_backend.proplanner.app/api/'
        case 'prod2':
            return 'https://prod_v2_backend.proplanner.app/api/'
        case 'local':
            return 'http://' + localHost + ':3030/api/'
        }
    } else if (service == 'base') {
        switch (environment) {
        case 'prod':
            return 'https://prod_v2_backend.proplanner.app/'
        case 'prod2':
            return 'https://prod_v2_backend.proplanner.app/'
        case 'local':
            return 'http://' + localHost + ':3030/'
        }
    } else if (service == 'front') {
        switch (environment) {
        case 'prod':
            return 'https://new.proplanner.app/'
        case 'prod2':
            return 'https://app.outbuild.com/'
        case 'local':
            return 'http://' + localHost + ':3000/'
        }
    } else if (service == 'socketApi') {
        switch (environment) {
        case 'prod':
            return 'https://prod_v2_sockect.proplanner.app/'
        case 'prod2':
            return 'https://prod_v2_sockect.proplanner.app/'
        case 'local':
            return 'http://' + localHost + ':8000/'
        }
    } else if (service == 'apiJava') {
        switch (environment) {
        case 'prod':
            return 'https://prod-v2-java.proplanner.app/proplanner/'
        case 'prod2':
            return 'https://prod-v2-java.proplanner.app/proplanner/'
        case 'local':
            return 'http://' + localHost + ':8080/'
        }
    } else if (service == 'exportableNode') {
        switch (environment) {
        case 'prod':
            return 'https://www.outbuildexportable.com/gantt'
        case 'prod2':
            return 'https://www.outbuildexportable.com/gantt'
        case 'local':
            return 'https://www.outbuildexportable.com/gantt' // 'http://' + localHost + ':3200/'
        }
    } else if (service == 'procoreLogin') {
        switch (environment) { // procore push schedule
        case 'prod':
            return 'https://login.procore.com'
        case 'prod2':
            return 'https://login.procore.com'
        case 'local':
            return 'https://login-sandbox.procore.com/'
        }
    } else if (service == "procoreUrl") {
    const environment = getEnvironment();
    switch (environment) {
      case "demo":
        return "https://sandbox.procore.com";
      case "beta":
        return "https://sandbox.procore.com";
      case "prod2":
        return "https://us02.procore.com";
      case "local":
        return "https://sandbox.procore.com";
    }
  } else if (service == 'backendExportable') {
        switch (environment) {
        case 'prod':
            return 'https://www.outbuildbackend.com/api/'
        case 'prod2':
            return 'https://www.outbuildbackend.com/api/'
        case 'local':
            return 'https://www.outbuildbackend.com/api/'
        }
    }
}
const getVar = (varname) => {
    const environment = getEnvironment()
    if (varname == 'procoreClientId') {
        switch (environment) {
        case 'prod':
            return '18a0a93ca7b50c7eb3a2faa380fc90bbc491cbd7c0db61cecebb80d38452ffb5'
        case 'prod2':
            return '18a0a93ca7b50c7eb3a2faa380fc90bbc491cbd7c0db61cecebb80d38452ffb5'
        case 'local':
            return '5b64314eb778f43fbd32282845fe878edd4a9fb146e82996afad2475e6fa52ea'
        }
    }
}
export const base = {
    api: getUrl('api'),
    apiTakt: getUrl('apitakt'),
    apiAnalytics: getUrl('apianalytics'),
    base: getUrl('base'),
    front: getUrl('front'),
    socketApi: getUrl('socketApi'),
    apiJava: getUrl('apiJava'),
    procoreLogin: getUrl('procoreLogin'),
    procoreUrl: getUrl("procoreUrl"),    
    procoreClientId: getVar('procoreClientId'),
    exportableUrl: getUrl('exportableNode'),
    backendExportable: getUrl('backendExportable'),
    returnEnviroment: getEnvironment(),
    version: '2.60.23'
}
