import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, Menu, Icon, Empty } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import SingleOrderRow from '../SingleOrderRow';
import update from 'immutability-helper';
import { updateDefaultView } from '../../services/views/viewSync';
import './index.css';
const mapElements = {};

export const testIds = {
  RENDER_GANTT_FILTER_ORDER: 'RENDER_GANTT_FILTER_ORDER'
};
const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_GANTT_FILTER_ORDER}>{children}</span>
);

export default function GanttOrder(props) {
  const { t, showedFilters, setShowedFilters } = props;
  const [addFilterOptions, setAddFilterOptions] = useState(
    []
  ); /** Array with all options to add filters */
  const ganttState = useSelector((state) => state.ganttState);

  useEffect(() => {
    props.checkBackgrondOrder(showedFilters);
  });

  useEffect(() => {
    if (showedFilters.value.length != 0) {
      filterGanttData();
    }
  }, [ganttState.notifyChangeOrder]);

  useEffect(() => {
    const opts = (
      <Menu>
        {props.options.map((op, index) => {
          if (op.orderable) {
            return (
              <Menu.Item key={index}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => addNewFilter(op)}>
                  {op.label}
                </a>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );

    setAddFilterOptions(opts);
  }, [
    showedFilters
  ]); /** If there are DOM elements on the JSX, associated with other useState hooks, needs to be updated to fit with the same STATE */

  /**
   * This function handle add new filters to the dinamic list
   * @param {*} filter Specific filter coming from props.options
   */
  const addNewFilter = (filter) => {
    const doesExist = showedFilters.value.filter((f) => {
      if (f.name == filter.name) return true;
      return false;
    });

    if (doesExist.length == 0) {
      filter.order_by = 'asc';
      showedFilters.value.push(filter);
      updateRender();
      filterGanttData();
    }
  };

  /**
   * This function handle delete icon no single filter activated
   * @param {*} filter Specific filter coming from props.options
   */
  const deleteFilter = (filter) => {
    const n = showedFilters.value.filter((op) => {
      if (op != filter) return true;
      return false;
    });
    showedFilters.value = n;

    updateRender();
    filterGanttData();
  };

  /**
   * This function handle order_by attr comming from single filter activated
   * @param {*} e event from input
   * @param {*} filter Specific filter coming from props.options
   */
  const onChangeOrderBy = (e, filter) => {
    const { value } = e.target;
    filter.order_by = value;
    updateRender();
    filterGanttData();
  };

  /**
   * This functions allow developer to re load on force the render (virtual DOM)
   */
  const updateRender = () => {
    const a = JSON.stringify(showedFilters);
    setShowedFilters((prev) => {
      prev = JSON.parse(a);
      return prev;
    });
  };

  const filterGanttData = () => {
    if (showedFilters.value.length != 0) {
      props.gantt.isDataOrdered = true;
      let query = '';
      showedFilters.value.map((o, index) => {
        let fieldName = "'" + o.name + "'";
        const orderBy = o.order_by;
        if (o.name == 'custom_predecessors') {
          o.name = 'custom_predecessor';
        }

        if (o.data_type.includes('array/')) {
          if (
            o.data_type.includes('/string') ||
            o.data_type.includes('/icon')
          ) {
            const optionsMap = {};
            o.from_values.map((option) => {
              optionsMap[option.value] = option.weigth;
            });
            mapElements[o.name] = optionsMap;
            fieldName = `
                        (v1, v2) => {
                            let weigth_one = mapElements.${o.name}[v1.${o.name}]
                            let weigth_two = mapElements.${o.name}[v2.${o.name}]
                            return (weigth_one || 0) - (weigth_two || 0)
                        }
                        `;
          }
        }

        if (index == 0) {
          query =
            query +
            `firstBy(${fieldName}, { direction: '${orderBy}', ignoreCase: true})`;
        } else {
          query =
            query +
            `.thenBy(${fieldName}, { direction: '${orderBy}', ignoreCase: true})`;
        }
      });

      // eslint-disable-next-line no-eval
      props.gantt.sort(eval(query));
      props.gantt.toOrderByQuery = query;
      props.gantt.lastOrderByQuery = showedFilters;
    } else {
      props.gantt.isDataOrdered = false;
      props.gantt.toOrderByQuery = null;
      props.gantt.sort('correlative_id');
    }
    updateDefaultView();
  };

  const moveCard = async (dragIndex, hoverIndex) => {
    const dragCard = showedFilters.value[dragIndex];
    showedFilters.value = update(showedFilters.value, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard]
      ]
    });
    updateRender();
    filterGanttData();
  };

  const renderShowedFilters = () =>
    showedFilters.value.map((filter, index) => (
      <SingleOrderRow
        key={filter.name}
        index={index}
        id={index}
        filter={filter}
        moveCard={moveCard}
        deleteFilter={deleteFilter}
        onChangeOrderBy={onChangeOrderBy}
      />
    ));

  const checkIfFiltersAreActive = () => {
    if (showedFilters.value.length) {
      return renderShowedFilters();
    }
    return (
      <div className="non-selected-order">
        {t('master_plan.no_fields_filter_selected')}
      </div>
    );
  };

  return (
    <TestWrapped>
      <div className="order-main-container gantt-filter-left-order">
        <Row className="full-width-style">{checkIfFiltersAreActive()}</Row>
        <Row className="full-width-style">
          <Col className="switch-col-style">
            <Dropdown overlay={addFilterOptions} trigger={['click']}>
              <a
                onClick={(e) => e.preventDefault()}
                style={{ color: '#53C255', marginLeft: 3 }}>
                <Icon type="plus" style={{ color: '#53C255' }} />
                {t('master_plan.add_fields_filter')}
              </a>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </TestWrapped>
  );
}
