import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Outbuild from '../../../../assets/img/proplanner.svg';
import InputSystem from '../../../../components/DesignSystem/InputSystem';
import DropdownSystem from '../../../../components/DesignSystem/DropdownSystem';
import {
  userService,
  authService,
  companyService,
  projectService
} from '../../../../services';
import { base } from '../../../../services/base';
import { ITEMS_COMPANY_SIZE, ITEMS_COMPANY_TYPE } from '../../constants';
import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { isNotValidFormatEmail } from '../../utils';
import './index.scss';
import { httpCodes } from '../../../../constants/httpCodes';
import {
  redirectSelectCompany,
  setSessionForFreeTrial
} from '../../../login/login.helpers';

const INPUT_TYPE_TEXT = 'text';
const INPUT_TYPE_PASSWORD = 'password';
const INPUT_TYPE_PASSWORD2 = 'password2';
const REGEX_PASSWORD = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

const DEFAULT_DATA_FORM = {
  first_name: '',
  last_name: '',
  password: '',
  password2: '',
  company_name: '',
  company_size: '',
  company_type: ''
};

const ThirdScreenB = (props) => {
  const { t, userData } = props;

  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [showErrorPassword, setShowErrorPassword] = useState(false);
  const [showErrorPassword2, setShowErrorPassword2] = useState(false);
  const [dataForm, setDataForm] = useState(DEFAULT_DATA_FORM);
  const [companySizeSelected, setCompanySizeSelected] = useState();
  const [companyTypeSelected, setCompanyTypeSelected] = useState();

  useEffect(() => {
    trackingEvent('create_company_user_form_viewed', null, AMPLITUDE_SERVICE);
  }, []);

  const handleCompanySize = (item) => {
    setCompanySizeSelected(item);

    setDataForm({
      ...dataForm,
      company_size: item.label
    });
  };

  const handleCompanyType = (item) => {
    setCompanyTypeSelected(item);

    setDataForm({
      ...dataForm,
      company_type: item.label
    });
  };

  const handleInputChange = (event) => {
    setDataForm({
      ...dataForm,
      [event.target.name]: event.target.value
    });
  };

  const handleOnBlur = (type) => {
    if (type === INPUT_TYPE_PASSWORD) {
      setShowErrorPassword(!REGEX_PASSWORD.test(dataForm.password));
    }
    if (type === INPUT_TYPE_PASSWORD2) {
      if (dataForm.password !== '' && dataForm.password2 !== '') {
        setShowErrorPassword2(dataForm.password !== dataForm.password2);
      }
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingButton(true);

    try {
      if (!isNotValidFormatEmail(dataForm)) {
        const dataCompany = {
          name: userData.company
            ? `${dataForm.first_name}'s ${dataForm.company_name}`
            : dataForm.company_name,
          contract_type: 'project_based',
          country: 'US',
          company_size: dataForm.company_size,
          company_type: dataForm.company_type,
          isFreeTrial: true
        };

        const dataAuth = {
          user: userData.user.email.toLowerCase(),
          password: dataForm.password,
          link: base.front + 'confirmation/',
          lang: t('lang')
        };

        const responseAuth = await authService.index(dataAuth);
        if (responseAuth.status !== httpCodes.OK) return;

        setSessionForFreeTrial(responseAuth);

        const responseCompany = await companyService.create(dataCompany);

        if (responseCompany) {
          const dataUser = {
            id: userData.user.id,
            first_name: dataForm.first_name,
            last_name: dataForm.last_name,
            email: userData.user.email.toLowerCase(),
            password: dataForm.password,
            company_id: responseCompany.id,
            flow_free_trial_company_detected: Boolean(userData.company),
            flow_create_new_company: true,
            role: 'admin',
            isFreeTrial: true
          };

          const responseUser = await userService.updateUserFreeTrial(dataUser);

          if (responseUser.status === 200) {
            const dataProject = {
              userId: userData.user.id,
              name: dataForm.first_name,
              companyId: responseCompany.id,
              isFreeTrial: true
            };

            const responseDuplicateProject =
              await projectService.duplicateProject(dataProject);

            if (responseDuplicateProject.status === 200) {
              trackingEvent(
                'new_company_signup_form_filled',
                {
                  fields_filled:
                    dataForm.company_type === '' ? 'partial' : 'all',
                  action_taken: 'account_created',
                  company_existence_checked: Boolean(userData.company)
                },
                AMPLITUDE_SERVICE
              );

              const dataAssign = {
                projectId: responseDuplicateProject.data.projectId,
                email: dataUser.email,
                isFreeTrial: true
              };

              await projectService.assignProjectFreeTrialSuperAdmin(dataAssign);

              if (responseAuth.status === httpCodes.OK)
                redirectSelectCompany(responseAuth);
            }
          }
        }
      }
    } catch (err) {
      localStorage.removeItem('signed');
      localStorage.removeItem('firsTimeLogin');
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
      console.log(`Error in SignUpConfirmation - onSubmit() - Case B, ${err}`);
    }
  };

  return (
    <div className="signup__third-screen-b">
      <div className="third-screen-b__logo">
        <img src={Outbuild} alt="Logo Outbuild" />
      </div>
      <div className="third-screen-b__body">
        <div className="body__title">
          <h6>{t('signup.case_b.third_screen_title')}</h6>
        </div>
        <form onSubmit={onSubmit} className="body__form">
          <div className="form__inputs">
            {InputSystem({
              label: t('signup.case_b.third_screen_first_name_label'),
              size: 'large',
              onChange: handleInputChange,
              onBlur: () => handleOnBlur(INPUT_TYPE_TEXT),
              name: 'first_name',
              autoComplete: 'off'
            })}
          </div>
          <div className="form__inputs">
            {InputSystem({
              label: t('signup.case_b.third_screen_last_name_label'),
              size: 'large',
              onChange: handleInputChange,
              onBlur: () => handleOnBlur(INPUT_TYPE_TEXT),
              name: 'last_name',
              autoComplete: 'off'
            })}
          </div>
          <div className="form__company">
            {InputSystem({
              label: t('signup.case_b.third_screen_organization_label'),
              size: 'large',
              onChange: handleInputChange,
              onBlur: () => handleOnBlur(INPUT_TYPE_TEXT),
              name: 'company_name',
              autoComplete: 'off'
            })}
          </div>
          <div className="form__company-size">
            <label>{t('signup.case_b.third_screen_organization_size_label')}</label>
            {DropdownSystem({
              height: 40,
              trigger: ['click'],
              label:
                ITEMS_COMPANY_SIZE.find(
                  (item) => item.key === companySizeSelected?.key
                )?.label || '',
              items: ITEMS_COMPANY_SIZE.map((item) => ({
                key: item.key,
                label: t(`signup.case_b.third_screen_organization_size_${item.key}`),
                icon: null,
                onClick: () => handleCompanySize(item),
                disabled: false,
                isDivider: false,
                active: companySizeSelected === item.key,
                subitems: []
              }))
            })}
          </div>
          <div className="form__company-type">
            <label>{t('signup.case_b.third_screen_organization_type_label')}</label>
            {DropdownSystem({
              height: 40,
              trigger: ['click'],
              label:
                ITEMS_COMPANY_TYPE.find(
                  (item) => item.key === companyTypeSelected?.key
                )?.label || '',
              items: ITEMS_COMPANY_TYPE.map((item) => ({
                key: item.key,
                label: t(`signup.case_b.third_screen_organization_type_${item.key}`),
                icon: null,
                onClick: () => handleCompanyType(item),
                disabled: false,
                isDivider: false,
                active: companyTypeSelected === item.key,
                subitems: []
              }))
            })}
          </div>
          <div className="form__password">
            {InputSystem({
              label: t('signup.case_b.third_screen_password_label'),
              size: 'large',
              hint: t('signup.case_b.third_screen_password_hint'),
              type: 'password',
              onChange: handleInputChange,
              onBlur: () => handleOnBlur(INPUT_TYPE_PASSWORD),
              name: 'password',
              autoComplete: 'off',
              isError: showErrorPassword
            })}
          </div>
          <div className="form__password2">
            {InputSystem({
              label: t('signup.case_b.third_screen_password2_label'),
              size: 'large',
              type: 'password',
              onChange: handleInputChange,
              onBlur: () => handleOnBlur(INPUT_TYPE_PASSWORD2),
              name: 'password2',
              autoComplete: 'off',
              hint: showErrorPassword2
                ? t('signup.case_b.third_screen_password2_hint')
                : '',
              isError: showErrorPassword2
            })}
          </div>
          <div className="form__button">
            <button
              type="submit"
              disabled={isNotValidFormatEmail(dataForm) || isLoadingButton}>
              {isLoadingButton ? (
                <LoadingOutlined />
              ) : (
                t('signup.case_b.third_screen_button_text')
              )}
            </button>
          </div>
        </form>
        <div className="body__hint">
          <span>
            {t('signup.case_b.third_screen_text')}{' '}
            <a
              href="https://www.outbuild.com/terms-and-conditions"
              target="_blank">
              {t('signup.case_b.third_screen_link_text')}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ThirdScreenB;
