/**
 * An enumeration that maps role types to their corresponding names.
 * This object is frozen to make it immutable, ensuring that the mappings
 * cannot be accidentally modified elsewhere in the code.
 *
 * @readonly
 * @enum {string}
 */
export const roleTypes = Object.freeze({
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  PROJECTLEADER: 'projectleader',
  PLANNER: 'planner', // Scheduler
  SUPERINTENDENT: 'superintendent',
  LASTPLANNER: 'lastplanner',
  CLIENT: 'client', // Owner
  MANAGER: 'manager', // Read Only
  SUBTRADE: 'subtrade' // Project Partner
});

export const userType = (t, loggedUser) =>
  Object.entries(roleTypes).reduce((acc, [key, value]) => {
    if (loggedUser.role !== 'superadmin' && value === 'superadmin') {
      return acc;
    }
    acc.push({ value, label: t(`positions.${value}`) });
    return acc;
  }, []);
