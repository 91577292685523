import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createBrowserHistory } from 'history';

/**
 * Redux implementation
 */
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { base } from './services/base';
import { start } from './monitor/monitor';
// import './i18n';

/** Prod verification for production (at IP log will work) */
if (window.location.hostname == 'app.outbuild.com') {
  console.log = () => {};
}

console.warn = () => {};

const history = createBrowserHistory();

start(history);

ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </Provider>,
  document.getElementById('root')
);
