const setSession = (response) => {
  const { data } = response;
  if (!data) return;

  const { user, authToken } = data;
  if (!authToken) return;

  const { id } = user;
  localStorage.setItem('signed', true);
  localStorage.setItem('firsTimeLogin', true);
  localStorage.setItem('user', JSON.stringify({ id }));
  localStorage.setItem('authToken', JSON.stringify(authToken));
};

export const redirectSelectCompany = (response) => {
  setSession(response);
  window.location = '/selectCompany';
};

export const setSessionForFreeTrial = (response) => {
  setSession(response);
};
