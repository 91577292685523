import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectService } from '../services';
import { filterByName } from '../utils/projectUtils';
import { setProps } from '../redux/slices/projectSlice';
import { ScheduleFactory } from '../components/ScheduleOfSchedules/builder/ScheduleFactory';
import { trackingEvent } from '../analytics';
import { HOME_TIMELINE_VIEW } from './useSelectProject';
import { AMPLITUDE_SERVICE } from '../analytics/constants';
import { zoomLevels } from '../components/GanttVisualization/constants';

const ganttType = 'scheduleOfSchedules';

const destroyGantt = () => {
  const gantt = window.ganttScheduleOfSchedule;
  if (!gantt) {
    return;
  }
  gantt.removeEvents();
  gantt.destructor();
  window.ganttScheduleOfSchedule = undefined;
};

const openForm =
  ({ permissions, handler, handlerName, dispatch, projectState }) =>
  (e, project) => {
    if (permissions.project !== 'AC') {
      return null;
    }

    if (e) {
      e.stopPropagation();
    }

    const props = {
      setFormDeleteVisible: {
        ...projectState.props,
        modalDeleteVisible: true,
        currentProject: project
      },
      setFormProjectVisible: {
        ...projectState.props,
        currentProjectId: project.id,
        openFormProject: true
      }
    };

    handler(true);
    dispatch(setProps(props[handlerName]));
  };

export const useScheduleOfSchedules = ({
  t,
  permissions,
  processedProjects,
  setFormDeleteVisible,
  setFormProjectVisible,
  setZoomLevel,
  zoomLevel,
  onClickProject,
  filterText
}) => {
  const projectState = useSelector(({ projectState }) => projectState);
  const ganttRef = useRef(null);
  const dispatch = useDispatch();

  const [dataToLoad, setDataToLoad] = useState([]);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => destroyGantt, []);

  useEffect(() => {
    if (!processedProjects || !window.loader) {
      return;
    }

    window.loader.show();
    projectService
      .getScheduleOfScheduleProjects()
      .then(({ data }) => {
        if (!data) {
          return [];
        }

        return data.filter(({ start_date: startDate }) => startDate !== null);
      })
      .then((schedules) => {
        setIsLoading(false);
        setDataToLoad(schedules);
        setFilteredSchedules(schedules.filter(filterByName(filterText)));
        window.loader.hide();
      });
  }, [processedProjects]);

  useEffect(() => {
    if (!filteredSchedules.length || !ganttRef.current) {
      destroyGantt();
      return;
    }

    const handleDelete = openForm({
      handler: setFormDeleteVisible,
      handlerName: 'setFormDeleteVisible',
      permissions,
      projectState,
      dispatch
    });

    const handleEdit = openForm({
      handler: setFormProjectVisible,
      handlerName: 'setFormProjectVisible',
      permissions,
      projectState,
      dispatch
    });

    const analytics = {
      search_text: filterText,
      results_count: filteredSchedules.length
    };

    const ganttOptions = {
      t,
      handleDelete,
      handleEdit,
      processedProjects,
      onClickProject,
      analytics,
      setZoomLevel
    };

    const gantt =
      window.ganttScheduleOfSchedule ||
      ScheduleFactory.create(ganttType, ganttOptions);

    if (!window.ganttScheduleOfSchedule) {
      window.ganttScheduleOfSchedule = gantt;
      gantt.init(ganttRef.current);
    }

    gantt.changeScaleVisualization(zoomLevels.length - 1 - zoomLevel);
    gantt.clearAll();
    gantt.parse({ data: filteredSchedules });
    gantt.sort((a, b) => a.name.localeCompare(b.name));
    gantt.render();
    gantt.createMarker();
  }, [filteredSchedules, ganttRef]);

  useEffect(() => {
    const gantt = window.ganttScheduleOfSchedule;
    if (!ganttRef.current || !gantt || !gantt.modalData) {
      return;
    }

    const { action, projectId, value } = gantt.modalData;
    if (action === 'edit') {
      const currentRef = gantt.getTask(projectId);
      currentRef.name = value;
    }

    if (action === 'delete') {
      gantt.deleteTask(projectId);
    }

    gantt.sort((a, b) => a.name.localeCompare(b.name));
    gantt.render();
  }, [processedProjects, ganttRef]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const schedules = dataToLoad.filter(filterByName(filterText));
    const gantt = window.ganttScheduleOfSchedule;

    if (gantt) {
      gantt.analytics = {
        search_text: filterText,
        results_count: schedules.length
      };
    }

    if (schedules.length === 0) {
      trackingEvent(
        'search_box_no_results',
        {
          search_text: filterText,
          event_source: HOME_TIMELINE_VIEW
        },
        AMPLITUDE_SERVICE
      );
    }

    setFilteredSchedules(schedules);
  }, [filterText]);

  return { ganttRef, filteredSchedules, isLoading };
};
