import jwt from 'jsonwebtoken';
import * as Sentry from '@sentry/react';
import { addBreadcrumbUtil } from '../monitor/monitor';
import { NO_ORGANIZATION_ACCESS_ERROR_MSG } from '../constants/access';
import { authService } from '../services';
import SessionCreationError from '../entities/error/SessionCreationError';

/**
 * Maps a user object to a friendlier format
 *
 * @param {Object} user - The original user object
 * @return {Object} - The user mapped to the new format
 */
export const mapUserToNewFormat = (user) => {
  const mappingConfig = [
    ['is_active', 'isActive'],
    ['name', 'firstName'],
    ['lastname', 'lastName'],
    ['image', 'thumbnail'],
    ['activation_token', null],
    ['password', null],
    ['userproject', null]
  ];
  const newUser = { ...user };

  mappingConfig.forEach(([oldPropertyName, newPropertyName]) => {
    if (newPropertyName) {
      newUser[newPropertyName] = newUser[oldPropertyName];
    }

    delete newUser[oldPropertyName];
  });

  return newUser;
};

export const getSignedUser = () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const { companyId, role, position } = getSessionTokenData();
    addBreadcrumbUtil('getSignedUser', 'User signed in', 'info', {
      user,
      companyId,
      role
    });
    return {
      ...user,
      companyId,
      role,
      position
    };
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setContext('getSignedUser', {
        message: err.message
      });
      Sentry.captureException(err);
    });
    return null;
  }
};

export const getCurrentUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (err) {
    return null;
  }
};

export const getFromSessionStorage = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (err) {
    return null;
  }
};

export const getCurrentSector = () => getFromSessionStorage('currentSector');
export const getCurrentProject = () => getFromSessionStorage('currentProject');
export const getCurrentCompany = () => getFromSessionStorage('company');
export const getSessionTokenData = () => {
  const sessionToken = getFromSessionStorage('sessionToken');

  if (!sessionToken) {
    return null;
  }

  try {
    const { id: data } = jwt.decode(sessionToken);
    const { id, role, position, companyId, activation_token } = data;
    return { id, role, position, companyId, activation_token };
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setContext('getSessionTokenData', {
        message: error.message
      });
      Sentry.captureException(error);
    });

    return null;
  }
};

export const setCurrentSector = (sector) =>
  sessionStorage.setItem('currentSector', JSON.stringify(sector));
export const setCurrentProject = (project) =>
  sessionStorage.setItem('currentProject', JSON.stringify(project));

export const initializeUserStorage = ({
  user,
  sessionToken,
  permissions,
  company
}) => {
  if (!sessionToken) {
    throw new Error(NO_ORGANIZATION_ACCESS_ERROR_MSG);
  }

  localStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('sessionToken', JSON.stringify(sessionToken));
  sessionStorage.setItem('permissiontable', JSON.stringify(permissions));
  sessionStorage.setItem('company', JSON.stringify(company));
};

export const getSessionToken = async (userId, companyId) => {
  try {
    const { data } = await authService.createSession({ userId, companyId });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw new SessionCreationError(error.message);
  }
};

export const clearSessionStorage = () => {
  const keys = [
    'sessionToken',
    'permissiontable',
    'company',
    'currentSector',
    'currentProject'
  ];

  keys.forEach((key) => sessionStorage.removeItem(key));
};
