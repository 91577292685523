import React from 'react';
import { CardViewIcon, TimelineViewIcon } from '../../../../icons';

export const TYPE_VIEWS = Object.freeze({
  CARD: {
    key: 'card',
    label: 'card_view_label',
    icon: <CardViewIcon color="#121212" />
  },
  TIMELINE: {
    key: 'timeline',
    label: 'timeline_view_label',
    icon: <TimelineViewIcon color="#121212" />
  }
});
