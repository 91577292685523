import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Spin, Icon } from 'antd';
import { PdfIcon } from '../../icons';
import { PAPER_FORMAT } from './constants';
import { trackingEvent } from '../../analytics/index';
import { AMPLITUDE_SERVICE } from '../../analytics/constants';
import moment from 'moment';
import ModalSystem from '../../components/DesignSystem/ModalSystem';
import DropdownSystem from '../../components/DesignSystem/DropdownSystem';
import DatePickerSystem from '../../components/DesignSystem/DatePickerSystem';
import CheckSystem from '../../components/DesignSystem/CheckSystem';
import { activityPdfService } from '../../services';
import notificationSystemV2 from '../DesignSystem/NotificationSystemV2/NotificationSystemV2';
import * as Sentry from '@sentry/react';
import {
  getCurrentCompany,
  getCurrentProject,
  getCurrentSector
} from '../../utils/userUtils';
import './index.scss';
import { checkBackendAccess } from '../../utils/checkBackendAccess';

const FORMAT_DEFAULT = 'MM/DD/AA';
const TABS = { PAGE: 1, PRINT_DATE: 2 };
const defaultFormatExport = 'ALL_CONTENT';
const antIcon = <Icon type="loading" style={{ fontSize: 20 }} spin />;

const ExportPdfModal = ({ t, isVisible, handleClose, setIsVisible }) => {
  const dateNow = new Date();
  const paperFormatDefault = PAPER_FORMAT[0];

  const [currentTab, setCurrentTab] = useState(TABS.PAGE);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [dateSelected, setDateSelected] = useState(dateNow);
  const [fitOnePage, setFitOnePage] = useState(false);
  const [checkMatchDay, setCheckMatchDay] = useState(false);
  const [formatExport, setFormatExport] = useState(defaultFormatExport);
  const [isTrimParent, setIsTrimParent] = useState(false);

  const currentProject = getCurrentProject();
  const currentCompany = getCurrentCompany();
  const currentSector = getCurrentSector();

  useEffect(() => {
    if (isVisible) {
      const button_import_export = document.querySelector('.ant-dropdown-open');
      if (button_import_export) button_import_export.click();
    }
  }, [isVisible]);

  const doesGanttExist = (_) => {
    const gantt = window?.to_use_react_gantt;
    if (gantt) {
      return gantt;
    }
  };

  const getCurrentTab = (tab = TABS.PAGE) => {
    const tabs = {
      [TABS.PAGE]: pageContentTab(),
      [TABS.PRINT_DATE]: printDateContentTab()
    };

    return tabs[tab];
  };

  const isDifferentsDates = (currentNow, currentSelected) => {
    let currentDateNow = new Date(currentNow);
    currentDateNow.setHours(0, 0, 0, 0);

    let currentDateSelected = new Date(currentSelected);
    currentDateSelected.setHours(0, 0, 0, 0);

    return currentDateNow.getTime() !== currentDateSelected.getTime();
  };

  const handleFormatChange = (key) => {
    const ganttObject = doesGanttExist();

    if (!ganttObject) return;
    if (!key) return;

    ganttObject.formatToExport = key;

    setFormatExport(key);
    if (key === defaultFormatExport) setFitOnePage(false);
  };

  const handleCloseModal = () => {
    setIsVisible(false);
    setCurrentTab(TABS.PAGE);
    setFormatExport(defaultFormatExport);
    setFitOnePage(false);
    setIsTrimParent(false);
    setDateSelected(dateNow);
    setCheckMatchDay(false);

    const gantt = doesGanttExist();
    if (!gantt) return;
    gantt.formatToExport = paperFormatDefault[0];

    handleClose();
  };

  const showNetworkBlockAlert = () => {
    try {
      const message = t('export_pdf_modal.network_blocking_alert');
      notificationSystemV2({
        key: 'network-blocking-alert',
        type: 'error',
        message
      });

      const { id: idCompany, name: nameCompany } = currentCompany;
      const { id: idProject, name: nameProject } = currentProject;
      const { id: idSector, name: nameSector } = currentSector;

      trackingEvent(
        'schedule_pdf_network_warning_alert',
        {
          company_id: idCompany,
          company_name: nameCompany,
          project_id: idProject,
          project_name: nameProject,
          stage_id: idSector,
          stage_name: nameSector,
          status_code: message
        },
        AMPLITUDE_SERVICE
      );
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingPdf(false);
    }
  };

  const handleExport = async () => {
    setLoadingPdf(true);
    try {
      const statusServer = await checkBackendAccess(activityPdfService);
      if (!statusServer) {
        return showNetworkBlockAlert();
      }

      const gantt = doesGanttExist();
      if (!gantt) return;
      if (!gantt.formatToExport) gantt.formatToExport = paperFormatDefault[0];

      let newDate = isDifferentsDates(dateNow, dateSelected)
        ? dateSelected
        : null;
      const isShowTodayLinePDF =
        gantt.config.show_todayline && checkMatchDay && newDate !== null;
      const isFitOnePage = fitOnePage;

      if (gantt.exportGantt)
        gantt.exportGantt(
          'pdf',
          setLoadingPdf,
          handleCloseModal,
          newDate,
          isShowTodayLinePDF,
          isFitOnePage,
          isTrimParent
        );
      const currentScale = JSON.parse(localStorage.getItem('scale'));

      trackingEvent(
        'pdf_exportable_ generation',
        {
          page_format: gantt.formatToExport,
          company_name: currentCompany?.name,
          company_id: currentCompany?.id,
          project_name: currentProject?.name,
          project_id: currentProject?.id,
          stage_name: currentSector?.name,
          stage_id: currentSector?.id,
          print_date_selected: moment(dateSelected).format(
            gantt?.currentDateFormat || FORMAT_DEFAULT
          ),
          today_line_aligned:
            gantt.config.show_todayline && checkMatchDay && newDate !== null,
          trim_parent_activities: isTrimParent,
          scale_option_selected: isFitOnePage ? 'Fit to one page wide' : null,
          schedule_zoom_level: currentScale
        },
        AMPLITUDE_SERVICE
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const pageContentTab = () => {
    return (
      <div className="tab--page">
        <div className="tab--page__title">
          <h5>{t('export_pdf_modal.tabs_page_subtitle')}</h5>
        </div>
        <div className="tab--page__format">
          <label>{t('export_pdf_modal.select_format')}</label>
          <div className="format__dropdown">
            {DropdownSystem({
              theme: 'dark',
              version: 'v2',
              trigger: ['click'],
              height: 32,
              label: t(PAPER_FORMAT.find(([key]) => key === formatExport)[1]),
              withRadioButtons: true,
              items: PAPER_FORMAT.map(([key, value]) => {
                return {
                  key: key,
                  label: t(value),
                  onClick: () => handleFormatChange(key),
                  disabled: false,
                  isDivider: false,
                  active: key === formatExport,
                  subitems: []
                };
              })
            })}
          </div>
        </div>
        <div className="tab--page__scaling">
          <label>{t('export_pdf_modal.scaling_options')}</label>
          <CheckSystem
            checked={fitOnePage}
            withLabel={true}
            label={t('export_pdf_modal.fit_one_page')}
            onClickFunction={(value) => setFitOnePage(value)}
            disabled={formatExport === defaultFormatExport}
          />
        </div>
        <div className="tab--page__trim">
          <CheckSystem
            checked={isTrimParent}
            onClickFunction={(vaue) => setIsTrimParent(vaue)}
            withLabel={true}
            label={t('export_pdf_modal.tabs_page_trim_check_label')}
          />
        </div>
      </div>
    );
  };

  const printDateContentTab = () => {
    const gantt = doesGanttExist();
    if (!gantt) return;

    const { show_todayline } = gantt.config;

    return (
      <div className="tab--print--date">
        <div className="tab--print--date__title">
          <h5>{t('export_pdf_modal.tabs_print_date_subtitle')}</h5>
        </div>
        <div className="tab--print--date__select">
          <label>{t('export_pdf_modal.print_date')}</label>
          <div className="select__picker">
            {DatePickerSystem({
              theme: 'dark',
              format: gantt?.currentDateFormat || FORMAT_DEFAULT,
              defaultValue: moment(
                dateSelected,
                gantt?.currentDateFormat || FORMAT_DEFAULT
              ),
              defaultPickerValue: moment(
                dateSelected,
                gantt?.currentDateFormat || FORMAT_DEFAULT
              ),
              allowClear: false,
              onChange: (date) => setDateSelected(date)
            })}
          </div>
        </div>
        <div className="tab--print--date__todayline">
          {isDifferentsDates(dateNow, dateSelected) && show_todayline && (
            <CheckSystem
              checked={checkMatchDay}
              withLabel={true}
              label={t('export_pdf_modal.today_line_aligned')}
              onClickFunction={(value) => setCheckMatchDay(value)}
            />
          )}
        </div>
      </div>
    );
  };

  const contentModal = () => {
    return (
      <div className="modal--export--pdf">
        <div className="modal--export--pdf__tabs">
          <div
            className={`tabs__tab ${currentTab === TABS.PAGE ? 'active' : ''}`}
            onClick={() => setCurrentTab(TABS.PAGE)}>
            {t('export_pdf_modal.tabs_page_title')}
          </div>
          <div
            className={`tabs__tab ${currentTab === TABS.PRINT_DATE ? 'active' : ''}`}
            onClick={() => setCurrentTab(TABS.PRINT_DATE)}>
            {t('export_pdf_modal.tabs_print_date_title')}
          </div>
        </div>
        <div className="modal--export--pdf__content">
          {getCurrentTab(currentTab)}
          <div className="content__footer">
            <button
              className="footer__cancel"
              onClick={() => handleCloseModal(false)}>
              {t('cancel')}
            </button>
            <button
              className="footer__download"
              disabled={loadingPdf}
              onClick={() => handleExport()}>
              {loadingPdf ? (
                <Spin indicator={antIcon} className="download__spinner" />
              ) : (
                t('export_pdf_modal.download')
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return ModalSystem({
    theme: 'dark',
    width: 350,
    title: t('export_pdf_modal.title'),
    showIconTitle: true,
    iconTitle: <PdfIcon color="#7DFF8A" />,
    visible: isVisible,
    setVisible: setIsVisible,
    centered: true,
    children: contentModal(),
    onCancelFunction: () => handleCloseModal()
  });
};

export default withTranslation()(ExportPdfModal);
