export const Colors = {
  WHITE: '#fff',
  BLACK: '#121212',
  PRIMARY: '#7dff8a',
  GRAY_1: '#b3b3b3',
  GRAY_2: '#d8d8d8',
  GRAY: '#c4c4c4',
  COOL_GRAY: '#f0f4f5',
  L_GRAY_4: '#e7e7e7',
  L_GRAY_3: '#f4f4f4',
  L_GRAY_2: '#f7f7f7',
  L_GRAY_1: '#fcfcfc',
  DARK_GREEN_SEC: '#252b1d',
  DARK_GREEN: '#3e482d',
  BED_ROCK: '#7d8671',
  BED_ROCK_T1: '#616a54',
  BED_ROCK_T2: '#2c3421',
  BED_ROCK_T3: '#151b0e',
  BED_ROCK_T4: '#090d03',
  BED_ROCK_BORDER: '#252b1e',
  MIST: '#cce2d6',
  DARK_MINT: '#53c255',
  LIGHT_GREEN: '#bdffc4',
  LIGHTER_GREEN: '#ecffee',
  BLUE: '#498e98',
  LIGHT_BLUE: '#a3c6cb',
  LIGHTER_BLUE: '#c9dee1',
  LIGHTER_BLUE_V2: '#f0f7f8',
  DARK_BLUE_GREEN: '#0e5381',
  DEEP_DARK_RED: '#4c2a2e',
  DARK_RED: '#8e0101',
  RED: '#e50101',
  LIGHT_RED: '#fa7676',
  LIGHTER_RED: '#fce6e6',
  YELLOW: '#fdd551',
  LIGHT_YELLOW: '#fdd99b',
  LIGHTER_YELLOW: '#fefbf0',
  ORANGE: '#f26d0c',
  LIGHT_ORANGE: '#fcd4b7',
  LIGHTER_ORANGE: '#fdefe5',
  DARK_GRAY: '#747474',
  SB_RED: '#f7b3b3',
  SB_LIGHT_RED: '#ffc2c2',
  SB_ORANGE: '#ffceb3',
  SB_YELLOW: '#fdebcd',
  SB_OLIVE: '#dfd8b5',
  SB_GREEN: '#b4e7b4',
  SB_DARK_GREEN: '#b5d8b5',
  SB_GREENISH_BLUE: '#b4cbcb',
  SB_CYAN_BLUE: '#b3e0e7',
  SB_LIGHT_BLUE: '#b8cffb',
  SB_ROYAL_BLUE: '#b5bff6',
  SB_RED_PURPLE: '#dcb4ef',
  SB_PINK_PURPLE: '#f1b4d1',
  SB_PINK: '#ffc7e2',
  SB_GRAY: '#cdcdcd',
  SB_DARK_GRAY: '#c2c2c2'
};
