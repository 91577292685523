import {
  init,
  track,
  Identify,
  identify,
  setUserId,
  reset
} from '@amplitude/analytics-browser';
import { AMPLITUDE_MIN_LENGHT_USER_ID } from '../../constants';
import { getTokenAmplitude } from '../../utils';
import { getSessionTokenData } from '../../../utils/userUtils';

export const initAmplitude = () => {
  init(getTokenAmplitude());
};

export const amplitudeUserIdentify = (user) => {
  const event = new Identify();
  const sessionTokenData = getSessionTokenData();

  event.set('user_id', user?.id);
  event.set('name', user?.name);
  event.set('last_name', user?.lastname);
  event.set('email', user?.email);
  event.set('position', sessionTokenData?.position);
  event.set('role', sessionTokenData?.role);
  event.set('company_id', sessionTokenData?.companyId);
  event.set('company', user?.company);

  identify(event);

  setUserId(user?.id.toString().padEnd(AMPLITUDE_MIN_LENGHT_USER_ID));
};

export const amplitudeEventTracking = (eventName, eventProperties) => {
  track(eventName, eventProperties);
};

export const resetPropertiesAmplitude = () => {
  reset();
};
