import React, { useEffect, useState } from 'react';
import EventEmitter from 'react-native-eventemitter';
import { Table, Input, Button, Layout, Menu } from 'antd';
import { useParams } from 'react-router-dom';
import './index.css';
import { withTranslation } from 'react-i18next';
import UserInactive from '../../components/Admin/user_inactive_list/index';
import CompanyCreate from '../../components/Admin/company_create/index';

function Admin(props) {
  // const { week, planificationday, year } = useParams();
  const { Header, Footer, Sider, Content } = Layout;
  const [menuSwitch, setMenuSwitch] = useState(false);
  const [value, setValue] = useState('');
  const [valueSearch, setValueSearch] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const { t } = props;
  
  const menu = () => (
    <Menu
      onClick={() => {
        setMenuSwitch(!menuSwitch);
      }}
      id="mimenupropio"
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      className="admin-main-menu"
      style={{
        width: '170px !important',
        backgroundColor: 'red !important'
      }}>
      <Menu.Item key="1">{t('admin.menu.activation_link_option')}</Menu.Item>
      <Menu.Item key="2">{t('admin.menu.create_organization_option')}</Menu.Item>
    </Menu>
  );
  // <General/>
  return (
    <div style={{ background: '#FFFFFF' }}>
      <Layout className="admin-main-layout">
        <Sider className="admin-main-slider">
          <Content className="admin-main-slider">{menu()}</Content>
        </Sider>
        <Layout>
          <Content className="admin-main-contenct">
            {!menuSwitch ? <UserInactive /> : <CompanyCreate />}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
export default withTranslation()(Admin);