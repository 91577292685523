import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PopoverSystemV2 from '../PopoverSystemV2';
import SplitTextSystem from '../SplitTextSystem';
import {
  SearchIcon,
  WarningDiamontIcon,
  DeleteTagOutlineIcon,
  RadioCheckedIcon
} from '../../../icons';
import { LoadingOutlined } from '@ant-design/icons';

const LIGHT_THEME = 'light';
const HEIGHT_ITEM = 30;
const WIDTH_DEFAULT = 200;
const DEFAULT_COUNT_ITEMS_SHOW = 5;

const PopoverCompanySystem = ({
  theme = LIGHT_THEME,
  width = WIDTH_DEFAULT,
  children,
  items = [],
  defaultChecked = undefined,
  withSearch = true,
  countItemsShow = DEFAULT_COUNT_ITEMS_SHOW,
  ctaText,
  ctaIcon,
  ctaFunction,
  onFocusSearchFunction,
  onCleanSearchFunction,
  onUsedSearchFunction,
  onShowEmptyStateFunction,
  onItemCheckedFunction,
  itemReference,
  isLoading = false,
  ...props
}) => {
  const { t } = useTranslation();

  const [isEmptyState, setIsEmptyState] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentItemChecked, setCurrentItemChecked] = useState(defaultChecked);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    setCurrentItems([...items]);
  }, [items]);

  useEffect(() => {
    setCurrentItemChecked(defaultChecked);
  }, [items]);

  useEffect(() => {
    if (isValidFunction(onItemCheckedFunction)) {
      onItemCheckedFunction(currentItemChecked);
    }
  }, [currentItemChecked]);

  useEffect(() => {
    setCurrentItemChecked(defaultChecked || currentItemChecked);
  }, [defaultChecked]);

  const handleOnChangeSearch = (event) => {
    setSearchInput(event.target.value);

    if (event.target.value === '') {
      setCurrentItems([...items]);
      setIsEmptyState(false);

      return;
    }

    const filteredItems = filterItemsByKeyword(
      currentItems,
      event.target.value
    );

    if (filteredItems.length === 0) {
      setIsEmptyState(true);

      if (isValidFunction(onShowEmptyStateFunction)) {
        onShowEmptyStateFunction(event.target.value);
      }

      return;
    }

    setCurrentItems(filteredItems);
    setIsEmptyState(false);
  };

  const handleCleanSearch = (isExternal = false) => {
    if (isValidFunction(onCleanSearchFunction) && !isExternal) {
      onCleanSearchFunction(searchInput);
    }

    setSearchInput('');
    setCurrentItems([...items]);
    setIsEmptyState(false);
  };

  function filterItemsByKeyword(items, keyword) {
    const lowerKeyword = keyword.toLowerCase();

    return items
      .map((item) => {
        if (item.name.toLowerCase().includes(lowerKeyword)) {
          return item;
        }

        return null;
      })
      .filter((item) => item !== null);
  }

  const calculateHeightItems = () => {
    const currentItemsCalculate = [...items];

    return {
      totalCount: countItemsShow * HEIGHT_ITEM + 32,
      itemsTotalAll: currentItemsCalculate.length
    };
  };

  const contentItem = (item) => {
    const contentChildren = (
      <div className="item__text">
        <div className="text">
          <div className="text__check">
            {item.key === currentItemChecked?.key ? (
              <RadioCheckedIcon
                color={theme === LIGHT_THEME ? '#53C255' : '#7DFF8A'}
              />
            ) : (
              <div className="check__empty" />
            )}
          </div>
          <div
            className="text__color"
            style={{ backgroundColor: item.color }}
          />
          <span style={{ maxWidth: width - 92 }}>{item.name}</span>
        </div>
      </div>
    );

    return (
      <div
        key={item.key}
        onClick={() => {
          isItemClickable(item);
          isOnUsedSearchFunction();
        }}
        className={`list__item ${item.disabled ? 'disabled' : ''}`}>
        <SplitTextSystem
          name={item.name}
          children={contentChildren}
          placement="top"
        />
      </div>
    );
  };

  const contentGlobalItem = (item) => {
    return contentItem(item);
  };

  const countItemsFunction = (items) => items.reduce((items) => items + 1, 0);

  const isItemClickable = (item) => {
    if (item.disabled) return;

    setCurrentItemChecked(item);
  };

  const isOnUsedSearchFunction = () => {
    if (!isValidFunction(onUsedSearchFunction) || searchInput === '') return;

    onUsedSearchFunction(searchInput, countItemsFunction(currentItems));
  };

  const isValidFunction = (functionValidation) =>
    typeof functionValidation === 'function';

  const checkedNoCompany = () => {
    setCurrentItemChecked({
      value: null,
      name: null,
      color: null
    });
  };

  const handleOnVisibleChange = (visible) => {
    if (!visible) {
      handleCleanSearch(true);
    }
  };

  const contentPopover = () => (
    <div
      className={`popover-company-design-system theme--${theme}`}
      style={{ width: width }}>
      {withSearch && (
        <div className="popover-company-design-system__search">
          <input
            placeholder="Search"
            name="search"
            onChange={handleOnChangeSearch}
            value={searchInput}
            autoComplete="off"
            onFocus={() =>
              isValidFunction(onFocusSearchFunction)
                ? onFocusSearchFunction()
                : null
            }
          />
          <div
            className="search__clean"
            onClick={() =>
              searchInput.length > 0 ? handleCleanSearch() : null
            }>
            {searchInput.length > 0 && (
              <DeleteTagOutlineIcon
                color={theme === LIGHT_THEME ? '#747474' : '#F0F4F5'}
              />
            )}
          </div>
          <div className="search__icon">
            <SearchIcon color={theme === LIGHT_THEME ? '#53C255' : '#7DFF8A'} />
          </div>
        </div>
      )}
      <div
        className="popover-company-design-system__items"
        style={{
          maxHeight:
            !isEmptyState && items.length > 0
              ? calculateHeightItems().totalCount
              : 'inherit',
          overflowY:
            calculateHeightItems().itemsTotalAll > countItemsShow &&
            !isEmptyState
              ? 'scroll'
              : 'initial'
        }}>
        {isEmptyState ? (
          <div className="items__empty">
            <div className="empty__icon">
              <WarningDiamontIcon
                color={theme === LIGHT_THEME ? '#747474' : '#c3cfbf'}
              />
            </div>
            <span>{t('popoverCompanySystem.empty_state_message')}</span>
          </div>
        ) : (
          <div className="items__list">
            {isLoading ? (
              <div className="list__empty">
                <LoadingOutlined width={24} />
                <span>{t('popoverCompanySystem.label_loading')}</span>
              </div>
            ) : items.length > 0 ? (
              <>
                <div
                  className="list__select"
                  onClick={() => checkedNoCompany()}>
                  <div className="select__checked">
                    {currentItemChecked?.value === null &&
                    currentItemChecked?.name === null &&
                    currentItemChecked?.color === null ? (
                      <RadioCheckedIcon
                        color={theme === LIGHT_THEME ? '#53C255' : '#7DFF8A'}
                      />
                    ) : (
                      <div className="checked__empty" />
                    )}
                  </div>
                  <span>{t('popoverCompanySystem.label_no_company')}</span>
                </div>
                {currentItems.map((item) => contentGlobalItem(item))}
              </>
            ) : (
              <div className="list__empty">
                <div className="empty__icon">
                  <WarningDiamontIcon
                    color={theme === LIGHT_THEME ? '#747474' : '#c3cfbf'}
                  />
                </div>
                <span>
                  {t('popoverCompanySystem.emtpy_state_company_message')}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      {ctaText && isValidFunction(ctaFunction) && (
        <div
          className="popover-company-design-system__cta"
          onClick={() => ctaFunction()}>
          {ctaIcon && <div className="cta__icon">{ctaIcon}</div>}
          <span>{ctaText}</span>
        </div>
      )}
    </div>
  );

  return (
    <PopoverSystemV2
      itemReference={itemReference}
      theme={theme}
      content={contentPopover()}
      onVisibleChange={(value) => handleOnVisibleChange(value)}
      {...props}>
      {children}
    </PopoverSystemV2>
  );
};

export default PopoverCompanySystem;
