import React, { useState, useEffect } from 'react';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const cncExportableColumns = [
  'cnctasks',
  'week',
  'cncTypeId',
  'create_date',
  'user',
  'status',
  'priority'
];

function LookAheadPlanExport(props) {
  const {
    tableColumns,
    dataToExport,
    toSelectResponsables,
    t,
    lastLevelActivities,
    lookaheadState,
    haveWeeklyPlan,
    Icon,
    iconColor,
    place = 'weekly'
  } = props;
  const taskList = props.taskList;
  let activity_view = props.subContracts ? 1 : 0;
  const constraint_view = props.constraintTypes ? 1 : 0;
  const cnc_view = props.cncTypes ? 1 : 0;
  let validate_tre = true;

  const activatedColumns = useSelector((state) => {
    if (activity_view) {
      return state.lookaheadState.activatedColumns;
    } else if (constraint_view) {
      return state.constraintState.activatedColumns;
    } else if (cnc_view) {
      return cncExportableColumns;
    }
  });

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(activatedColumns);
  const [finalData, setFinalData] = useState([]);
  const projectState = useSelector((state) => state.projectState);
  const [refresh, setRefresh] = useState(false);
  const [planification_day, setPlanification_day] = useState('');

  const arr_priority = [
    {
      value: 'Low',
      label: t('exportable.Low')
    },
    {
      value: 'Normal',
      label: 'Normal'
    },
    {
      value: 'High',
      label: t('exportable.High')
    },
    {
      value: 'Urgent',
      label: t('exportable.Urgent')
    }
  ];

  const arr_elam_state = [
    {
      color: '#E50101',
      value: 'Restricted',
      label: t('exportable.Restricted'),
      description: 'LEAN debit status means...',
      weigth: 4
    },
    {
      color: '#586666',
      value: 'Debit',
      label: t('exportable.Should'),
      description: 'LEAN debit status means...',
      weigth: 3
    },
    {
      color: '#34AF00',
      value: 'Can',
      label: t('exportable.Can'),
      description: 'LEAN can status means...',
      weigth: 2
    },
    {
      color: '#2C3421',
      value: 'Will',
      label: t('exportable.Weekly_Plan'),
      description: 'LEAN debit status means...',
      weigth: 1
    },
    {
      color: '#F59D04',
      value: 'Committed',
      label: t('exportable.Committed'),
      description: 'LEAN debit status means...',
      weigth: 0
    }
  ];

  const arr_status = [
    {
      value: 'resolved',
      label: t('exportable.Resolved')
    },
    {
      value: 'unsolved',
      label: t('exportable.Unsolved')
    },
    {
      value: 'inprocess',
      label: t('exportable.InProcess')
    }
  ];

  const arr_area = [
    {
      value: 'rrhh',
      label: t('settings.project_categories.rrhh')
    },
    {
      value: 'machinery',
      label: t('settings.project_categories.machinery')
    },
    {
      value: 'material',
      label: t('settings.project_categories.material')
    },
    {
      value: 'permissions',
      label: t('settings.project_categories.permissions')
    },
    {
      value: 'activity',
      label: t('settings.project_categories.activity')
    },
    {
      value: 'risks',
      label: t('settings.project_categories.risks')
    },
    {
      value: 'ambient',
      label: t('settings.project_categories.ambient')
    },
    {
      value: 'procedure',
      label: t('settings.project_categories.procedure')
    },
    {
      value: 'provider',
      label: t('settings.project_categories.provider')
    },
    {
      value: 'design',
      label: t('settings.project_categories.design')
    },
    {
      value: 'others',
      label: t('settings.project_categories.others')
    },
    {
      value: 'quality',
      label: t('settings.project_categories.quality')
    },
    {
      value: 'plan',
      label: t('settings.project_categories.plan')
    },
    {
      value: 'ambient',
      label: t('settings.project_categories.ambient')
    },
    {
      value: 'weather',
      label: t('settings.project_categories.weather')
    }
  ];

  const arrTaskStatus = [
    {
      icon: 'fas fa-clock',
      color: 'yellow',
      value: 'Advancement',
      label: t('exportable.Advancement'),
      weigth: 4
    },
    {
      icon: 'fas fa-clock',
      color: 'yellow',
      value: 'Overdue',
      label: t('exportable.Overdue'),
      weigth: 3
    },
    {
      icon: 'fas fa-clock',
      color: 'yellow',
      value: 'Doing',
      label: t('exportable.Doing'),
      weigth: 2
    },
    {
      icon: 'far fa-check-circle',
      color: 'green',
      value: 'Done',
      label: t('exportable.Done'),
      weigth: 1
    },
    {
      icon: 'fas fa-minus-circle',
      color: 'grey',
      value: 'Waiting',
      label: t('exportable.Waiting'),
      weigth: 0
    }
  ];

  useEffect(() => {
    const data = [];
    setData(dataToExport);
    if (
      cnc_view == 1 ||
      (activatedColumns.length !== 0 && tableColumns.length !== 0)
    ) {
      tableColumns.forEach((column) => {
        if (column.name !== 'actions') {
          (activatedColumns.length == 0 ? ['name'] : activatedColumns).some(
            (e) => {
              if (
                (cnc_view == 1 ? cncExportableColumns : [])
                  .concat([e, e?.value])
                  .includes(column.name) &&
                !data.includes(column)
              ) {
                data.push(column);
              }
            }
          );
        }
      });
      setColumns(data);
    }
  }, [activatedColumns, tableColumns]);

  const recursiveGet = (
    arrayToPopulate,
    data,
    columns,
    padding = ' ',
    calendarId = null,
    activityRoute = null
  ) => {
    const sector = projectState.allSectors.find(
      (e) => e.id == projectState.sectorSelected
    );

    data.map((singleElement) => {
      const ganttReference = window.ganttVisualization
        ? window.ganttVisualization.getTask(singleElement.id)
        : null;

      if (ganttReference) {
        singleElement.expected = ganttReference.expected;
      }
      if (!singleElement.activityId) {
        calendarId = singleElement.calendar_id;
      }
      const singleElementToPush = {};

      columns.map((singleColumn) => {
        if (singleColumn.name === 'roadblocks') {
          singleColumn.mode = 'multiple';
        }

        singleElementToPush.Sector = sector.name;
        if (singleColumn.name == 'name') {
          singleElementToPush[singleColumn.name] =
            typeof singleElement[singleColumn.name] === 'string'
              ? singleElement[singleColumn.name]
              : JSON.stringify(singleElement[singleColumn.name]);
          let route = '-';
          if (singleElement.activityId) {
            if (lastLevelActivities) {
              const activityTmp = lastLevelActivities.activities.find(
                (el) => el.id === singleElement.activityId
              );
              if (activityTmp) {
                // activityName = nameTmp.nam, e
                route = `${activityTmp.activityRoute} > ${activityTmp.name}`;
              }
            } else {
              if (singleElement.taskRoute) {
                const taskRoute = singleElement.taskRoute.split(' < ');
                taskRoute.push(taskRoute.shift());
                route = taskRoute.join(' > ');
              }
            }
          }
          singleElementToPush.Actividad = `${route}`;
          activity_view = 1;
        } else {
          if (singleColumn.mode) {
            if (singleColumn.mode == 'multiple') {
              const text = [];

              if (singleColumn.name == 'ponderator') {
                singleElementToPush[singleColumn.name] = parseFloat(
                  singleElement[singleColumn.name]
                ).toFixed(2);
              }

              if (
                singleColumn.name === 'roadblocks' &&
                singleElement.constraints
              ) {
                const released = singleElement.constraints.filter(
                  (constraint) => constraint.status == 'released'
                );
                singleElementToPush[singleColumn.name] =
                  `${released.length}/${singleElement.constraints.length}`;
              }

              if (singleElement[singleColumn.name]) {
                if (singleColumn.name != 'Rescheduling') {
                  // Evalua las columnas que tienen un array de valores
                  if (Array.isArray(singleElement[singleColumn.name])) {
                    singleElement[singleColumn.name].map((el) => {
                      if (el) {
                        if (el.name) {
                          text.push(
                            `${el.name} ${
                              el.lastname != null ? el.lastname : ''
                            }`
                          );
                        } else if (el.taskId) {
                          const taskFind = taskList.find(
                            (task) => task.id === el.taskId
                          );
                          if (taskFind) {
                            text.push(`${taskFind.name || ''}`);
                          }
                        }
                      }
                    });
                    singleElementToPush[singleColumn.name] = text.join(',   ');
                  } else {
                    if (singleElement[singleColumn.name]) {
                      singleElementToPush[singleColumn.name] =
                        singleElement[singleColumn.name].name;
                    } else {
                      singleElementToPush[singleColumn.name] = '';
                    }
                  }
                }
              }
            } else {
              if (!Array.isArray(singleElement[singleColumn.name])) {
                singleElementToPush[singleColumn.name] =
                  singleElement[singleColumn.name];
                switch (singleColumn.name) {
                  case 'priority':
                    if (singleElement[singleColumn.name] != undefined) {
                      const label = arr_priority.filter(
                        (e) => e.value == singleElement[singleColumn.name]
                      );
                      singleElementToPush[singleColumn.name] = label[0].label;
                    } else {
                      singleElementToPush[singleColumn.name] =
                        singleElement[singleColumn.name];
                    }
                    break;
                  // status
                  case 'status':
                    if (singleElement[singleColumn.name] != undefined) {
                      let objArrStatus = arrTaskStatus;
                      if (cnc_view) {
                        objArrStatus = arr_status;
                      }
                      const label = objArrStatus.filter(
                        (e) => e.value == singleElement[singleColumn.name]
                      );
                      singleElementToPush[singleColumn.name] = label[0].label;
                    } else {
                      singleElementToPush[singleColumn.name] =
                        singleElement[singleColumn.name];
                    }
                    break;
                  case 'lean_status':
                    if (singleElement[singleColumn.name] != undefined) {
                      const label = arr_elam_state.filter(
                        (e) => e.value == singleElement[singleColumn.name]
                      );
                      singleElementToPush[singleColumn.name] = label[0].label;
                    } else {
                      singleElementToPush[singleColumn.name] =
                        singleElement[singleColumn.name];
                    }
                    break;
                  case 'materialId':
                    if (singleElement[singleColumn.name] != undefined) {
                      const label = props.resources.filter(
                        (e) =>
                          e.id == singleElement[singleColumn.name] &&
                          e.type == 'material'
                      );
                      if (label[0])
                        singleElementToPush[singleColumn.name] = label[0].name;
                    } else {
                      singleElementToPush[singleColumn.name] = [
                        undefined,
                        null
                      ].includes(singleElement[singleColumn.name])
                        ? '-'
                        : singleElement[singleColumn.name];
                    }
                    break;
                  case 'specialtyId':
                    if (singleElement[singleColumn.name] != undefined) {
                      const label = props.resources.filter(
                        (e) =>
                          e.id == singleElement[singleColumn.name] &&
                          e.type == 'rrhh'
                      );
                      if (label[0])
                        singleElementToPush[singleColumn.name] = label[0].name;
                    } else {
                      singleElementToPush[singleColumn.name] =
                        singleElement[singleColumn.name];
                    }
                    break;
                  case 'machineId':
                    if (singleElement[singleColumn.name] != undefined) {
                      const label = props.resources.filter(
                        (e) =>
                          e.id == singleElement[singleColumn.name] &&
                          e.type == 'machinery'
                      );
                      let nameTmp = '-';
                      if (label[0]) {
                        nameTmp = label[0].name;
                      }
                      singleElementToPush[singleColumn.name] = nameTmp;
                    } else {
                      singleElementToPush[singleColumn.name] =
                        singleElement[singleColumn.name];
                    }
                    break;
                  // subcontractId
                  case 'subcontractId':
                    if (singleElement[singleColumn.name] != undefined) {
                      const label = props.subContracts.filter(
                        (e) => e.id == singleElement[singleColumn.name]
                      );
                      let nameTmp = '-';
                      if (label[0]) {
                        nameTmp = label[0].name;
                      }
                      singleElementToPush[singleColumn.name] = nameTmp;
                    } else {
                      singleElementToPush[singleColumn.name] =
                        singleElement[singleColumn.name];
                    }
                    break;
                  case 'constraintTypeId':
                    if (singleElement[singleColumn.name] != undefined) {
                      const label = props.constraintTypes.filter(
                        (e) => e.id == singleElement[singleColumn.name]
                      );
                      if (label[0])
                        singleElementToPush[singleColumn.name] = label[0].name;
                    } else {
                      singleElementToPush[singleColumn.name] =
                        singleElement[singleColumn.name];
                    }
                    break;
                  case 'typeArea':
                    if (singleElement[singleColumn.name] != undefined) {
                      const labelArea = arr_area.filter(
                        (e) => e.value == singleElement[singleColumn.name]
                      );
                      singleElementToPush[singleColumn.name] =
                        labelArea[0].label;
                    } else {
                      singleElementToPush[singleColumn.name] =
                        singleElement[singleColumn.name];
                    }
                    break;
                  case 'cncTypeId':
                    if (singleElement[singleColumn.name] != undefined) {
                      const label = props.cncTypes.filter(
                        (e) => e.id == singleElement[singleColumn.name]
                      );
                      if (label[0])
                        singleElementToPush[singleColumn.name] = label[0].name;
                    } else {
                      singleElementToPush[singleColumn.name] =
                        singleElement[singleColumn.name];
                    }
                    break;
                  case 'ponderator':
                    singleElementToPush[singleColumn.name] = parseFloat(
                      singleElement[singleColumn.name]
                    ).toFixed(2);
                    break;
                  case 'progress':
                    if (singleColumn.name == 'ponderator') {
                      singleElementToPush[singleColumn.name] = parseFloat(
                        singleElement[singleColumn.name]
                      ).toFixed(2);
                    }
                    break;
                  case 'quantity_parcial_percentaje':
                    if (singleColumn.name == 'quantity_parcial_percentaje') {
                      singleElementToPush[singleColumn.name] = parseFloat(
                        singleElement[singleColumn.name]
                      ).toFixed(2);
                    }
                    break;
                  default:
                    if (singleElement[singleColumn.name] == null) {
                      singleElementToPush[singleColumn.name] = '';
                    } else {
                      singleElementToPush[singleColumn.name] =
                        typeof singleElement[singleColumn.name] === 'string'
                          ? singleElement[singleColumn.name]
                          : JSON.stringify(singleElement[singleColumn.name]);
                    }
                    break;
                }
              } else {
                if (singleColumn.name != 'Rescheduling') {
                  const text = [];
                  let stringArray = [];
                  singleElement[singleColumn.name].map((el) => {
                    if (!el.constraintTypeId) {
                      stringArray.push(el.name);
                      if (el.activity) {
                        stringArray.unshift(el.activity.name);
                        stringArray.unshift(el.activity.correlative_id);
                      }
                    }
                    text.push(stringArray.join(' > '));
                    stringArray = [];
                  });
                  singleElementToPush[singleColumn.name] = text.join(',   ');
                }
              }
            }
          } else {
            if (singleColumn.data_type == 'date') {
              if (singleElement[singleColumn.name]) {
                if (singleColumn.name == 'release_date') {
                  if (singleElement.status == 'released') {
                    singleElementToPush[singleColumn.name] = moment(
                      singleElement[singleColumn.name]
                    ).format(sector.dateFormat);
                  }
                } else {
                  singleElementToPush[singleColumn.name] = moment(
                    singleElement[singleColumn.name]
                  ).format(sector.dateFormat);
                }
              }
            } else {
              let percentVal;
              let quantity_parcial = 0;
              if (haveWeeklyPlan) {
                const find = haveWeeklyPlan.taskcommitments.find(
                  (e) => e.taskId === singleElement.id
                );
                if (find && !isNaN(find.commitment_percentaje)) {
                  const valueColumn = find.commitment_percentaje || 0;
                  percentVal = valueColumn.toFixed
                    ? valueColumn.toFixed(2)
                    : valueColumn;
                  if (parseFloat(percentVal) > 100) {
                    percentVal = 100;
                  }
                  quantity_parcial = find.current_commitment_partial;
                }
              }
              switch (singleColumn.name) {
                case 'expected':
                  // Declaraciones ejecutadas cuando el resultado de expresión coincide con valorN
                  // singleElementToPush[singleColumn.name] = parseFloat(calculateExpected(singleElement, ganttAPI, calendarId)).toFixed(2)
                  if (
                    singleElement[singleColumn.name] != undefined &&
                    singleElement[singleColumn.name] != null &&
                    !isNaN(singleElement[singleColumn.name])
                  ) {
                    singleElementToPush[singleColumn.name] = parseFloat(
                      singleElement[singleColumn.name]
                    ).toFixed(2);
                  } else {
                    singleElementToPush[singleColumn.name] = 0;
                  }
                  break;
                case 'expected_cost':
                  // Declaraciones ejecutadas cuando el resultado de expresión coincide con valorN
                  // singleElementToPush[singleColumn.name] = parseFloat(calculateExpectedCost(singleElement, ganttAPI, calendarId)).toFixed(2)
                  singleElementToPush[singleColumn.name] = parseFloat(
                    singleElement[singleColumn.name]
                  ).toFixed(2);
                  break;
                case 'spend_hm':
                  if (
                    singleElement[singleColumn.name] != undefined &&
                    singleElement[singleColumn.name] != null &&
                    !isNaN(singleElement[singleColumn.name])
                  ) {
                    singleElementToPush[singleColumn.name] = parseFloat(
                      (singleElement.progress / 100) * singleElement.total_hm
                    ).toFixed(2);
                  } else {
                    singleElementToPush[singleColumn.name] = 0;
                  }
                  break;
                // dateRange
                case 'total_quantity':
                  singleElementToPush[singleColumn.name] = singleElement[
                    singleColumn.name
                  ]
                    ? parseFloat(singleElement[singleColumn.name]).toFixed(2)
                    : '0.00';
                  break;
                case 'actual_quantity':
                  singleElementToPush[singleColumn.name] = singleElement[
                    singleColumn.name
                  ]
                    ? parseFloat(singleElement[singleColumn.name]).toFixed(2)
                    : '0.00';
                  break;
                case 'quantity_parcial':
                  singleElementToPush[singleColumn.name] = quantity_parcial
                    ? parseFloat(quantity_parcial).toFixed(2)
                    : '0.00';
                  break;
                case 'commitment_percentaje':
                  singleElementToPush[singleColumn.name] = percentVal
                    ? parseFloat(percentVal).toFixed(2)
                    : singleElement[singleColumn.name]
                      ? parseFloat(singleElement[singleColumn.name]).toFixed(2)
                      : '0.00';
                  break;
                case 'expectedweek':
                  // Declaraciones ejecutadas cuando el resultado de expresión coincide con valorN
                  singleElementToPush[singleColumn.name] = parseFloat(
                    singleElement[singleColumn.name]
                  ).toFixed(2);
                  break;
                case 'ponderator':
                  singleElementToPush[singleColumn.name] = parseFloat(
                    singleElement[singleColumn.name]
                  ).toFixed(2);
                  break;
                case 'progress':
                  if (singleColumn.name == 'progress') {
                    singleElementToPush[singleColumn.name] =
                      singleElement[singleColumn.name] > 100
                        ? '100.00'
                        : parseFloat(singleElement[singleColumn.name]).toFixed(
                            2
                          );
                  }
                  break;
                case 'quantity_parcial_percentaje':
                  if (singleColumn.name == 'quantity_parcial_percentaje') {
                    const tempVal = parseFloat(
                      singleElement[singleColumn.name]
                    ).toFixed(2);
                    singleElementToPush[singleColumn.name] = isNaN(tempVal)
                      ? ''
                      : tempVal;
                  }
                  break;
                default:
                  if (singleElement[singleColumn.name] == null) {
                    singleElementToPush[singleColumn.name] = '';
                  } else {
                    singleElementToPush[singleColumn.name] =
                      typeof singleElement[singleColumn.name] === 'string'
                        ? singleElement[singleColumn.name]
                        : JSON.stringify(singleElement[singleColumn.name]);
                  }
                  break;
              }
            }
          }
        }
      });
      if (props.constraintTypes) {
        // singleElementToPush['Sem detección'] = moment(singleElement.createdAt).week();
        singleElementToPush['Fec detección'] = moment(
          singleElement.createdAt
        ).format(sector.dateFormat);
        if (singleElement.identify_user) {
          const userCreator = toSelectResponsables.find(
            (el) => el.id === singleElement.identify_user
          );
          if (userCreator) {
            singleElementToPush['Identificada Por'] =
              `${userCreator.name} ${userCreator.lastname}`;
          }
        }

        if (singleElement.releaseuser) {
          const userRelease = singleElement.releaseuser;
          singleElementToPush['Liberada Por'] =
            `${userRelease.name} ${userRelease.lastname}`;
        }

        const weekIni = parseInt(moment(singleElement.commitmentDate).week());
        const day3ofWeek = moment().week(weekIni);
        const from_date = day3ofWeek
          .startOf('isoWeek')
          .add('days', planification_day);
        const to_date = from_date.clone().add(6, 'day');
        const start = from_date
          .toISOString()
          .split('T')[0]
          .split('-')
          .join('/');
        const end = to_date.toISOString().split('T')[0].split('-').join('/');
        let rangeIni = {
          start: start,
          end: end,
          week: weekIni,
          today: moment(singleElement.commitmentDate)
        };
        let found = false;
        let weekSearch = weekIni;
        let count = 0;
        while (found === false) {
          const currentDate = moment(singleElement.commitmentDate);
          const belongs = belongsToRange(currentDate, [{ ...rangeIni }]);
          if (belongs) {
            found = belongs;
            weekSearch = rangeIni.week;
          } else {
            const newStart = moment(rangeIni.start).subtract(7, 'days'); // -7
            const newEnd = moment(rangeIni.end).subtract(7, 'days'); // -7
            const newWeek = parseInt(rangeIni.week) - 1; // -1
            rangeIni = {
              start: newStart,
              end: newEnd,
              week: newWeek,
              today: moment(singleElement.commitmentDate)
            };
          }
          if (count < 10) {
            found = true;
          }
          count++;
        }
        // singleElementToPush['Sem comp.'] = weekSearch
        singleElementToPush.Id = singleElement.id;
        const a = moment(singleElement.createdAt);
        const b = moment(singleElement.commitmentDate);
        const diferent = b.diff(a, 'days');
        singleElementToPush['Anticipación'] = diferent;

        // if (singleElement.commitmentDate) {
        //     singleElementToPush['Entra en el PCR'] = 1;
        // } else {
        //     singleElementToPush['Entra en el PCR'] = 0;
        // }
        if (singleElement.reprogramint) {
          singleElementToPush.Reprogramada = t('exportable.Rescheduling');
        }
      }

      if (props.cncTypes) {
        if (singleElement.user) {
          singleElementToPush.user = `${singleElement.user.name} ${singleElement.user.lastname}`;
        }
      }

      // Constraints
      if (
        !singleElement.activityRoute &&
        !singleElement.childRended &&
        singleElement.constraintTypeId
      ) {
        const newStatus = t(
          `tables.lookahead.constraints.status.options.${singleElementToPush.status}`
        );
        const newPriority = t(
          `tables.lookahead.constraints.priority.options.${singleElementToPush.priority}`
        );
        singleElementToPush.status = newStatus;
        singleElementToPush.priority = newPriority;
        arrayToPopulate.push(singleElementToPush);
      } else if (
        !singleElement.activityRoute &&
        !singleElement.childRended &&
        singleElement.cncTypeId
      ) {
        // Cncs
        const newStatus = t(
          `tables.weeklyplan.cncs.status.options.${singleElement.status}`
        );
        const newPriority = t(
          `tables.weeklyplan.cncs.priority.options.${singleElement.priority}`
        );
        singleElementToPush.status = newStatus;
        singleElementToPush.priority = newPriority;
        arrayToPopulate.push(singleElementToPush);
      } else if (!singleElement.activityRoute && !singleElement.childRended) {
        /** check showWeeklyPlan if exists */
        if (typeof singleElement.showWeeklyPlan !== 'undefined') {
          if (singleElement.showWeeklyPlan) {
            arrayToPopulate.push(singleElementToPush);
          } else if (place === 'lookahead') {
            arrayToPopulate.push(singleElementToPush);
          }
        } else {
          arrayToPopulate.push(singleElementToPush);
        }
      }

      if (validate_tre) {
        if (singleElement.children) {
          recursiveGet(
            arrayToPopulate,
            singleElement.tasks || singleElement.children,
            columns,
            padding + '  ',
            calendarId,
            singleElement.activityRoute
          );
        }

        if (constraint_view) {
          if (singleElement.tasks && !singleElement.id) {
            recursiveGet(
              arrayToPopulate,
              singleElement.tasks || singleElement.children,
              columns,
              padding + '  ',
              calendarId,
              singleElement.activityRoute
            );
          }
        } else {
          if (singleElement.tasks) {
            recursiveGet(
              arrayToPopulate,
              singleElement.tasks || singleElement.children,
              columns,
              padding + '  ',
              calendarId,
              singleElement.activityRoute
            );
          }
        }
      }
      validate_tre = !props.constraintTypes && !props.cncTypes;
    });
  };

  const belongsToRange = (currentDate, rangesDates) => {
    const formatDate = 'YYYY/MM/DD';
    const belongs = rangesDates.some((e) => {
      const startDate = moment(e.start, formatDate);
      const endDate = moment(e.end, formatDate);
      return currentDate.isBetween(startDate, endDate, 'days', true);
    });
    return belongs;
  };

  useEffect(() => {
    setRefresh(false);
    if (data.length && tableColumns.length) {
      const finalExportableArray = [];
      data[0].tasks.activityRoute = 'true';
      if (props.constraintTypes) {
        const project = projectState.allProjects.find(
          (pr) => pr.id === projectState.projectSelected
        );
        let planificationDay;
        if (project.planification_day == 0) {
          planificationDay = -1;
        } else {
          planificationDay = project.planification_day - 1;
        }
        setPlanification_day(project.planification_day);
        const data_constraint = { ...data[0] };
        data_constraint.tasks.map((constraint) => {
          // constraint.tasks = []
          constraint.schedules.map((schedul) => {
            const c = { ...constraint };
            if (!c.commitmentDate) {
              c.status = 'notcompromises';
              c.reprogramint = false;
              data[0].tasks.push(c);
            } else if (
              moment(c.commitmentDate.substr(0, 10)).format('YYYY/MM/DD') !==
              moment(schedul.deadline.substr(0, 10)).format('YYYY/MM/DD')
            ) {
              c.commitmentDate = moment(schedul.deadline.substr(0, 10)).format(
                'YYYY/MM/DD H:MM'
              );
              c.status = 'expired';
              c.reprogramint = true;
              data[0].tasks.push(c);
            }
          });
        });
      }
      recursiveGet(finalExportableArray, data, columns);
      setFinalData(finalExportableArray);
    }
  }, [data, columns]);

  if (cnc_view === 1 && !finalData[0]?.create_date) {
    finalData.shift();
  }
  useEffect(() => {
    setRefresh(true);
  }, [finalData]);

  return refresh ? (
    <ExcelFile
      element={
        <>
          {Icon && <Icon color={iconColor} />}
          <span>Excel</span>
        </>
      }>
      {activity_view === 1 ? (
        <ExcelSheet
          data={finalData}
          name={
            lookaheadState === 'weeklyplanState'
              ? t('modules.weeklyplan')
              : t('modules.lookahead')
          }>
          <ExcelColumn label={t('exportable.Stages')} value="Sector" />
          {columns.map((col) => (
            <ExcelColumn key={col.label} label={col.label} value={col.name} />
          ))}
        </ExcelSheet>
      ) : constraint_view === 1 ? (
        <ExcelSheet data={finalData} name={t('modules.lookahead')}>
          <ExcelColumn label="Id" value="Id" />
          <ExcelColumn label={t('exportable.Stages')} value="Sector" />
          {columns.map((col) => (
            <ExcelColumn key={col.label} label={col.label} value={col.name} />
          ))}
          <ExcelColumn
            label={t('export.created_by')}
            value="Identificada Por"
          />
          <ExcelColumn label={t('export.released_by')} value="Liberada Por" />
          <ExcelColumn
            label={t('export.date_detection')}
            value="Fec detección"
          />
          <ExcelColumn label={t('export.anticipation')} value="Anticipación" />
          <ExcelColumn label={t('export.reprogrammed')} value="Reprogramada" />
        </ExcelSheet>
      ) : cnc_view === 1 ? (
        <ExcelSheet data={finalData} name={t('modules.weeklyplan')}>
          {columns.map((col) => (
            <ExcelColumn key={col.label} label={col.label} value={col.name} />
          ))}
        </ExcelSheet>
      ) : (
        <ExcelSheet data={finalData} name={t('modules.lookahead')}>
          <ExcelColumn label={t('exportable.Stages')} value="Sector" />
          {columns.map((col) => (
            <ExcelColumn key={col.label} label={col.label} value={col.name} />
          ))}
        </ExcelSheet>
      )}
    </ExcelFile>
  ) : null;
}
export default withTranslation()(LookAheadPlanExport);
