import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';

import CommitmentsActivity from '../../../components/WeeklyPlan/Commintments';
import CustomPaginator from '../../../components/CustomPaginator';

import { tableMetadata } from './table.layout';

/** react-query */
import { useQuery } from 'react-query';

import styles from './Commitments.module.scss';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
/** Import lodash functions */
import { startCase } from 'lodash';

/** import library for handle dates */
import 'moment/locale/es';
import moment from 'moment';
/** Build components from antd css framework */
import { Empty, Row, Col, Spin, Icon, Tooltip } from 'antd';

import {
  getFlattenActivities,
  weeksInYear,
  getTodayWeekWithPday,
  recursiveTasksFilterWp,
  getTodayWeekWithPdayCommitments
} from '../../../utils';
import { sectorBaselineVersionService } from '../../../services/index';
import { taskService } from '../../../services/index';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';
import { weeklyplanActions } from '../../../redux/actions/weeklyplanActions';
import { calendarService } from '../../../services';
import { sectorService } from '../../../services/sector.service';
import {
  activityService,
  userService,
  weekCommitmentService,
  subContractService
} from '../../../services';
import { getActivitiesforWeeklyPlanForWidget } from '../../../utils';
import cloneDeep from 'lodash/cloneDeep';

import { WeeklyPlanCommitmentToolbar } from '../../../components/WeeklyPlan/Commintments/components/WeeklyplanCommitmentToolbar/WeeklyplanToolbar';
import CncForm from '../../../components/WeeklyPlan/Cnc/CncForm';
import { getListCncs, getWeekCommitments } from '../weeklyPlan.helper';
import { getCncList, getTasksWithRoute } from '../cncs/cncs.helper';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Manpower from '../../../components/WeeklyPlan/Commintments/components/ManpowerInfo/ManpowerInfo';
import useSubtradeUser from '../../../hooks/useSubtradeUser';

import { DeletedTaskIcon } from '../../../icons';
import ModalSystem from '../../../components/DesignSystem/ModalSystem';
import { ModalContentTasksCommitmentDeleted } from './ModalContentTasksCommitmentDeleted/ModalContentTasksCommitmentDeleted';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const Commitmens = (props) => {
  const { permission, t } = props;

  const customPaginator = useRef({});

  const [currentPage, setCurrentPage] = useState(0);
  const [tableConfig, setTableConfig] = useState([]);
  const [days, setDays] = useState([]);
  const [groupBy, setGroupBy] = useState({
    criteria: 'activityId',
    sort: 'asc'
  });
  const [visibleFormCnc, setVisibleFormCnc] = useState({
    value: false,
    tasks: []
  });

  // weekly plan data

  const [isProgressColumns, setIsProgressColumns] = useState({
    progress: true,
    productivity: false
  });
  const [weekcommitment, setWeekcommitment] = useState(false);
  const [weekcommitmentClosed, setWeekcommitmentClosed] = useState(null);
  const [yearState, setYearState] = useState(moment().format('YYYY'));
  const [prevYear, setPrevYear] = useState(
    parseInt(moment().format('YYYY')) - 1
  );
  const [currentWeek, setCurrentWeek] = useState(null);
  const [currentWeekWithPday, setCurrentWeekWithPday] = useState();
  const [showInitView, setShowInitView] = useState(true);

  const [allWeekCommitments, setAllWeekCommitments] = useState([]);
  const [allTaskCommitments, setAllTaskCommitments] = useState([]);
  const [allActivitiesCommitments, setAllActivitiesCommitments] = useState([]);
  const [taskscommitmentsDeleted, setTaskscommitmentsDeleted] = useState([]);
  const [dataNoGroup, setDataNoGroup] = useState({}); /** state initial data */
  const [tasksAddedCnc, setTasksAddedCnc] = useState([]);
  const [resources, setResources] = useState([]);
  const subtradeRole = useSubtradeUser();
  const ELEMENT_VIEW = {
    LOADING: 'loading',
    NO_COMMITTED_SELECTED_WEEK: 'noCommittedSelectedWeek',
    COMMITTED_SELECTED_WEEK: 'committedSelectedWeek'
  };
  /** Object to handle start and end dates to show last level activities from master plan  */
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  /** redux */
  const stateProject = useSelector((state) => state.projectState);

  /** Project state from redux */
  const projectState = useSelector((state) => state.projectState);
  const weeklyplanState = useSelector((state) => state.weeklyplanState);
  const dispatch = useDispatch();

  /** get cncs by sector */
  const cncListRq = useQuery(['cncListRq', stateProject.sectorSelected], () =>
    getListCncs(stateProject.sectorSelected)
  );
  /** get week commitments */
  const weekCommitmentsListRq = useQuery(
    ['weekCommitmentsListRq', stateProject.sectorSelected],
    () => getWeekCommitments(projectState.sectorSelected)
  );
  /** Flag to know when component is on loading step */
  const [isLoading, setLoading] = useState(true);
  const [existFilter, setExistFilter] = useState(false);
  const [toSelectResponsables, setToSelectResponsables] = useState([]);
  const [subContracts, setSubcontracts] = useState([]);
  const [showModalDeletedTasks, setShowModalDeletedTasks] = useState(false);

  /** Activities to handle table loading */
  const [activities, setActivities] = useState([]);
  const [lastLevelActivities, setLastLevelActivities] = useState({});
  const [haveWeeklyPlan, setHaveWeeklyPlan] = useState(false);
  const [sectorWidget, setSectorWidget] = useState(null);
  const [sectorbaselineversion, setSectorbaselineversion] = useState(null);
  const [sectorpoins, setSectorpoins] = useState(null);
  const [sectorpoinsall, setSectorpoinsall] = useState(null);
  /** Handle tasks of weekly plan */
  const [tasksWeeklyPlan, setTasksWeeklyPlan] = useState([]);

  const { width: windowWidth } = useWindowDimensions();

  const updateRender = (ac = activities) => {
    setActivities([...ac]);
  };

  const [planification_day, setPlanification_day] = useState('');
  /** Calendar to handle table loading */
  const [calendars, setCalendars] = useState([]);

  const checkIfExistsWeeklyPlan = async (week) => {
    const data = {
      week: parseInt(week),
      sectorId: parseInt(projectState.sectorSelected),
      year: yearState
    };
    const have = await weekCommitmentService.searchbyweek(data);

    let ret = false;
    if (have.commitment && have.commitment.id) {
      ret = have.commitment;
    }
    return ret;
  };
  const assignInitialCommitmentPercentaje = (arrayData) => {
    const reference = cloneDeep(arrayData);
    const ret = arrayData.activities.map((el) => {
      const tasksRecursive = recursiveTasks1(el.tasks, reference);
      return {
        ...el,
        tasks: tasksRecursive
      };
    });
    // .filter(el => el.tasks.length)
    return ret;
  };

  useEffect(() => {
    if (cncListRq.isSuccess && cncListRq.data.cncs) {
      getListsTasks();
    }
  }, [cncListRq.isSuccess]);

  const getListsTasks = () => {
    /** get cncs */
    const listTmp = getCncList(cncListRq);
    if (listTmp.cncTasksArr) {
      setTasksAddedCnc(listTmp.cncTasksArr);
    }
  };

  /** get tasks recursively */
  const recursiveTasks1 = (array, reference) => {
    /** return mapped array */
    return array.map((task) => {
      /** if (task.children) {
                let findActivity
                if (task.activityId) {
                    findActivity = reference.activities.find(el => el.id === task.activityId)
                    if (findActivity) {
                        task.expected_cost = calculateExpectedCost(task, ganttAPI, findActivity.calendarId)
                    }
                }

                const expWeek = calculateExpectedForWeek(task, ganttAPI, findActivity.calendarId, null, dateRange)
                task.commitment_percentaje = parseFloat(expWeek).toFixed(2)
                task.expectedweek = parseFloat(expWeek).toFixed(2)

                if (task.commitment_percentaje < task.progress) {
                    let quantity_parcial = 0
                    let newCompromise = task.progress
                    if (task.total_quantity) {
                        newCompromise = task.progress + quantity_parcial * 100 / task.total_quantity
                    }
                    task.commitment_percentaje = newCompromise
                    quantity_parcial = 0
                }
                const quantity_parcial_percentaje = parseFloat(parseFloat(task.commitment_percentaje || 0) - parseFloat(task.progress))
                task.quantity_parcial_percentaje = quantity_parcial_percentaje

                recursiveTasks1(task.children, reference)
            } */
      return task;
    });
  };

  const [data, setData] = useState([]);

  const renderExtraItemsNavigationWeek = () => {
    return (
      <div className="week-navigate">
        <Row className="">
          <div
            className="div-week"
            style={{ zIndex: 100, position: 'relative' }}>
            <Col span={8} className="week-left">
              <LeftOutlined onClick={() => changeCurrentWeek(-1)} />
            </Col>
            <Col span={8}>
              {currentWeek ? (
                <span>
                  <div className="week-number">
                    {t('weekly_week')} {currentWeek}
                  </div>
                  <div className="range-date">
                    {startCase(
                      moment(dateRange.start)
                        .format('DD MMM')
                        .replace(/\./g, '')
                    )}
                    &nbsp;-&nbsp;
                    {startCase(
                      moment(dateRange.end).format('DD MMM').replace(/\./g, '')
                    )}
                  </div>
                </span>
              ) : (
                <span>
                  <div className="week-number">{t('weekly_select')}</div>
                  <div className="range-date">{t('weekly_init_end')}</div>
                </span>
              )}
            </Col>
            <Col span={8} className="week-right">
              <RightOutlined onClick={() => changeCurrentWeek(+1)} />
            </Col>
          </div>
        </Row>
      </div>
    );
  };

  /** effect to use after close week */
  useEffect(() => {
    if (weekcommitment) {
      const findIndexWeeks = allWeekCommitments.findIndex(
        (week) => week.week === weekcommitment.week
      );
      if (findIndexWeeks !== -1) {
        const tmp = allWeekCommitments[findIndexWeeks];
        tmp.closed = weekcommitment.closed;
        allWeekCommitments[findIndexWeeks] = tmp;
        setAllWeekCommitments(allWeekCommitments);
      }
    }
  }, [weekcommitment?.closed]);

  useEffect(() => {
    const tmpYear = moment().format('YYYY');
    if (currentWeek === 1 && parseInt(prevYear) === parseInt(tmpYear)) {
      changeCurrentWeek('-0');
    }
    if (currentWeek === 1 && parseInt(prevYear) !== parseInt(tmpYear)) {
      changeCurrentWeek('+0');
    }
    if (currentWeek === 0 && parseInt(prevYear) !== parseInt(tmpYear)) {
      changeCurrentWeek('+1');
    }
  }, [currentWeek]);

  /** side effect for currentWeek */
  useEffect(() => {
    /** get week number */
    if (currentWeek) {
      getRangeDateOfWeek(currentWeek);
      setActivities([]);
      getLookahead(currentWeek);
    }
  }, [currentWeek, showInitView, projectState.sectorSelected]);

  useEffect(() => {
    if (currentWeek && weekCommitmentsListRq.isSuccess) {
      const currentWeekSelected =
        weekCommitmentsListRq?.data?.weekcommitments.find(
          (weekCommitment) => weekCommitment.week === currentWeek
        );
      if (currentWeekSelected) {
        const currentTasksDeleted = currentWeekSelected.taskcommitments.filter(
          (currentTask) => currentTask.taskId === null
        );
        setTaskscommitmentsDeleted(currentTasksDeleted);
        return;
      }

      setTaskscommitmentsDeleted([]);
    }
  }, [
    currentWeek,
    weekCommitmentsListRq.data,
    weekCommitmentsListRq.isSuccess
  ]);

  const getRangeDateOfWeek = async (week) => {
    // fix week 2021
    // if (week === 1) {
    //     week = 2
    // }
    // setCurrentWeek(week)
    const today = new Date();
    const year = moment(new Date()).format('YYYY');
    /** get day 3 of week */
    const project = projectState.allProjects.find(
      (pr) => pr.id === projectState.projectSelected
    );
    let planificationDay;
    if (year === '2024' && yearState == '2023') {
      week -= 0;
    }
    if (year === '2022') {
      week -= 0;
    }
    // eslint-disable-next-line prefer-const
    let temp_yearState = yearState;
    let day3ofWeek = moment(temp_yearState + '/01/01', 'YYYY/MM/DD')
      .isoWeek(week)
      .day(3);

    if (parseInt(year) !== parseInt(temp_yearState)) {
      day3ofWeek = moment(`${temp_yearState}/01/07`, 'YYYY/MM/DD')
        .isoWeek(week)
        .day(3);
    }
    const mm = day3ofWeek.format('MM');
    const dd = day3ofWeek.format('DD');

    let fixDay3ofWeek;
    if (week === 0 && mm === '12') {
      fixDay3ofWeek = moment(
        `${parseInt(temp_yearState) - 1}/${mm}/${dd}`,
        'YYYY/MM/DD'
      );
    } else {
      fixDay3ofWeek = moment(
        `${parseInt(temp_yearState)}/${mm}/${dd}`,
        'YYYY/MM/DD'
      );
    }

    if (project.planification_day == 0) {
      planificationDay = -1;
    } else {
      planificationDay = project.planification_day - 1;
    }
    setPlanification_day(project.planification_day);

    /** get first day of week */

    const from_date = fixDay3ofWeek
      .clone()
      .startOf('isoWeek')
      .add(planificationDay, 'day');
    const to_date = from_date.clone().add(6, 'day');

    const start = from_date.toISOString().split('T')[0].split('-').join('/');
    const end = to_date.toISOString().split('T')[0].split('-').join('/');
    // console.log("dateRange.start d",start);
    /** Then using set state hook load this vars, to let virtual dom load datepickers with correct dates */
    const week0 = getTodayWeekWithPdayCommitments(projectState);
    setDateRange((prev) => {
      prev.start = start;
      prev.end = end;
      return prev;
    });
  };

  const changeCurrentWeek = (add) => {
    const formatDate = 'YYYY/MM/DD';
    if (currentWeek == 1 && yearState == 2024 && add == '-1') {
      setPrevYear(parseInt(yearState));
      setYearState(2023);
      setCurrentWeekWithPday(52);
      setCurrentWeek(52);
      setShowInitView(true);
    } else if (currentWeek == 51 && yearState == 2023 && add == '+1') {
      setPrevYear(parseInt(yearState));
      setYearState(2023);
      setCurrentWeekWithPday(52);
      setCurrentWeek(52);
      setShowInitView(true);
    } else if (currentWeek == 52 && yearState == 2023 && add == '+1') {
      setPrevYear(parseInt(yearState));
      setYearState(2024);
      setCurrentWeekWithPday(1);
      setCurrentWeek(1);
      setShowInitView(true);
    } else {
      const week0 = parseInt(getTodayWeekWithPday(projectState));
      setPrevYear(parseInt(yearState));
      setCurrentWeekWithPday(week0);

      /** get week number */
      /** number of weeks of the year */
      const numberOfWeeks = weeksInYear(yearState);
      let weekNumber = currentWeek + parseInt(add);
      if (weekNumber > numberOfWeeks && numberOfWeeks != 0) {
        weekNumber = weekNumber - numberOfWeeks;
        setYearState(parseInt(yearState) + 1);
      }
      if (weekNumber === 0) {
        const numberOfWeeks = weeksInYear(parseInt(yearState) - 1);
        weekNumber = numberOfWeeks;
        setYearState(parseInt(yearState) - 1);
      }
      setCurrentWeek(weekNumber);
      setShowInitView(true);
    }
  };

  useEffect(() => {
    if (
      weekCommitmentsListRq.isSuccess &&
      weekCommitmentsListRq.data.weekcommitments
    ) {
      const weekData = weekCommitmentsListRq.data.weekcommitments;
      allTaskCommitmentsFn(weekData);
    }
  }, [weekCommitmentsListRq.data]);

  useEffect(() => {
    /** get week number */
    const todayWeek = getTodayWeekWithPday(projectState);
    setCurrentWeekWithPday(todayWeek);

    /** set range according current week */
    getRangeDateOfWeek(todayWeek);
    setCurrentWeek(todayWeek);
    /** Loads activities with their tasks */
    // getLookahead(todayWeek);
  }, [projectState.sectorSelected]);

  useEffect(() => {
    if (dateRange.start != '') {
      const tableColumns = tableMetadata.filter((column) => {
        if (!column.showColumCommitment) return false;
        if (isProgressColumns.progress)
          return column.isProgress == isProgressColumns.progress;

        return column.isProductivity == isProgressColumns.productivity;
      });

      setDays([
        getDayTextFormart(0),
        getDayTextFormart(1),
        getDayTextFormart(2),
        getDayTextFormart(3),
        getDayTextFormart(4),
        getDayTextFormart(5),
        getDayTextFormart(6)
      ]);
      setTableConfig(tableColumns);
    }
  }, [dateRange.start, isProgressColumns]);

  const getDayTextFormart = (correlative) => {
    // console.log("dateRange.start",dateRange.start);
    return (
      moment(dateRange.start)
        .clone()
        .locale(t('lang'))
        .add('days', correlative)
        .format('ddd') +
      ' ' +
      moment(dateRange.start).clone().add('days', correlative).format('DD')
    );
  };

  /**
   * This function get activities and responsables to use them at DOM
   */
  async function getLookahead(
    week = currentWeek,
    avoidSetState = false,
    reload = true
  ) {
    if (reload) setLoading(true);
    const calendars = await calendarService.showBySector(
      projectState.sectorSelected
    );
    setCalendars(calendars.calendar);

    const haveWeekly = await checkIfExistsWeeklyPlan(week);
    setHaveWeeklyPlan(haveWeekly);

    const sectorResp = await sectorService.showByLooaheadWeekly(
      projectState.sectorSelected
    ); // getSectorsByProject(idProject)
    setSectorWidget(sectorResp.sector);
    const sectorBeslinePoint =
      await sectorBaselineVersionService.showBySectorActive(
        projectState.sectorSelected
      );
    if (sectorBeslinePoint) {
      const points = getActivitiesforWeeklyPlanForWidget(
        sectorBeslinePoint.sectorbaselineversion.points,
        { start: dateRange.start, end: dateRange.end }
      );
      setSectorbaselineversion(sectorBeslinePoint.sectorbaselineversion);
      setSectorpoins(points);
      setSectorpoinsall(sectorBeslinePoint.sectorbaselineversion.points);
      /** set load to true */
    }

    // if (!avoidLoading.value) {
    //    setLoading(true)
    // }

    setCurrentPage(0);
    const objGetLookAhead = {
      sector_id: projectState.sectorSelected,
      start: dateRange.start,
      end: dateRange.end,
      ignore_dates: true,
      week: currentWeek,
      year: yearState
    };
    // getLookaheadWeeklyPlan
    const lastLevelActivitiesGet =
      await activityService.getLookaheadWeeklyPlanCommitment(objGetLookAhead);

    setWeekcommitment(lastLevelActivitiesGet.weekcommitment);
    assignInitialCommitmentPercentaje(lastLevelActivitiesGet);
    const resources = lastLevelActivitiesGet.resource;
    setLastLevelActivities(lastLevelActivitiesGet);

    /** mark showWeeklyPlan recursively */

    /** get Subcontracts */
    const subcontractsGet = await subContractService.getByProject(
      projectState.projectSelected
    );
    /* if (subcontractsGet) {
            //const typeFrom = tableConfig.find(col => col.name == 'subcontractId')
            //typeFrom.from_values.splice(0, typeFrom.from_values.length)
            const subContractsOrdered = subcontractsGet.subcontracts.sort(dynamicSort('name'));

            subContractsOrdered.map((type, index) => {
                typeFrom.from_values.push({
                    value: type.id,
                    label: type.name,
                    weigth: (index + 1)
                })
            })
           // setTableConfig([...tableConfig])
            setSubcontracts(subContractsOrdered)
        } */

    /* const materialFrom = tableConfig.find(col => col.identifier == 'material')
        materialFrom.from_values.splice(0, materialFrom.from_values.length)

        resources && resources.map((resource, index) => {
            const toPush = {
                value: resource.id,
                label: resource.name,
                weigth: (index + 1)
            }

            switch (resource.type) {
            case 'material':
                materialFrom.from_values.push(toPush)
                break
            }
        }) */

    setResources(resources || []);

    const responsables = await userService.getBySector(
      projectState.sectorSelected
    );
    setToSelectResponsables(responsables.users);

    setLastLevelActivities(lastLevelActivitiesGet);
    if (avoidSetState) {
      return lastLevelActivitiesGet;
    }
    setWeekcommitmentClosed(true);
    return refreshView(lastLevelActivitiesGet, false, haveWeekly);
  }

  const refreshView = (
    lastLevelActivitiesRefresh = lastLevelActivities,
    updateview = false,
    haveWeeklyData = null,
    render = true
  ) => {
    /** map activities and set hidden, according to filters */
    const lastLevelActivitiesFiltered = lastLevelActivitiesRefresh.activities;
    const filterWeek = ['Committed'];

    /** sort by default */
    const data = recursiveTasksFilterWp(
      lastLevelActivitiesFiltered,
      filterWeek,
      dateRange
    );
    const dataFilterActivities = recursiveActivityFilter(data);
    const dataFilterActivitiesShow = dataFilterActivities; // .filter(el => el.showWeeklyPlan === true)
    const cloneActivities = dataFilterActivitiesShow.filter(
      (el) => el.tasks.length
    );
    // const cloneActivities = cloneDeep(dataFilterActivitiesShow).filter(el => el.tasks.length)
    const flattenActivities = getFlattenActivities(cloneActivities).filter(
      (el) => el.showWeeklyPlan
    );
    setTasksWeeklyPlan(flattenActivities);
    if (haveWeeklyData) {
      /** recalc activity on component activiy */
      cloneActivities.map((act) => {
        act.recalcCommitment = true;
        return act;
      });
    }

    /** If data were fetch succesfully, we pass this data to component states */
    if (lastLevelActivities || updateview) {
      if (render) setLoading(false);
      // console.log('cloneActivities',cloneActivities);
      setActivities(
        cloneActivities.sort(
          (a, b) => parseInt(a.correlative_id) - parseInt(b.correlative_id)
        )
      );
    }
    return cloneActivities;
  };

  const recursiveActivityFilter = (array) => {
    /** return mapped array */
    return array.map((el) => {
      const tmpArr = [];
      tmpArr.push(el);
      const getFlatten = getFlattenActivities(tmpArr);
      const haveWp = getFlatten.some((el) => el.showWeeklyPlan);
      el.showWeeklyPlan = haveWp;
      return el;
    });
  };

  const allTaskCommitmentsFn = async (weeks) => {
    setAllWeekCommitments(weeks);
    const allTasks = [];
    weeks.map((el) => {
      if (el.taskcommitments.length) {
        el.taskcommitments.map((tc) => {
          allTasks.push(tc);
        });
      }
    });
    const tasks = await getTasksWithRoute(
      allTasks,
      projectState.sectorSelected,
      taskService
    );
    setAllTaskCommitments(tasks?.tasks || []);
    setAllActivitiesCommitments(tasks?.activities || []);
  };

  /**
   * This function render Form Add Constraint
   */
  const renderFormCnc = () => {
    return (
      <CncForm
        allWeekCommitments={allWeekCommitments}
        allTaskCommitments={allTaskCommitments}
        allActivitiesCommitments={allActivitiesCommitments}
        cncListRq={cncListRq}
        t={t}
        visibleForm={visibleFormCnc.value}
        setVisibleForm={(val) => setVisibleFormCnc({ value: val, tasks: [] })}
        getCncList={getCncList}
        closeForm={true}
        setCncAdded={() => {}}
        responsables={toSelectResponsables}
        setDataNoGroup={setDataNoGroup}
        weekDefault={currentWeek}
        tasksSelected={visibleFormCnc.tasks}
      />
    );
  };

  const handleAddCnc = (task) => {
    setVisibleFormCnc({ value: true, tasks: [task] });
  };

  const splitText = (text = '', textWidth) => {
    let tooltip;
    let showedText = text;
    let colAdjustWidth;
    const textAdjustWidth = showedText ? showedText.length * 2 : 100;

    // eslint-disable-next-line prefer-const
    colAdjustWidth = Math.trunc(textWidth / 3);

    if (textAdjustWidth > colAdjustWidth) {
      showedText = text.slice(0, Math.trunc(colAdjustWidth / 3)) + '...';
      tooltip = true;
    }
    return tooltip ? (
      <Tooltip placement="top" title={text}>
        {showedText}
      </Tooltip>
    ) : (
      showedText
    );
  };

  if (
    permission.plan != 'ACP' &&
    permission.plan != 'AC' &&
    permission.plan != 'V'
  ) {
    return <div>{t('non_permission_access')}</div>;
  }

  const shouldRenderElement = (element) => {
    const isSelectedWeek = currentWeek === weekcommitment?.week;

    const renderLoading = ({ isLoading }) => isLoading;
    const renderCommittedSelectedWeek = ({ isSelectedWeek, isLoading }) =>
      isSelectedWeek && !isLoading;
    const renderNoCommittedSelectedWeek = ({
      isSelectedWeek,
      activities,
      isLoading
    }) => (!isSelectedWeek || !activities.length) && !isLoading;

    const viewElementConditions = {
      [ELEMENT_VIEW.LOADING]: renderLoading,
      [ELEMENT_VIEW.NO_COMMITTED_SELECTED_WEEK]: renderNoCommittedSelectedWeek,
      [ELEMENT_VIEW.COMMITTED_SELECTED_WEEK]: renderCommittedSelectedWeek
    };

    const renderViewElement = viewElementConditions[element];

    if (renderViewElement) {
      return renderViewElement({ isSelectedWeek, activities, isLoading });
    } else {
      return false;
    }
  };

  return (
    <>
      <WeeklyPlanCommitmentToolbar
        changeCurrentWeek={changeCurrentWeek}
        dateRange={dateRange}
        year={yearState}
        activities={activities}
        planificationDay={planification_day}
        currentWeek={currentWeek}
        weekcommitment={weekcommitment}
        sectorId={projectState.sectorSelected}
        t={t}
        setIsProgressColumns={setIsProgressColumns}
        setWeekcommitment={setWeekcommitment}
        setWeekcommitmentClosed={setWeekcommitmentClosed}
        getLookahead={getLookahead}
        resources={resources}
        updateAllTaskCommitments={() => weekCommitmentsListRq.refetch()}
        setShowModalDeletedTasks={setShowModalDeletedTasks}
      />
      <div className={styles.header}>
        <div>
          {tableConfig.map((column) => (
            <span
              key={column.label}
              className={cn(styles.columns, styles.table_column, {
                [styles.column_commited]: column.name == 'column/combine'
              })}>
              {column.data_type.includes('/icon') ||
              column.data_type.includes('/string')
                ? t('weekcommitment.columns.' + column.name + '.label')
                : splitText(t('weekcommitment.columns.' + column.name), 81)}
            </span>
          ))}
        </div>
        <div>
          {days.map((day) => (
            <span key={day} className={styles.columns}>
              {day}
            </span>
          ))}
        </div>
        <div>
          <span className={cn(styles.columns, styles.completed)}>
            {t('weekcommitment.completed')}
          </span>
          <span className={cn(styles.columns, styles.rfv)}>
            {t('weekcommitment.rfv')}
          </span>
        </div>
      </div>
      <div className={styles.activity__row} ref={customPaginator}>
        {shouldRenderElement(ELEMENT_VIEW.LOADING) && (
          <Spin className={styles['loading-container']} indicator={antIcon} />
        )}
        {shouldRenderElement(ELEMENT_VIEW.NO_COMMITTED_SELECTED_WEEK) && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>{t('weekly_plan_plan.no_tasks')}</span>}
          />
        )}
        {shouldRenderElement(ELEMENT_VIEW.COMMITTED_SELECTED_WEEK) && (
          <CustomPaginator
            data={[{}, ...activities]}
            current={currentPage}
            setCurrentPage={setCurrentPage}
            widthParent={customPaginator.current.clientWidth}
            perPage={1}
            weeklyCommitments
            renderItem={(activity, key) => (
              <>
                {key > 0 && (
                  <CommitmentsActivity
                    subtradeRole={subtradeRole}
                    permission={permission}
                    key={key}
                    isProgressColumns={isProgressColumns}
                    resources={resources}
                    activity={activity}
                    week={currentWeek}
                    year={yearState}
                    planificationDay={planification_day}
                    calendars={calendars}
                    cncListRq={cncListRq}
                    tasksAddedCnc={tasksAddedCnc}
                    handleAddCnc={handleAddCnc}
                    weekcommitment={weekcommitment}
                    t={t}
                  />
                )}
              </>
            )}
          />
        )}
      </div>
      <Manpower
        activities={activities}
        week={currentWeek}
        planificationDay={planification_day}
        year={yearState}
        calendars={calendars}
        t={t}
      />

      {ModalSystem({
        theme: 'dark',
        title: `${t('weekcommitment.modal_deleted_tasks_title')} (${t('weekcommitment.modal_deleted_tasks_week')} ${currentWeek} - ${moment(dateRange.start).format('DD MMM').replace(/\./g, '')})`,
        width: 780,
        showIconTitle: true,
        iconTitle: <DeletedTaskIcon color="#7DFF8A" />,
        centered: true,
        visible: showModalDeletedTasks,
        setVisible: setShowModalDeletedTasks,
        children: ModalContentTasksCommitmentDeleted(
          taskscommitmentsDeleted,
          isProgressColumns,
          weekcommitment,
          t
        )
      })}

      {renderFormCnc()}
    </>
  );
};

export default Commitmens;
