import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { notification } from 'antd';

import * as icon from '../../../../../icons';
import {
  TOOLBAR_MIDDLE_GROUP_ITEMS,
  TOOLBAR_RIGTH_GROUP_ITEMS
} from './constants';
import { ToolbarGroup } from '../../../../ToolbarGroup';

import { CloseWeekModal } from '../CloseModal/CloseWeekModal';

import styles from './WeeklyplanToolbar.module.scss';
import { projectService } from '../../../../../services/project.service';
import useWeeklyplanPermissions from '../../../../../hooks/useWeeklyplanPermissions';
import { sectorService } from '../../../../../services';

import XlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
import {
  getEnvironment,
  getTodayWeekWithPdayAndCurrentDay
} from '../../../../../utils';

export const WeeklyPlanCommitmentToolbar = (props) => {
  const {
    activities,
    currentWeek,
    changeCurrentWeek,
    dateRange,
    planificationDay,
    weekcommitment,
    t = (_) => {},
    sectorId,
    year,
    setWeekcommitment,
    setIsProgressColumns,
    setWeekcommitmentClosed,
    getLookahead,
    resources,
    updateAllTaskCommitments,
    setShowModalDeletedTasks
  } = props;

  const [isCloseWeek, setCloseWeek] = useState(false);
  const [refreshExcel, setRefreshExcel] = useState(true);
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [headerexcel, setHeaderexcel] = useState([]);
  const header = [
    t('week_commitment_activity'),
    t('week_commitment_task'),
    t('week_commitment_responsable'),
    t('week_commitment_commitment'),
    t('week_commitment_progress'),
    t('week_commitment_material'),
    t('week_commitment_week'),
    t('week_commitment_material_real'),
    t('week_commitment_subtrade'),
    t('week_commitment_crew'),
    t('tables.lookahead.plan.total_quantity')
  ];

  const projectState = useSelector((state) => state.projectState);
  const stateCompany = useSelector((state) => state.companyState);
  const noRequireResources = !stateCompany?.currentCompany?.require_resources;
  const history = useHistory();

  /** Permissions */
  const weeklyPlanPermissions = useWeeklyplanPermissions();
  const isOnlyReadElement = ['V', 'ACP'].includes(weeklyPlanPermissions.plan);
  const notAccessCloseWeekRoles = ['ACP'];

  useEffect(() => {
    TOOLBAR_MIDDLE_GROUP_ITEMS[0][1].props = { currentWeek, dateRange, t };
  }, [currentWeek, dateRange]);

  useEffect(() => {
    const data_export = [];
    if (activities.length) {
      activities.map((activty) => {
        recursiveTaskPuch(activty, activty.tasks, data_export);
      });
    }
    setFinalData(data_export);
  }, [activities]);

  const recursiveTaskPuch = (activty, tasks, data_export) => {
    tasks.map((task) => {
      if (task.taskcommitments.length > 0) {
        const responsable =
          task.responsables.length > 0
            ? task.responsables[0].name + ' ' + task.responsables[0].lastname
            : '';
        const real_material = (task.progress / 100) * task.total_quantity;
        const progreso_temp =
          task.taskcommitments.length > 0
            ? task.taskcommitments[0].weekcommitment.closed
              ? task.taskcommitments[0].current_progress_task
              : task.progress
            : task.progress;
        const commitment_material =
          task.taskcommitments.length > 0
            ? task.taskcommitments[0]?.total_quantity
              ? ((task.taskcommitments[0].commitment_percentaje -
                  task.taskcommitments[0].current_progress_task) /
                  100) *
                task.taskcommitments[0]?.total_quantity
              : '-'
            : '0';

        data_export.push({
          route: activty.activityRoute,
          name: task.name,
          responsables: responsable,
          commitment:
            task.taskcommitments.length > 0
              ? task.taskcommitments[0].commitment_percentaje
              : 0,
          progress:
            task.taskcommitments.length > 0
              ? task.taskcommitments[0].weekcommitment.closed
                ? task.taskcommitments[0].realized_percentaje
                : task.progress
              : task.progress,
          resource: resources.find((resource) => resource.id == task.materialId)
            ?.name,
          resource_real_commit: commitment_material,
          resource__commit: real_material,
          company: task?.subcontract?.name,
          crew_size: task?.plan_endowment,
          total_quantity: task.taskcommitments[0]?.total_quantity
        });
      }
      if (task.children.length > 0) {
        recursiveTaskPuch(activty, task.children, data_export);
      }
    });
  };

  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : '';
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  useEffect(() => {
    if (!weeklyPlanPermissions) return; // lookaheadPermissions replaced by weeklyPlanPermissions
    const { plan } = weeklyPlanPermissions; // lookaheadPermissions replaced by weeklyPlanPermissions

    if (weekcommitment) {
      /** Permission ACP is a custom permission behaviour so, must be stopped */
      if (notAccessCloseWeekRoles.includes(plan)) {
        TOOLBAR_RIGTH_GROUP_ITEMS.pop();
        setCloseWeek(weekcommitment.closed || false);
        window.blockEditionProgress = weekcommitment.closed;
        return;
      }

      const objectRange = getTodayWeekWithPdayAndCurrentDay(
        projectState,
        moment()
      );
      const loadedWeek = weekcommitment.week;
      const currentWeek = objectRange[0];

      setCloseWeek(weekcommitment.closed || false);

      if (!notAccessCloseWeekRoles.includes(plan)) {
        window.blockEditionProgress = weekcommitment?.closed;

        if (!weekcommitment?.closed && loadedWeek === currentWeek) {
          if (isOnlyReadElement)
            getTooltipBehaivor(true, t('not_permissions_actions'));

          getToolbarBehaivor(
            'weekcommitment.close_week',
            'CLOSE_WEEK',
            icon.CalendarIcon
          );
          TOOLBAR_RIGTH_GROUP_ITEMS[
            TOOLBAR_RIGTH_GROUP_ITEMS.length - 1
          ][0].invertTextColor = true;
        } else {
          if (isOnlyReadElement) getTooltipBehaivor(false, null);

          getToolbarBehaivor(
            'weekcommitment.lookahead',
            'GO_TO_LOOKAHED',
            icon.LookaheadRangeIcon
          );
        }
      }
    } else if (!notAccessCloseWeekRoles.includes(plan)) {
      window.blockEditionProgress = true;

      getTooltipBehaivor(false, null);
      getToolbarBehaivor(
        'weekcommitment.lookahead',
        'GO_TO_LOOKAHED',
        icon.LookaheadRangeIcon
      );
    }
  }, [weekcommitment]);

  const getToolbarBehaivor = (i18nKey, command, icon) => {
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].i18nKey =
      i18nKey;
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].command =
      command;
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].icon =
      icon;
  };

  const getTooltipBehaivor = (disabled, tooltip) => {
    TOOLBAR_RIGTH_GROUP_ITEMS[
      TOOLBAR_RIGTH_GROUP_ITEMS.length - 1
    ][0].disabled = disabled;
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].tooltip =
      tooltip;
  };

  const handleMiddleToolbarActions = (command) => {
    const val = command == 'CHANGE_PREV_WEEK' ? -1 : +1;
    changeCurrentWeek(val);
  };

  const handleRightToolbarActions = (command) => {
    const commandActions = {
      GO_TO_LOOKAHED: (_) => history.push('/lookahead/planification'),
      DOWNLOAD_SCHEDULE: (_) => {
        if (!activities.length) {
          notification.error({
            message: t('weekcommitment.error_task'),
            description: t('weekcommitment.no_task')
          });
          return;
        }

        const dateStart = moment(dateRange.start)
          .format('YYYY-MM-DD')
          .toString();
        const dateEnd = moment(dateRange.end).format('YYYY-MM-DD').toString();
        history.push(
          `/report/week/all/${currentWeek}/${planificationDay}/${year}/${dateStart}/${dateEnd}`
        );
      },
      DOWNLOAD_SCHEDULE_EXCEL: (_) => {
        // alert("holiii")
        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
          const sheet1 = workbook.sheet(0);
          const sheetData = getSheetData(finalData, header);
          const totalColumns = sheetData[0].length;

          sheet1.cell('A1').value(sheetData);
          const range = sheet1.usedRange();
          const endColumn = String.fromCharCode(64 + totalColumns);
          sheet1.row(1).style('bold', true);
          sheet1.range('A1:' + endColumn + '1').style('fill', 'BFBFBF');
          range.style('border', true);
          return workbook.outputAsync().then((res) => {
            saveAs(res, 'file.xlsx');
          });
        });
      },
      PROGRESS_COLS: (_) => {
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[0].active = true;
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[0].itemColor = '#FFFFFF';
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[1].active = false;
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[1].itemColor = '#121212';
        setIsProgressColumns({ progress: true, productivity: false });
      },
      PRODUCTIVITY_COLS: (_) => {
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[0].active = false;
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[0].itemColor = '#121212';
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[1].active = true;
        TOOLBAR_RIGTH_GROUP_ITEMS[1].switchable[1].itemColor = '#FFFFFF';
        setIsProgressColumns({ progress: false, productivity: true });
      },
      CLOSE_WEEK: (_) => {
        setVisibleModal(true);
      },
      SHOW_MODAL_DELETED_TASK: (_) => {
        setShowModalDeletedTasks(true);
      }
    };

    commandActions[command]();
  };

  const handleHiddeModal = (_) => setVisibleModal(false);

  const saveForwardWeeklyData = async (_) => {
    const currentSector = JSON.parse(sessionStorage.getItem('currentSector'));
    // console.log('We are on week')
    const finalData = { ...currentSector };
    /** NOTE: We validate,
     * that user only can go forward one week, so then in this code below,
     * we know where user did already close a week, but with a cron,
     * we will be checking if the current week reach the last closed week, then if their match,
     * this two flags will be reseted */
    if (currentWeek) {
      finalData.currentClosedWeek = currentWeek;
      finalData.didCloseWeek = true;
      /** Data should be saved */
      await sectorService.update(finalData);
      sessionStorage.setItem('currentSector', JSON.stringify(finalData));
    }
  };

  const handleCloseWeek = async (_) => {
    handleHiddeModal();
    projectService.closecommitbysector(sectorId, currentWeek, year);
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].i18nKey =
      'weekcommitment.lookahead';
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].command =
      'GO_TO_LOOKAHED';
    TOOLBAR_RIGTH_GROUP_ITEMS[TOOLBAR_RIGTH_GROUP_ITEMS.length - 1][0].icon =
      icon.LookaheadRangeIcon;
    const cloneweekcommit = { ...weekcommitment };
    cloneweekcommit.closed = true;
    await getLookahead(currentWeek);
    // setWeekcommitmentClosed(null)
    setWeekcommitmentClosed(true);
    setWeekcommitment(cloneweekcommit);
    setCloseWeek(!isCloseWeek);
    updateAllTaskCommitments();
    await saveForwardWeeklyData();
  };

  return (
    <div className={styles.container}>
      <div></div>
      <ToolbarGroup
        items={TOOLBAR_MIDDLE_GROUP_ITEMS}
        onCommandDispatched={handleMiddleToolbarActions}
        t={t}
      />
      <ToolbarGroup
        items={TOOLBAR_RIGTH_GROUP_ITEMS}
        noRequireResources={noRequireResources}
        onCommandDispatched={handleRightToolbarActions}
        t={t}
      />
      <CloseWeekModal
        visible={isVisibleModal}
        hiddeModal={handleHiddeModal}
        closeWeek={handleCloseWeek}
        contentText={t('weekcommitment.content_text')}
        contentsubText={t('weekcommitment.content_subText')}
        cancelText={t('weekcommitment.cancel_text')}
        closeWeekText={t('weekcommitment.close_text')}
      />
    </div>
  );
};
