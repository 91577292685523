import React, { useState } from 'react';
import ReactDOM from 'react-dom';
/** Import elements from library Antd */
import { Tooltip, Input, Popconfirm, Icon } from 'antd';
import { MinusOutlined, DownOutlined } from '@ant-design/icons';
import {
  findDeepGetTask,
  handleDelete,
  updateAsyncTaskGanttV2,
  handleChildAdd,
  handleAdd,
  collapseExpandActivity,
  checkPonderatorIcon,
  isGrouped,
  createTask,
  avoidOptimizationTimelineBlinking,
  updateIsParent,
  updateParentDuration,
  updateTaskMassive,
  assignCorrelativeNumbers
} from '../../GanttVisualization.helper';
import {
  convertPPActivity2PluginTask,
  convertPPTask2PluginTask,
  findDeepGetActivityOfTask
} from '../../../../views/lookahead/planification/index.helper';
import {
  calculatePonderators,
  getTask,
  hasUnfitProblem2 as hasUnfitProblem,
  leanStatusArray,
  hasConstraintHtml,
  splitText
} from '../../../../utils/lookahead-common';
import TooltipHTML from '../../../../components/TooltipHTML';
import PopconfirmHTML from '../../../../components/PopconfirmHTML';

import addSubTaskIcon from '../../../../assets/img/newlookahead/name/child-arrow.png';
import addTaskIcon from '../../../../assets/img/newlookahead/name/add.png';
import deleteIcon from '../../../../assets/img/newlookahead/name/delete.png';
import duplicateIcon from '../../../../assets/img/newlookahead/duplicateactivity/dupli_acti.png';
import { isFeatureOn } from '../../../../utils/featureUtils';
import { ganttAPI } from '../../../../utils/customGanttPlugin';

export const testIds = {
  RENDER_ACTIONS_CONTAINER: 'RENDER_ACTIONS_CONTAINER'
};

const LVL_THREE = 6; /** Task depth allowed. Counter from 0. */

const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_ACTIONS_CONTAINER}>{children}</span>
);

const RenderActions = ({
  task = {},
  column = {
    width: 0
  },
  activities = [],
  gantt = {},
  t = (text) => 'Fake Translation',
  onlyRead = true,
  projectState = {},
  updateAsyncActivity = (...vals) => console.log(vals),
  updateRender = () => console.log('update render fake function'),
  data = {
    data: []
  },
  updateMemoryRefMaterialValues = () => {}
}) => {
  const SHOW_INDICATOR_CONSTRAINT = false;
  const taskId = task?.id ? task.id : 0;
  const columnName = 'ACTIONS';
  const dataTestId =
    'data-testid="' +
    testIds.RENDER_ACTIONS_CONTAINER +
    '_' +
    taskId +
    '_' +
    columnName +
    '"';
  if (!activities || !task)
    return (
      <TestWrapped>
        <div></div>
      </TestWrapped>
    );
  const isOnlyReadElement = onlyRead || task.readonly;
  /** Check if data is defined. Activity don't have this data */
  const item = task;
  /** get reference task (taskFromLookahead) from activities */
  let taskFromLookahead = null;
  if (item?.isTask) {
    /** get reference task (taskFromLookahead) from activities */
    taskFromLookahead = findDeepGetTask(window.activities, 'id', task.id);
  }

  let activityReference = null;
  const findAct = findDeepGetActivityOfTask(window.activities, 'id', task?.id);
  if (findAct) activityReference = findAct;

  /** disabled value for parent tasks, get parent task  */
  let parentTask = null;
  if (taskFromLookahead) {
    if (taskFromLookahead.parent_id) {
      parentTask = findDeepGetTask(
        window.activities,
        'id',
        taskFromLookahead.parent_id
      );
    }
  }

  if (!gantt)
    return (
      <TestWrapped>
        <div></div>
      </TestWrapped>
    );

  /** calculate margin left according level */
  const itemLvl = item?.$level; // ? item.$level : 30
  let marginLeft = 26;
  if (taskFromLookahead?.children?.length) {
    marginLeft = 9;
  }

  /** this function runs after updating the value */
  const updateAsyncTask = async (taskLocal) => {
    gantt.isRenderingOnProcess = false;
    await updateAsyncTaskGanttV2(
      taskLocal,
      column.name,
      taskLocal.name,
      gantt,
      true,
      false,
      false,
      true
    );
  };

  const addCopyOfActivity = async (activity) => {
    return await createTask(
      null,
      'New task',
      activity,
      true,
      projectState,
      updateAsyncActivity,
      updateRender,
      gantt
    );
  };

  const renderActions = (constraint = null) => {
    const addRef = null;

    if (
      !window.ganttVisualization
        ?.lookaheadTemplates_actionsADDTaskRenderActivityTask
    ) {
      window.ganttVisualization.lookaheadTemplates_actionsADDTaskRenderActivityTask =
        async (id, activityReferenceLvl_id) => {
          if (!id || id === null) return;
          const thisTask = gantt.getTask(id);
          const thisActivityReferenceLvl = activityReferenceLvl_id
            ? activities.find((ac) => ac.id == activityReferenceLvl_id)
            : null;

          if (thisTask?.isTask) {
            /** get reference task (taskFromLookahead) from activities */
            taskFromLookahead = findDeepGetTask(activities, 'id', id);
          }
          // addRef && addRef.tooltip.setState({ visible: false })
          const toAdd = await handleChildAdd(
            taskFromLookahead,
            thisActivityReferenceLvl,
            projectState,
            updateAsyncActivity,
            updateRender,
            window.ganttVisualization
          );
          const adaptedTask = convertPPTask2PluginTask(toAdd, null, thisTask);
          delete adaptedTask.parent;
          adaptedTask.activityReference = convertPPActivity2PluginTask(
            thisActivityReferenceLvl
          );
          thisTask.is_parent = true;
          window.ganttVisualization.createTask(adaptedTask, id);

          /** update parent */
          const arrMass = [];
          if (adaptedTask.parent) {
            updateParentDuration(
              adaptedTask.parent,
              adaptedTask.activityReference,
              arrMass,
              gantt,
              ganttAPI,
              projectState
            );
          }
          /** save data */
          updateTaskMassive(arrMass);

          window.ganttVisualization.refreshVisualizations &&
            window.ganttVisualization.refreshVisualizations(adaptedTask);
        };
    }

    if (!window.ganttVisualization?.lookaheadTemplates_actionsDeleteTask) {
      window.ganttVisualization.lookaheadTemplates_actionsDeleteTask = async (
        id,
        activityReferenceLvl_id
      ) => {
        if (!id || id === null) return;
        const thisTask = window.ganttVisualization.getTask(id);
        const thisActivityReferenceLvl = activityReferenceLvl_id
          ? activities.find((ac) => ac.id == activityReferenceLvl_id)
          : null;
        avoidOptimizationTimelineBlinking();
        // addRef.tooltip.setState({ visible: false })
        if (thisTask?.isTask) {
          /** get reference task (taskFromLookahead) from activities */
          taskFromLookahead = findDeepGetTask(window.activities, 'id', id);
        }

        let parentTask = null;
        if (taskFromLookahead) {
          if (taskFromLookahead.parent_id) {
            parentTask = findDeepGetTask(
              window.activities,
              'id',
              taskFromLookahead.parent_id
            );
          }
        }

        await handleDelete(
          taskFromLookahead,
          thisActivityReferenceLvl,
          parentTask,
          updateAsyncTask,
          updateAsyncActivity,
          projectState,
          t,
          updateRender,
          window.ganttVisualization
        );

        /** if parentTask no has children, update this parentTask */
        if (parentTask?.children && parentTask.children?.length === 0)
          updateIsParent(thisTask, parentTask, window.ganttVisualization);

        if (thisActivityReferenceLvl.tasks.length > 0) {
          assignCorrelativeNumbers(thisActivityReferenceLvl.tasks);
        }

        /** update parent */
        const arrMass = [];
        if (taskFromLookahead?.isTask && parentTask?.children.length) {
          updateParentDuration(
            taskFromLookahead.parent_id,
            thisActivityReferenceLvl,
            arrMass,
            gantt,
            ganttAPI,
            projectState
          );
        }
        /** save data */
        if (arrMass.length) {
          updateTaskMassive(arrMass);
        }
      };
    }

    return `<div ${dataTestId}>
                ${
                  isOnlyReadElement
                    ? '-'
                    : `<span
                            class="task-actions"
                            style=" display: inline; visibility: visible"
                        >
                            ${
                              isGrouped()
                                ? ''
                                : `<span style=" display:flex">
                                            <span
                                              onclick="window.ganttVisualization.lookaheadTemplates_actionsADDTaskRenderActivityTask(${item?.id},${activityReference?.id})"
                                              style="cursor: pointer"
                                            >
                                              ${task.$level < LVL_THREE ? `<img src=${addSubTaskIcon} width="15" style="opacity:0.3"/>` : ''}
                                            </span>

                                            <span style="margin-right: -1px; margin-left: 6px; cursor: pointer">
                                                ${constraint}
                                            </span>

                                            <span style="cursor: pointer; margin-left: 7px" class='react-dhtmlx'>
                                                ${PopconfirmHTML({
                                                  task_id: item.id,
                                                  container: `<img src=${deleteIcon} width="15" style="opacity:0.4"/>`,
                                                  name: 'delete_subtask',
                                                  onclick: `window.ganttVisualization.lookaheadTemplates_actionsDeleteTask(${item?.id},${activityReference?.id})`
                                                })}
                                                
                                            </span>
                                        </span>`
                            }
                        </span>`
                }
            </div>`;
  };

  const renderMain = () => {
    // comment
    const addTaskTooltipRef = null;
    const duplicateActivityTooltipRef = null;
    /** is Activity */
    const hideAddBtn =
      gantt.subtradeRole &&
      gantt.subtradeRole.isSubtradeRole &&
      !gantt.subtradeRole.subtrade;
    const haveChildsAct = data?.data?.some(
      (it) => !it.isTask && it.parent == item?.id
    );
    let activityReferenceLvl = null;
    if (!haveChildsAct) {
      activityReferenceLvl = activities.find((ac) => ac.unique_id == item?.id);
    }

    if (activityReferenceLvl) {
      const activity = activityReferenceLvl;
      if (activity.reCalculatePonderator) {
        activity.reCalculatePonderator = false;
        updateAsyncActivity(activity);
        const alreadyCalculatedParents = {};
        let activityAlreadyCalculated = false;
        activity.tasks.map((task) => {
          if (task.parent_id) {
            if (!alreadyCalculatedParents[task.parent]) {
              const parent = getTask(task.parent_id, null, activity);
              calculatePonderators(parent, null, updateAsyncTask, projectState);
              alreadyCalculatedParents[task.parent_id] = true;
            }
          } else if (!activityAlreadyCalculated) {
            calculatePonderators(activity, null, updateAsyncTask, projectState);
            activityAlreadyCalculated = true;
          }
        });
        gantt.render();
      }
    }

    const showDuplicate = !gantt.hasChild(item?.id) && !isGrouped();

    if (!window.ganttVisualization?.lookaheadTemplates_actionsAddTask) {
      window.ganttVisualization.lookaheadTemplates_actionsAddTask = async (
        task_id,
        activityReferenceLvl_id
      ) => {
        if (!task_id || task_id === null) return;
        const thisTask = window.ganttVisualization.getTask(task_id);
        const thisActivityReferenceLvl = activityReferenceLvl_id
          ? activities.find((ac) => ac.id == activityReferenceLvl_id)
          : null;

        avoidOptimizationTimelineBlinking();
        // addTaskTooltipRef && addTaskTooltipRef.tooltip.setState({ visible: false })
        const toAdd = await handleAdd(
          thisActivityReferenceLvl,
          projectState,
          updateAsyncActivity,
          updateRender,
          window.ganttVisualization
        );
        const adaptedTask = convertPPTask2PluginTask(toAdd, null, thisTask);
        delete adaptedTask.parent;
        window.ganttVisualization.createTask(adaptedTask, task_id);
        window.ganttVisualization.refreshVisualizations &&
          window.ganttVisualization.refreshVisualizations(adaptedTask);
      };
    }

    if (!window.ganttVisualization?.lookaheadTemplates_actionsDuplicateTask) {
      window.ganttVisualization.lookaheadTemplates_actionsDuplicateTask =
        async (task_id, activityReferenceLvl_id, showDuplicate) => {
          if (!showDuplicate || !task_id || task_id === null) return;
          const thisTask = gantt.getTask(task_id);
          const thisActivityReferenceLvl = activityReferenceLvl_id
            ? activities.find((ac) => ac.id == activityReferenceLvl_id)
            : null;

          avoidOptimizationTimelineBlinking();
          duplicateActivityTooltipRef &&
            duplicateActivityTooltipRef.tooltip.setState({ visible: false });
          const toAdd = await addCopyOfActivity(thisActivityReferenceLvl);
          const adaptedTask = convertPPTask2PluginTask(toAdd, null, thisTask);
          delete adaptedTask.parent;
          window.ganttVisualization.createTask(adaptedTask, task_id);
          setTimeout(() => {
            updateMemoryRefMaterialValues();
            window.ganttVisualization.refreshVisualizations &&
              window.ganttVisualization.refreshVisualizations(adaptedTask);
            window.ganttVisualization.refreshView();
          }, 200);
        };
    }

    return `<div ${dataTestId}>
                <div
                    onclick="window.ganttVisualization.selectTask(${task.id})"
                    class="${isFeatureOn('enable_lwp_18') ? 'custom-div-row-container' : ''} text-btn-gantt-view-main"
                    style="
                        color: #121212;
                        font-weight: 600;
                        position: relative;
                        top: 0px !important
                    "
                >

                    <span>
                        <span style="
                            top: 0px;
                            position: relative;
                            display: inline;
                            visibility: ${hideAddBtn ? 'hidden' : 'visible'}
                        "
                        >
                            ${
                              !isOnlyReadElement &&
                              !haveChildsAct &&
                              activityReferenceLvl
                                ? `<span onclick="window.ganttVisualization.lookaheadTemplates_actionsAddTask(${task?.id}, ${activityReferenceLvl?.id})"
                                    style="cursor: pointer; position: relative; margin-top: 10px; z-index: 1000; margin-left: -3px">
                                    <img src=${addTaskIcon} width="15"
                                  />
                                  </span>`
                                : ' '
                            }

                            ${
                              !isOnlyReadElement
                                ? `<span
                                    style="margin-left: 10px; display: ${showDuplicate ? 'inline' : 'none'}"
                                    onclick="window.ganttVisualization.lookaheadTemplates_actionsDuplicateTask(${task.id}, ${activityReferenceLvl.id}, ${showDuplicate})"
                                  >
                                    ${
                                      showDuplicate
                                        ? `<img src=${duplicateIcon} width="14"
                                            style="cursor: pointer; position: relative; z-index: 1000"
                                            />`
                                        : `<img src=${duplicateIcon} width="14"
                                            style="cursor: pointer; position: relative; z-index: 1000"
                                            />`
                                    }
                                  </span>`
                                : ' '
                            }
                        </span>
                    </span>

                </div>
            </div>`;
  };

  const renderActivityTask = () => {
    if (!window.ganttVisualization?.lookaheadTemplates_tooltipRoadblock) {
      window.ganttVisualization.lookaheadTemplates_tooltipRoadblock = async (
        task_id
      ) => {
        if (!task_id) return;
        window.ganttVisualization.hasConstraintHtml(task_id);
        window.ganttVisualization.addConstraint(task_id);
      };
    }
    /** is Task */
    const html_ponderator_constraint = `<span onclick="window.ganttVisualization.lookaheadTemplates_tooltipRoadblock(${item?.id})">
                ${hasConstraintHtml(item, null, gantt.shouldOnlyRead, gantt.t, SHOW_INDICATOR_CONSTRAINT, item.id, 'actions')}
            </span>`;

    let html_collapse_expand = item?.is_parent
      ? `
                <span class="collapse-expand-gantt expand-task" onclick=${() => collapseExpandActivity(item, gantt)}>
                    ${item?.$open ? '<div class="gantt_tree_icon gantt_close"></div>' : '<div class="gantt_tree_icon gantt_open"></div>'}
                </span>`
      : '';

    const taskName = renderActions(html_ponderator_constraint);
    if (isGrouped()) {
      html_collapse_expand = null;
    }
    return `<div ${dataTestId}>
                <div>
                    <span
                        class='text-btn-gantt-view-actions'
                        style="position: relative; text-align: center"
                    >
                        ${taskName}
                    </span>
                </div>
            </div>`;
  };

  const RenderActionsColumn = () => {
    let isOdd = false;
    if (gantt.oddColsConfig && task.type === 'activitytask') {
      isOdd = gantt.oddColsConfig.name;
    }
    if (!item?.type)
      return (
        <TestWrapped>
          <div></div>
        </TestWrapped>
      );
    return `<div
                class="${!isOdd ? 'odd-col' : 'non-odd-col'}"
            >
                ${item.type === 'activitytask' ? renderActivityTask() : ''}
                ${item.type === 'main' ? renderMain() : ''}
            </div>`;
  };
  return RenderActionsColumn();
};

export default RenderActions;
