function defineColorByStatus(task, render = false, gantt = false) {
  if (!gantt) {
    gantt = window.to_use_react_gantt;
  }

  gantt.config.highlight_critical_path = false;
  if (task.type == 'milestone') return;
  let color;
  switch (task.status) {
    case 'Advancement':
      color = '#309FE9';
      break;
    case 'Overdue':
      color = '#E50101';
      break;
    case 'Waiting':
      color = '#121212';
      break;
    case 'Doing':
      color = '#F59D04';
      break;
    case 'Done':
      color = '#34AF00';
      break;
    default:
      color = '#121212';
      break;
  }
  task.progressSolidColor = color;
  task.color = color + '4d';
  if (render) gantt.optimizedRender();
}

export { defineColorByStatus };
