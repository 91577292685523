import React from 'react';
import './styles.scss';
import moment from 'moment';

const StartCell = ({ parent, date }) => {
  const language = navigator.language || navigator.userLanguage;
  const formatDate = language.includes('en') ? 'MM/DD/YY' : 'DD/MM/YY';

  const dateStr = moment(date).format(formatDate);
  return <div className="start-cell">{dateStr}</div>;
};

export default StartCell;
