/* eslint-disable prefer-const */
/** React stuff */
import React, { useState, useEffect } from 'react';

import axios from 'axios';

/** React-query */
import { useQuery, useQueryClient } from 'react-query';
import { getAnalitycsByProject } from '../../../../views/weeklyPlan/weeklyPlan.helper';

/** import library for render progessive images with lazy loading */
import ProgressiveImage from 'react-progressive-image';

import noImageProject from '../../../../assets/img/noimage-s.png';

/**  import library for animations effects */
import { Animated } from 'react-animated-css';

/** import common functions from utils */
import {
  dollar,
  area,
  addToLocalStorage,
  getTodayWeekWithPday,
  getClassForCards
} from '../../../../utils';

/** import icons from antd framework */
import {
  CalendarOutlined,
  CaretDownOutlined,
  CaretUpOutlined
} from '@ant-design/icons';

import useWindowDimensions from '../../../../hooks/useWindowDimensions';

/** Import elements from library Antd */
import { Avatar, Col, Tooltip } from 'antd';

import NumberFormat from 'react-number-format';

/** Moment for dates using */
import moment from 'moment';

import { withTranslation } from 'react-i18next';

import { compareValues } from '../../../../utils';

/** Redux */
import { useDispatch, useSelector } from 'react-redux';
import * as projectActions from '../../../../redux/slices/projectSlice';

/** Components */
import IconComponent from '../../IconSvg';
import ActionsMenu from '../../ActionsMenu'; /** menu project (edit copy delete) */

/** PNG to put on users without image */
import fakeAvatar from '../../../../assets/img/fake_user.png';
import { useProgressIndicatorProject } from '../../../../hooks/useProgressIndicatorProject';
import { usePpcIndicatorProject } from '../../../../hooks/usePpcIndicatorProject';
import { usePcrIndicatorProject } from '../../../../hooks/usePcrIndicatorProject';
import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import useMasterplanPermissions from '../../../../hooks/useMasterplanPermissions';
import {
  setCurrentProject,
  setCurrentSector
} from '../../../../utils/userUtils';
import {
  HOME_CARD_VIEW,
  SETTINGS_USER_VIEW
} from '../../../../hooks/useSelectProject';

const dateFormatList = ['DD/MM/YYYY', 'MM/DD/YYYY'];

/** Permissions */
const userGanttPermissions = useMasterplanPermissions();
const defaultModuleView =
  userGanttPermissions.gantt === 'SA'
    ? '/lookahead/planification'
    : '/masterplan';

/** test id */
export const testIds = {
  RENDER_ITEM_CARD_CONTAINER: 'RENDER_ITEM_CARD_CONTAINER'
};
const TestWrapped = ({ children }) => (
  <span data-testid={testIds.RENDER_ITEM_CARD_CONTAINER}>{children}</span>
);

function ItemCard(props) {
  /** props */
  const {
    project,
    showActionsMenu = true,
    t,
    setFormProjectVisible,
    setFormDeleteVisible,
    caller = 'projects',
    onClickProject,
    analytics = {}
  } = props;

  /** consts */
  const subtitleProject = project.address || t('without_direction');
  const navigatorLang = navigator.language || navigator.userLanguage;
  /** load data from cache */
  let allProgressProjectFromStorage = JSON.parse(
    localStorage.getItem('projectsProgress')
  );
  let progressProjectFromStorage = { progress: 0 };
  if (allProgressProjectFromStorage) {
    progressProjectFromStorage = allProgressProjectFromStorage.find(
      (el) => el.projectId === project.id
    );
  }

  let allPpcProjectFromStorage = JSON.parse(
    localStorage.getItem('projectsPpc')
  );
  let ppcProjectFromStorage = { ppc: 0 };
  if (allPpcProjectFromStorage) {
    ppcProjectFromStorage = allPpcProjectFromStorage.find(
      (el) => el.projectId === project.id
    );
  }

  let allPcrProjectFromStorage = JSON.parse(
    localStorage.getItem('projectsPcr')
  );
  let pcrProjectFromStorage = { pcr: 0 };
  if (allPcrProjectFromStorage) {
    pcrProjectFromStorage = allPcrProjectFromStorage.find(
      (el) => el.projectId === project.id
    );
  }

  const [proyectProgress, setProyectProgress] = useState(
    progressProjectFromStorage?.progress || '-'
  );
  const [lastweekcommitments, setLastweekcommitments] = useState(
    ppcProjectFromStorage?.ppc || '0.00%'
  );
  const [constraints, setConstraints] = useState(
    pcrProjectFromStorage?.pcr || '0.00'
  );

  const [lastweekcommitmentstask, setLastweekcommitmentstask] = useState('-');
  const [constraintscount, setConstraintscount] = useState('No Data');
  const [users, setUsers] = useState([]);
  const projectState = useSelector((state) => state.projectState);

  const numUsers = showActionsMenu ? 4 : 2;

  /** Queries */
  const analitycsRq = useQuery(['analitycsRq', project.id], () =>
    getAnalitycsByProject(project.id, ourRequest)
  );

  /** redux */
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  /** cancel backend process */
  const ourRequest = axios.CancelToken.source();
  useEffect(
    () => () => {
      /** cancel request */
      ourRequest.cancel();
      queryClient.cancelQueries(['analitycsRq', project.id]);
    },
    []
  );

  useEffect(() => {
    /** get week number */
    if (project && analitycsRq && analitycsRq?.data?.data) {
      getProyect();
      getUser();
    }
  }, [project, analitycsRq.data?.data]);

  async function getUser() {
    setUsers(project.users.filter((el) => el.is_active));
  }

  /** get indicator progress for project */
  const indicatorProject = useProgressIndicatorProject(analitycsRq.data?.data);
  const indicatorPpcProject = usePpcIndicatorProject(
    analitycsRq.data?.data,
    getTodayWeekWithPday
  );
  const indicatorPcrProject = usePcrIndicatorProject(analitycsRq.data?.data, t);

  async function getProyect() {
    /** set ppc indicator */
    const { realized, total, ppcLabel } = indicatorPpcProject;
    setLastweekcommitmentstask(realized + '/' + total + ' Tareas');
    setLastweekcommitments(ppcLabel);
    addToLocalStorage('projectsPpc', 'ppc', ppcLabel, project);

    /** set prr indicator */
    const { pcrLabel, constraintCount } = indicatorPcrProject;
    setConstraints(pcrLabel);
    setConstraintscount(constraintCount);
    addToLocalStorage('projectsPcr', 'pcr', pcrLabel, project);

    /** set progress indicator */
    const { progressLabel } = indicatorProject;
    setProyectProgress(progressLabel);
    addToLocalStorage('projectsProgress', 'progress', progressLabel, project);
  }

  /** get windows dimensions from hook */
  const { _, width } = useWindowDimensions();
  const classForCards = getClassForCards(width);

  /** render */
  return (
    <Col align="center" className={classForCards}>
      <Animated animationIn="fadeIn" animationInDuration={1000}>
        <TestWrapped>
          <div
            className={'cardProject'}
            onClick={() =>
              onClickProject(
                project,
                caller === 'settings' ? SETTINGS_USER_VIEW : HOME_CARD_VIEW,
                defaultModuleView,
                analytics
              )
            }>
            <div
              style={{
                minHeight: caller === 'projects' ? 152 : null
              }}>
              <div className="imgPry">
                {
                  <ProgressiveImage
                    delay={500}
                    src={project.image ? project.image : noImageProject}
                    placeholder={noImageProject}>
                    {(src, loading) => (
                      <img
                        src={src}
                        className={
                          loading ? 'imgProject img-blur-project' : 'imgProject'
                        }
                      />
                    )}
                  </ProgressiveImage>
                }
                {showActionsMenu && (
                  <ActionsMenu
                    {...props}
                    project={project}
                    setFormProjectVisible={setFormProjectVisible}
                    setFormDeleteVisible={setFormDeleteVisible}
                  />
                )}
              </div>
              <div className="dateProject">
                <CalendarOutlined style={{ marginRight: 5 }} />
                {moment(project.createdAt).format(
                  t('lang') === 'es' ? dateFormatList[0] : dateFormatList[1]
                )}
              </div>
              <div className="listUsers">
                {users.length - numUsers > 0 ? (
                  <span className="more-users">+{users.length - numUsers}</span>
                ) : null}
                {users.map((e, i) => {
                  if (i > numUsers - 1) return null;
                  return (
                    <Tooltip
                      title={e.email}
                      key={`us-${project.id}-${e.id}-${i}`}>
                      <Avatar
                        key={i}
                        style={{ left: 25 * i }}
                        className="avatar-btn"
                        shape="circle"
                        size={26}
                        icon="user"
                        src={e.image ? e.image : fakeAvatar}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </div>
            <div className="titleProject">
              <Tooltip title={project.name} placement="topLeft">
                {project.name}
              </Tooltip>
            </div>
            <div className="subTitleProject">
              <Tooltip title={subtitleProject} placement="topLeft">
                {subtitleProject}
              </Tooltip>
            </div>
            <div className="indicatorsProject">
              <Tooltip title={t('project_list.project_progress')}>
                <div className="ind-prg">{proyectProgress || 0.0}</div>
              </Tooltip>

              {/* LAST PPC */}
              <Tooltip title={t('project_list.ppc_project')}>
                <div className="ind-commit">
                  <CaretDownOutlined style={{ color: '#00CA2A' }} />
                  {lastweekcommitments}
                </div>
              </Tooltip>

              {/* CURRENT PRR */}
              <Tooltip title={t('project_list.last_commitment')}>
                <div className="ind-consts">
                  <CaretUpOutlined style={{ color: '#00CA2A' }} />
                  {constraints}
                </div>
              </Tooltip>
            </div>

            <div className="propsProject">
              <div className="pbudget">
                {/* Budget */}
                <Tooltip title={t('project_list.project_ammount')}>
                  <IconComponent
                    data={dollar}
                    width={18}
                    fill="#53C255"
                    className="iconDollar"
                  />
                  <span>
                    {`${project.currency} `}
                    <NumberFormat
                      value={project.budget}
                      displayType={'text'}
                      thousandSeparator={
                        navigatorLang.includes('en') ? ',' : '.'
                      }
                      decimalSeparator={
                        navigatorLang.includes('en') ? '.' : ','
                      }
                    />
                  </span>
                </Tooltip>
              </div>
              {/* SIZE */}
              <div className="psize">
                <Tooltip title={t('project_list.project_size')}>
                  <IconComponent
                    data={area}
                    width={18}
                    fill="#53C255"
                    className="iconArea"
                  />
                  <span>
                    <NumberFormat
                      value={project.size}
                      displayType={'text'}
                      thousandSeparator={
                        navigatorLang.includes('en') ? ',' : '.'
                      }
                      decimalSeparator={
                        navigatorLang.includes('en') ? '.' : ','
                      }
                    />
                    {` ${project.sizetype}`}
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </TestWrapped>
      </Animated>
    </Col>
  );
}

export default withTranslation()(ItemCard);
